import * as React from "react";
import { Box, CssBaseline, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { YC_FOOTER_COPYRIGHT, YC_LOGIN, YC_MY_FRIENDS } from "utils/strings";
import { BLACK, DARKGREY, WHITE } from "utils/colors";
import DashboardStatusAccordian from "components/dashboard/dashboard-status-accordian/dashboard-status-accordian";
import '../dasheboard-new/dashboard-app-tab.scss';
import { AccordionData } from "components/dashboard/dashboard-status-accordian/AccordianData";
import SideNav from "components/reusable-sideNav/SideNav";
import DashboardPageHeader from "components/dashboard-header/dashboard-header";
import HomepageFooter from "components/homepage-footer/homepage-footer";


const UseStyles = makeStyles(() => ({
    dividerStyle: {
        borderColor: WHITE,
        borderWidth: 0.5,
        opacity: 1,
    },
    boxView: {
        boxShadow: " 0px 11.67662px  9.35323px rgba(0, 0, 0, 0.15)",
        borderRadius: "8px",
        background: "#FFFFFF",
    },
    titleBox: {
        background: BLACK,
        color: WHITE,
        textAlign: "start",
        padding: "2%",
    },
    title: {
        fontWeight: 600
    },
    yellowPageImg: {
        height: '64px',
        width: '64px',
        borderRadius: '40px',
        border: '1px solid #000000',
        backgroundColor: DARKGREY,
    },
    item: {
        wordBreak: "break-word"
    }
}));

const drawerWidth = 250;

interface Props {

}

function CommonUserDashboard(props: Props) {
    const {
        dividerStyle,
        boxView,
        titleBox,
        title,
        yellowPageImg,
        item,
    } = UseStyles();

    return (
        <div style={{ height: "100%", flex: 1 }}>
            <Box
                sx={{
                    marginBottom: "40px",
                    height: "100%",
                    flex: 1,
                }}
            >

                <DashboardPageHeader />

                <CssBaseline />
                <Box sx={{ display: "flex", flexDirection: "row" }}>

                    <SideNav />


                    <Box
                        sx={{ width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                    >
                        <Box
                            className="mx-3 ms-lg-4 me-lg-4"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                flexGrow: 1,
                                mt: 7,
                                ml: 5,

                            }}
                        >
                            <Box
                                className={" d-flex flex-wrap row m-0 p-0"}
                                justifyContent="space-between"
                            >
                                <Box className={boxView + " col-12 col-lg-7 m-0 p-0"}>
                                    <Box className={titleBox} >
                                        <Typography className={title + " ms-3"}>{YC_MY_FRIENDS}</Typography>
                                    </Box>
                                    <Box className='pt-2 ps-2 m-0 p-0 row '>
                                        {profile.map((element, key) => (
                                            <Box key={key} className={" p-2 d-flex flex-wrap col-sm-6 col-lg-4 "} >
                                                <Box className={yellowPageImg} >

                                                </Box>
                                                <Box className={item + ' text-start ps-2 pt-2'} >
                                                    <Typography>{`${YC_LOGIN}:${element.login}`}</Typography>
                                                    <Typography>{element.profileName}</Typography>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                                <DashboardStatusAccordian accordionData={accordionData} />
                            </Box>
                            <Box sx={{ mt: 5, }}>
                                <Divider
                                    orientation="horizontal"
                                    flexItem
                                    className={dividerStyle}
                                    style={{ borderColor: "rgba(0, 0, 0, 0.5)", borderWidth: 0.5 }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <HomepageFooter isLinkFooter={true} displayText={YC_FOOTER_COPYRIGHT} />
        </div>

    );
}

export default CommonUserDashboard;
export interface profile {
    login: string,
    profileName: string,
}
const profile: profile[] = [
    {
        login: "Just Now",
        profileName: "Jennifer d’Or",
    },
    {
        login: "Just Now",
        profileName: "John Smith",
    },
    {
        login: "03/23/2021",
        profileName: "Enrique Mechanics",
    },
    {
        login: "Just Now",
        profileName: "Jennifer d’Or",
    },
    {
        login: "06/23/2021",
        profileName: "John Smith",
    },
    {
        login: "03/23/2021",
        profileName: "Enrique Mechanics",
    },
    {
        login: "Just Now",
        profileName: "Jennifer d’Or",
    },
    {
        login: "06/23/2021",
        profileName: "John Smith",
    },
    {
        login: "03/23/2021",
        profileName: "Enrique Mechanics",
    },
    {
        login: "Just Now",
        profileName: "Jennifer d’Or",
    },
    {
        login: "06/23/2021",
        profileName: "John Smith",
    },
    {
        login: "03/23/2021",
        profileName: "Enrique Mechanics",
    },

]

const accordionData: AccordionData[] = [
    {
        accordianTitle: "My Profile",
        accordianList: [],
    },
    {
        accordianTitle: "My Kriole Page",
        accordianList: [],
    },
    {
        accordianTitle: "My Orders",
        accordianList: [],
    },
    {
        accordianTitle: "My Favorites",
        accordianList: [],
    },
    {
        accordianTitle: "My Preferences",
        accordianList: [],
    },

]