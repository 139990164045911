import { Box, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BLACK, WHITE } from "utils/colors";
import {
  YC_ACCOUNTING_SETUP_MSG,
  YC_BUSINESS_SETUP_MSG,
  YC_CONTROLLER_SETUP_MSG,
  YC_YELLOW_PAGES_SETUP_MSG,
} from "utils/strings";

const useStyles = makeStyles(() => ({
  boxView: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    borderRadius: "7.2px",
    width: "90%",
    background: "#FFFFFF",
  },
  dividerStyle: {
    display: "flex",
    opacity: 1,
    // borderRadius: 10,
  },
  titleText: {
    color: BLACK,
    fontSize: 16,
    fontWeight: "700",
    marginTop: 1,
  },
  bodyText: {
    display: "flex",
    color: BLACK,
    fontSize: 12,
    marginTop: 1,
    textAlign: "left",
    fontWeight: "400",
    lineHeight: "18px",
  },
  stepText: {
    fontSize: 20,
    fontWeight: "700",
    marginTop: 1,
  },
}));

function SetupProcess(props: any) {
  const { boxView, dividerStyle, titleText, bodyText, stepText } = useStyles();

  function getStatusWidth() {
    let totalWidth = 0
    totalWidth = props.setupStepNo/props.setupTotalSteps
    if (totalWidth === 1) {
      totalWidth -= 0.065
    }
    return totalWidth
  }
  const barWidth = getStatusWidth();

  return (
    <div>
      <Box className={boxView}>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Divider
            orientation="horizontal"
            flexItem
            className={dividerStyle}
            sx={{ display: { xs: "flex", sm: "flex", lg: "flex", xl: "flex" } }}
            style={{ borderColor: props.setupColor, borderWidth: 2 }}
          />
          <Box sx={{ ml: 3, mt: 1, mb: 2 }}>
            <Typography className={titleText} sx={{ display: "flex" }}>
              {props.setupName}
            </Typography>
            <Typography
              className={stepText}
              sx={{ color: props.setupColor, display: "flex" }}
            >
              {props.setupStepNo} of {props.setupTotalSteps}
            </Typography>
            <Divider
              orientation="horizontal"
              flexItem
              className={dividerStyle}
              sx={[{ mr: 2 }, barWidth ? { width: barWidth } : null]}
              style={{ borderColor: props.setupColor, borderWidth: 3 }}
            />
            <Divider
              orientation="horizontal"
              flexItem
              className={dividerStyle}
              sx={{ mb: 2, mr: 2, marginTop: 0.2}}
              style={{ borderColor: BLACK, borderWidth: 0.5 }}
            />
            <Typography className={bodyText} sx={{ mt: 2, mr: 2 }}>
              {props.setUpStepName}
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
export default SetupProcess;
