import { Box, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "components/custom-button/custom-button";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import HomeApi from "services/HomeAPi";
import { BLACK, BLUE, BLUE_3, PINK_2, PINK_3, RED, WHITE } from "utils/colors";
import { SUBHEADER1 } from "utils/fonts";
import Notification from "views/Notifications/Notification";

const ManageRoleDialogStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        borderRadius: "7.2px",
        marginTop: "10px",
        marginLeft: "20px",
        marginBottom: "10px",
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "90%",
        overflowY: 'auto',
        OverflowX: "none",
        maxWidth: 700,
        minWidth: 450,
        background: "white",
    }

}))

function ManageRoleDialog(props: any) {
    const { boxView } = ManageRoleDialogStyle();
    const [controllerOfAccount, setControllerOfAccount] = React.useState("");
    const { t } = useTranslation()
    const [primaryRoleList, setPrimaryRoleList] = React.useState([])
    const [primarySelectedRole, setPrimarySelectedRole] = React.useState("");
    const [primaryRoleNameList, setPrimaryRoleNameList] = React.useState([])
    const [otherRoleList, setOtherRoleList] = React.useState([])
    const [otherSelectedRole, setOtherSelectedRole] = React.useState("");
    const [otherRoleNameList, setOtherRoleNameList] = React.useState([])
    const [isDisableAddRole, setIsDisableAddRole] = React.useState(true)
    const [inputData, setInputData] = React.useState({
        otherRole: "",
        systemGeneratedRole: ""
    })

    const handleChange = (event: SelectChangeEvent) => {
        setControllerOfAccount(event.target.value);
    };

    const validForAddRole = () => {
        if(primarySelectedRole !== "" && inputData.otherRole !== "") {
            setIsDisableAddRole(false)
        } else {
            setIsDisableAddRole(true)
        }
    }

    const getUserId = () => {
        let userId;
        const loginUserDetails = localStorage.getItem("user_Details")
        if (loginUserDetails){
          const details = JSON.parse(loginUserDetails)
          userId = details.id
        }
        return userId
    }
        
    const getPrimaryRole = async () => {
        const response = await HomeApi.getPrimaryRole(getUserId());
        if (response.status === true) {
            setPrimaryRoleList(response.data)
            let tempArray: any = [];
            response.data.map((role: any) => {
            tempArray.push(role.name)
            });
            tempArray.push("other role")
            setPrimaryRoleNameList(tempArray)
        }
    }

    const getOtherRole = async () => {
        const response = await HomeApi.getOtherRole(getUserId());
        if (response.status === true) {
            setOtherRoleList(response.data)
            let tempArray: any = [];
            response.data.map((role: any) => {
            tempArray.push(role.name)
            });
            setOtherRoleNameList(tempArray)
        }
    }

    useEffect(() => {
        getPrimaryRole()
        getOtherRole()
    }, [])

    const createRole = async () => {
        const payload = {
            primary_role: primarySelectedRole,
            otherRole: inputData.otherRole
        };

        const response = await HomeApi.createRole(payload);
        if (response.status === true) {
            Notification.notifySuccess(response.message)
            getOtherRole()
            getPrimaryRole()
        } else {
            Notification.notifyError(response.message)
        }
    };

    const suspendRole = async () => {
        const payload = {
            otherRole: otherSelectedRole
        };

        const response = await HomeApi.suspendRole(payload);
        if (response.status === true) {
            Notification.notifySuccess(response.message)
            getOtherRole()
            getPrimaryRole()
        } else {
            Notification.notifyError(response.message)
        }
    };

    const deleteRole = async () => {
        const payload = {
            otherRole: otherSelectedRole
        };

        const response = await HomeApi.deleteRole(payload);
        if (response.status === true) {
            Notification.notifySuccess(response.message)
            getOtherRole()
            getPrimaryRole()
        } else {
            Notification.notifyError(response.message)
        }
    };

    useEffect(() => {
        validForAddRole()
    }, [primarySelectedRole, inputData.otherRole])

    return (
        <Box className={boxView}>
            <Typography style={{
                fontFamily: 'roboto',
                fontSize: 20,
                fontWeight: 700, marginTop: "40px", marginLeft: "40px"
            }} > {t("YC_ADD_USER_ROLE")} </Typography>
            <Box sx={{
                borderRadius: "7.2px", margin: 2, padding: 2,
                boxShadow: 2,
                border: 2,
                borderColor: 'primary.light',
                height: "90%"
            }}>
                <Box sx={{
                    display: "block",
                    flexDirection: "column",
                    paddingBottom: "10px"
                }}>


                    <Typography variant="caption" > {t("YC_CREATE_NEW_USER_ROLE_AND_ADD_THEM_ROLE_RECORDS")} </Typography>
                </Box>

                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "10px"
                }}>
                    <Typography variant="caption"> {t("YC_PRIMARY_ROLE")}:   </Typography>
                    <Box sx={{ marginRight: "25%" }}>
                        <Select
                            value={primarySelectedRole}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            onChange={(event: any) => {
                                const selectedRole: any = primaryRoleNameList.filter((role: any) => (role === event.target.value))
                                primaryRoleList.map((roleData: any) => {
                                    if (roleData.name === selectedRole[0]) {
                                        setPrimarySelectedRole(roleData.name)
                                    } else if (event.target.value ==="other role") {
                                        setPrimarySelectedRole("other role")
                                    }
                                })
                            }}
                            size="small"
                            placeholder={t("YC_SELECT_PRIMARY_ROLE")}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <>{t("YC_SELECT_PRIMARY_ROLE")}</>;
                                }
                                return selected;
                            }}
                        >
                            {primaryRoleNameList.map((item: any) => {
                                    return (
                                        <MenuItem
                                            value={item}
                                        >
                                            {item}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </Box>

                </Box>

                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "10px"
                }}>
                    <Typography variant="caption"> {t("YC_OTHER_ROLE")}:   </Typography>
                    <Box sx={{ marginRight: "25%", width: "45%" }}>
                        <TextField style={{
                            fontStyle: "italic", width: "100%"
                        }}
                            id="User not found" label={t("YC_NAME_ROLE_I_E")} variant="outlined" size="small"
                            value={inputData.otherRole}
                            onChange={(text) => {
                                const trimStr = text.target.value.trimStart();
                                const removedSpaceString = text.target.value.replace(/ /g, '-').toLowerCase()
                                setInputData((prevFields) => ({
                                    ...prevFields,
                                    otherRole: trimStr,
                                    systemGeneratedRole: removedSpaceString
                                }));
                            }} />
                    </Box>
                </Box>

                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "10px"

                }}>
                    <Typography variant="caption"> {t("YC_ROLE_SYSTEM_NAME")}:   </Typography>
                    <Box sx={{ marginRight: "25%", width: "45%" }}>
                        <TextField style={{
                            fontStyle: "italic", width: "100%"
                        }} id="Type Email or Username" label={t("YC_SYSTEM_GENERATED_I_E")} variant="outlined"
                            size="small" 
                            disabled={true}
                            value={inputData.systemGeneratedRole}
                            onChange={(text) => {
                                const trimStr = text.target.value.trimStart();
                                setInputData((prevFields) => ({
                                    ...prevFields,
                                    systemGeneratedRole: trimStr,
                                }));
                            }}/>
                    </Box>
                </Box>

                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between"
                }}>
                    <Box sx={{
                        display: "block",
                        flexDirection: "column",
                        paddingBottom: "10px",
                        width: "25%",
                    }}>
                        <Typography variant="caption" style={{ fontWeight: "bold", marginLeft: "10px" }}> {t("YC_PRIMARY_ROLE")} </Typography>
                        <Box sx={{
                            borderRadius: "5.2px", padding: 2,
                            // boxShadow: 2,
                            border: 1,
                            borderColor: 'black',
                            display: "flex",
                            flexDirection: "column",
                            height: "30vh",
                            width: "100%",
                        }}>
                            {primaryRoleNameList.map((primaryRole: any) => {
                                    return (
                                        <Typography variant="caption" style={{ 
                                             background: primaryRole === "controller" ? PINK_3 : primaryRole === "Administrator" ? BLUE_3 : WHITE,
                                             marginBottom: 1}}
                                        > {primaryRole} </Typography>
                                    );
                                })}
                            {/* <Typography variant="caption" style={{background: PINK_3, marginBottom: 1}}>{t("YC_CONTROLLER")}</Typography>
                            <Typography variant="caption" style={{background: BLUE_3, marginBottom: 1}}>{t("YC_ADMINISTRATOR")}</Typography>
                            <Typography variant="caption" style={{ marginBottom: 1}}>{t("YC_HEAD_OF_DEPARTMENT")}</Typography>
                            <Typography variant="caption" style={{ marginBottom: 1}}>{t("YC_GENERAL_USER")}</Typography> */}
                        </Box>
                    </Box>
                    <Box sx={{
                        display: "block",
                        flexDirection: "column",
                        paddingBottom: "10px",
                        width: "25%",
                    }}>
                        <Typography variant="caption" style={{ fontWeight: "bold", marginLeft: "10px" }}> {t("YC_OTHER_ROLE")} </Typography>
                        <Box sx={{
                            borderRadius: "5.2px", padding: 2,
                            border: 1,
                            borderColor: 'black',
                            height: "30vh",
                            display: "flex",
                            flexDirection: "column",
                        }}>
                            {otherRoleNameList.map((otherRole: any) => {
                                    return (
                                        <button 
                                          onClick={() => {setOtherSelectedRole(otherRole)}}
                                          style={{background: otherRole === otherSelectedRole ? BLUE : "transparent",
                                                border: "none",
                                                fontSize: 13,
                                                textAlign: "start",
                                                width: "100%"
                                          }}
                                        >{otherRole}</button>
                                    );
                                })}
                            {/* <Typography variant="caption" style={{ marginBottom: 1}}> Project User </Typography>
                            <Typography variant="caption" style={{ marginBottom: 1}}> Project Manager </Typography>
                            <Typography variant="caption" style={{ marginBottom: 1}}> Project Administrator </Typography>
                            <Typography variant="caption" style={{ marginBottom: 1}}> Project Frontend User </Typography>
                            <Typography variant="caption" style={{ marginBottom: 1}}> Other Roles </Typography> */}
                        </Box>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        paddingBottom: "10px",
                        marginTop: "25px"
                    }}>
                        <CustomButton
                            disabled={isDisableAddRole}
                            placeHolder={t("YC_ADD_ROLE")}
                            textTransform="none"
                            background={BLUE}
                            height="30px"
                            alignItems="center"
                            marginRight="10px"
                            marginBottom="30px"
                            marginLeft="20px"
                            width="auto"
                            borderRadius="4px"
                            onClick={() => createRole()}
                            buttonStyle={{
                                SUBHEADER1
                            }}>
                        </CustomButton>
                        <CustomButton
                            placeHolder={t("YC_SUSPEND_ROLE")}
                            textTransform="none"
                            background={PINK_2}
                            height="30px"
                            alignItems="center"
                            marginRight="10px"
                            marginLeft="20px"
                            marginBottom="30px"
                            width="auto"
                            borderRadius="4px"
                            onClick={() => suspendRole()}
                            buttonStyle={{
                                SUBHEADER1
                            }}>
                        </CustomButton>
                        <CustomButton
                            placeHolder={t("YC_DELETE_ROLE")}
                            textTransform="none"
                            background={RED}
                            height="30px"
                            alignItems="center"
                            marginRight="10px"
                            marginLeft="20px"
                            width="auto"
                            borderRadius="4px"
                            onClick={() => deleteRole()}
                            buttonStyle={{
                                SUBHEADER1
                            }}>
                        </CustomButton>

                    </Box>
                </Box>


            </Box>
        </Box>
    );
}

export default ManageRoleDialog;
