import { Box, Button, Divider, Link, Typography } from '@mui/material'
import { makeStyles } from "@mui/styles";
import { BLACK, GREEN, YELLOW } from 'utils/colors';
import { NoticeBoard } from './NoticeBoard';

const UseStyles = makeStyles(() => ({
    postedby: {
        "@media (max-width: 800px)": {
            display: "none",
        },
    },
    dividerStyle: {
        opacity: 1,
        width: "95%",
        margin: "auto",
        minHeight: "5px",
    },
    contentTextBox: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        "@media (max-width: 800px)": {
            width: "250px",
        },
    },
    headingText: {
        fontWeight: "700",

    },
}))


interface Props {
    element: NoticeBoard;
}
function DashboardBox(props: Props) {
    const { dividerStyle, contentTextBox, headingText, postedby } = UseStyles();
    const { element } = props;
    const HrbackgroundColor = element.hrColor
    return (
        <Box>
            <Box
                sx={{
                    minHeight: "65%",
                    borderRadius: "8px",
                    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
                    mx: 2,
                    mb: 2,
                }}
            >
                <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mx: 3,
                    my: 1.5,
                    pt: 2,
                }}
                >
                    <Typography sx={{ fontWeight: "700" }}>{element.heading1Text}</Typography>
                    <Typography className={postedby}>{element.postedBy}</Typography>
                    <Typography>{element.postedDate}</Typography>
                </Box>
                <Box sx={{ my: 2, }}>
                    <Divider
                        orientation="horizontal"
                        flexItem
                        className={dividerStyle}
                        sx={{ mb: 2, marginTop: 0.2 }}
                        style={{ background: HrbackgroundColor }}
                    />
                </Box>
                <Box className="row m-0 p-0 " sx={{}} >
                    <Box className="col-12 col-lg-8 p-0" sx={{ textAlign: "start", }}>
                        <Typography className={headingText + "  ms-4"} >{element.headingText2}</Typography>
                        <Typography className={contentTextBox + ' ms-4'}>{element.content}</Typography>
                        <Link href="#" className='me-3 mt-3 readMoreLink'>{element.readMoreLink} </Link>
                    </Box>
                    <Box className="col-12 col-lg-4 p-0 px-3  noticebtnparent">
                        <Button variant="contained" sx={{ minWidth: "100%", }} className={"mt-lg-1 " + element.buttonfirstTextClass} >{element.buttonfirstText}</Button>
                        <Button variant="contained" className='mb-4 mt-lg-3 morenotifibtn' >{element.secondbuttonText}</Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default DashboardBox;