import * as React from "react";
import { Box, CssBaseline, ListItemIcon, Tooltip, Typography, } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DashboardPageHeader from "components/dashboard-header/dashboard-header";
import { SUBHEADER1, SUBHEADERBOLD } from "utils/fonts";
import { BLACK, BLUE, GREY, WHITE, YELLOW } from "utils/colors";
import CustomButton from "components/custom-button/custom-button";
import { useTranslation } from "react-i18next";
import SupportIcon from "../../../assets/helpIcon.png";
import { useNavigate } from "react-router-dom";

const UseStyles = makeStyles(() => ({
    loginContainBlock: {
        flex: 1.6,
        "@media (max-width: 100px)": {
            display: "none",
        },
    },
}));

const drawerWidth = 250;

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

function ApplicationActivateSetup(props: any) {

    const { loginContainBlock } = UseStyles();
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [planData, setPlanData] = React.useState(props.planData)

    return (
        <div style={{ height: "100%", flex: 1 }}>
            <Box
                sx={{
                    marginBottom: "40px",
                    height: "100%",
                    flex: 1,
                }}
            >
                <CssBaseline />
                <DashboardPageHeader />

                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Box
                        sx={{
                            width: "100%",
                        }}
                    >
                        <Typography style={SUBHEADERBOLD}>
                            Mauritius Yellow Pages
                        </Typography>
                        <Typography>
                            Activation And Setup
                        </Typography>
                        <Box sx={{width: "100%",display: "flex", justifyContent: "flex-start"}}>
                            <CustomButton
                                placeHolder={ t("YC_BACK")}
                                textTransform="none"
                                background={BLUE}
                                height="30px"
                                alignItems="center"
                                marginLeft="30px"
                                marginTop="10px"
                                width= "10%"
                                borderRadius="4px"
                                titl
                                border={BLACK}
                                onClick={() => {navigate(-1)}}
                                buttonStyle={{
                                    SUBHEADER1
                                }}
                            />
                        </Box>
                        <hr
                            style={{
                                color: BLACK,
                                backgroundColor: BLACK,
                                width: "95%",
                                marginTop: 10,
                                marginLeft: "2.5%",
                                height: 2
                            }}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: { xs: "column", sm: "row", lg: "row", xl: "row" },
                                width: "100%",
                                height: "40%",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    marginTop: "30px",
                                    height: {xs: "70", sm: "60%", lg: "50%", xl: "50%"},
                                    width: "95%", //{xs: "100", sm: "30%", lg: "30%", xl: "30%"},
                                    border: 2,
                                    borderColor: GREY,
                                    boxShadow: 2,
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                    marginLeft: "2.5%"
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: { xs: "column", sm: "row", lg: "row", xl: "row" },
                                        width: "100%",
                                        marginTop: "10px",
                                        height: "100%",
                                        justifyContent: "space-between"
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: "10px",
                                            height: "100%",
                                            width: { xs: "100%", sm: "100%", lg: "30%", xl: "30%" },
                                            justifyContent: "flex-start",
                                            alignItems: "flex-start"
                                        }}
                                    >

                                        <Typography style={{ marginTop: "10px", marginLeft: "10px", fontWeight: 700 }}>
                                            {"Yellow Pages Plan:"}
                                        </Typography>
                                        <Typography style={{ marginTop: "10px", marginLeft: "20px" }}>
                                            {`${planData.plan + " " + t("YC_BUSINESS_PAGE")}`}
                                        </Typography>
                                    </Box>
                                    <Box sx={{ marginTop: "15px", height: { xs: "0px", sm: "30px", lg: "30px", xl: "30px" }, width: "3px", background: BLACK }} />
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: "10px",
                                            height: "100%",
                                            width: { xs: "100%", sm: "100%", lg: "30%", xl: "30%" },
                                            justifyContent: "flex-start",
                                            alignItems: "flex-start",
                                        }}
                                    >

                                        <Typography style={{ marginTop: "10px", marginLeft: "10px", fontWeight: 700 }}>
                                            {"Subscription Fee:"}
                                        </Typography>
                                        <Typography style={{ marginTop: "10px", marginLeft: "20px" }}>
                                            {planData.prize}
                                        </Typography>
                                    </Box>
                                    <CustomButton
                                        placeHolder={planData.plan === "Classic" ? t("YC_Setup") : t("YC_PAY_NOW")}
                                        textTransform="none"
                                        background={BLUE}
                                        marginTop="10px"
                                        height="40px"
                                        marginLeft="10px"
                                        marginRight="10px"
                                        alignItems="center"
                                        width="20%"
                                        borderRadius="4px"
                                        border={BLACK}
                                        titleColor={WHITE}
                                        hoverColor={YELLOW}
                                        onClick={() => { 
                                            navigate('/application-basic-setup', { state: { planData } })
                                        }}
                                        buttonStyle={{
                                            SUBHEADER1
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <CustomButton
                                        placeHolder={t("YC_CHOOSE_OTHER_PLANS")}
                                        textTransform="none"
                                        background={BLACK}
                                        height="40px"
                                        marginRight="10px"
                                        alignItems="center"
                                        width="40%"
                                        borderRadius="4px"
                                        border={BLACK}
                                        titleColor={YELLOW}
                                        hoverColor={WHITE}
                                        onClick={() => { }}
                                        buttonStyle={{
                                            SUBHEADER1
                                        }}
                                />
                        <hr
                            style={{
                                color: BLACK,
                                backgroundColor: BLACK,
                                width: "95%",
                                marginTop: 50,
                                marginLeft: "2.5%",
                                height: 2
                            }}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "90%",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginLeft: "5%"
                            }}
                        >
                            <Typography style={{ marginTop: "15px", fontSize: "16px" }}>
                                {t("YC_BUSINESS_DIRECTORY_POWERED_BY")}
                            </Typography>
                            <Typography style={{ marginTop: "15px", fontSize: "12px" }}>
                                {`MYP (Mauritius Yellow Pages) are a support local businesses driven company...............`}
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: { xs: "100", sm: "60%", lg: "60%", xl: "60%" },
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                    marginBottom: 5
                                }}
                            >
                                <CustomButton
                                    placeHolder={t("YC_CONTACT_SALES")}
                                    textTransform="none"
                                    background={BLUE}
                                    height="35px"
                                    alignItems="center"
                                    marginRight="10px"
                                    marginTop="10px"
                                    width="25%"
                                    borderRadius="4px"
                                    titl
                                    border={BLACK}
                                    onClick={() => { }}
                                    buttonStyle={{
                                        SUBHEADER1
                                    }}
                                />
                                <CustomButton
                                    placeHolder={t("YC_LEARN_MORE")}
                                    textTransform="none"
                                    background={"transparent"}
                                    height="35px"
                                    alignItems="center"
                                    marginRight="10px"
                                    marginTop="10px"
                                    width="25%"
                                    borderRadius="4px"
                                    titleColor={BLACK}
                                    border={BLACK}
                                    onClick={() => { }}
                                    buttonStyle={{
                                        SUBHEADER1
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* <HomepageFooter isLinkFooter={true} displayText={YC_FOOTER_COPYRIGHT} /> */}
        </div>
    );
}

export default ApplicationActivateSetup;
