import React, { useEffect } from "react";
import { HelpOutline, Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, Divider, FormControl, IconButton, Link, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckBox from "components/check-box/check-box";
import CustomButton from "components/custom-button/custom-button";
import HomepageFooter from "components/homepage-footer/homepage-footer";
import SlideButton from "components/slide-button/slide-button";
import TextInputField from "components/text-field/text-field";
// import {
//   YC_ACTIVATE_MY_ACCOUNT,
//   YC_ALREADY_ACCOUNT,
//   YC_BUSINESS,
//   YC_CHECK_BOX,
//   YC_CONFIRM_PASSWORD,
//   YC_EMAIL,
//   YC_EMAIL_ADDRESS,
//   YC_EMAIL_VALID,
//   YC_FIRST_NAME,
//   YC_FOOTER_COPYRIGHT_2022,
//   YC_GET_STARTED_ACCOUNT_EX,
//   YC_GOVERNMENT,
//   YC_INVALID_OTP,
//   YC_LAST_NAME,
//   YC_LOGIN_HERE,
//   YC_MOBILE,
//   YC_MOBILE_PHONE,
//   YC_OTP,
//   YC_PASSWORD,
//   YC_PASSWORD_NOT_MATCH,
//   YC_PASSWORD_VALID,
//   YC_PHONE_VALID,
//   YC_PP,
//   YC_PUBLIC,
//   YC_RESEND_OTP_NOW_EX,
//   YC_SEND_OTP_MY,
//   YC_SIGNUP_SUB_TITLE,
//   YC_SIGN_UP_TEXT_ONE,
//   YC_TERMS_POLICY_TEXT,
//   YC_TICK_CONTINUE,
//   YC_TITLE_CAM,
//   YC_TOC,
//   YC_VERIFICATION,
//   YC_VERIFY_ME,
//   YC_WHICH_ACCOUNT_TYPE,
// } from "utils/strings";
import SignInPage from "views/signin/signinpage";
import ReCAPTCHA from "react-google-recaptcha";
import CustomizedPopper from "components/customized-popper/customized-popper";
import Modal from "@mui/material/Modal";
import { BLACK, ERROR, GREY, LINK, RED, WHITE } from "utils/colors";
import { HEADER2 } from "utils/fonts";
import HomepageHeader from "components/homepage-header/homepage-header";
import OtpInput from "react-otp-input";
import DIGITAL_MARKETING from "../../assets/Rectangle_2.png";
import { navigateTo, validateEmail, validateMobilePhone, validatePassword } from "utils/helper";
import HomeApi from "services/HomeAPi";
import { RegisterModel } from "models/redux-models";
import Notification from "views/Notifications/Notification";
import { useTranslation } from "react-i18next";

const UseStyles = makeStyles(() => ({
  mainContainer: {
    flexDirection: "row",
    marginBottom: 40,
  },
  loginContainBlock: {
    display: 'flex',
    flexDirection: "column",
    padding: 15,
    marginTop: 30,
    "@media (max-width: 100px)": {
      display: "none",
    },
  },
  title: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 30,
    lineHeight: "40px",
    textAlign: "center",
    color: BLACK,
    width: "100%",
  },
  subTitle: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "28px",
    textAlign: "left",
    color: BLACK,
  },
  imageVideo: {
    justifyContent: "center",
    display: "flex",
    background: GREY,
    "@media (max-width: 200px)": {
      display: "none",
    },
  },
  dividerView: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  dividerStyle: {
    borderColor: BLACK,
    borderWidth: 2,
    opacity: 1,
  },
  contentLeft: {
    flex: 1,
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: 'center',
    flex: 1,
    background: WHITE,
    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    marginTop: 40,
    alignItems: "center",
    marginLeft: 5,
    marginRight: 5,
    "@media (min-width: 500px)": {
      marginLeft: 30,
      marginRight: 30,
    },
  },
  titleText: {
    display: "flex",
    marginTop: 20,
    marginBottom: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  inputView: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "80%",
    justifyContent: "center",
  },
  verticalInputView: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  checkBoxView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  loginText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  poweredText: {
    boxShadow: "2px 8px 8px rgba(0, 0, 0, 0.35);",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    background: BLACK,
    height: 30,
  },
  dividerStyleVerticle: {
    borderColor: GREY,
    borderWidth: 0.5,
    opacity: 1,
  },
  detailContainer: {
    display: "flex",
    flexDirection: "column",
  },
  buttonClass: {
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  otpTitle: {
    fontSize: 16,
    fontWeight: "400",
    color: "#000000",
  },
  infoLabel: {
    fontSize: 16,
    fontWeight: "700",
  },
  otpViewTitle: {
    fontSize: 16,
    fontWeight: "500",
    color: "#1F2328",
    lineHeight: "24px",
  },
  privacyPolicyTextView: {
    display: "flex",
    marginTop: 5,
    justifyContent: "center",
    alignItems: "center",
  },
}));

const SignUpPage = () => {
  const { mainContainer } = UseStyles();
  const [isStepTwo, setIsStepTwo] = React.useState(false);
  const [isShowModal, setIsShowModal] = React.useState(false);

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [mobilePhone, setMobilePhone] = React.useState("");
  const [isButtonDisabled, setButtonDisabled] = React.useState(true);
  const [isactiveButtonDisabled, setActiveButtonDisabled] = React.useState(true);

  const [recaptchaResponse, setRecaptchaResponse] = React.useState("");
  const [isShowRecaptcha, setIsShowRecaptcha] = React.useState(false);

  const [OTP, setOTP] = React.useState("");
  const [isOTPEmail, setOTPEmail] = React.useState(false);
  const [isOTPMobile, setOTPMobile] = React.useState(false);
  const [invalidOtp, setInvalidOtp] = React.useState(false);
  const [isOtpVerified, setOtpVerified] = React.useState(false);

  const [password, setPassword] = React.useState("");
  const [isConfirmPassword, setConfirmPassword] = React.useState("");
  const [passwordsMatch, setPasswordsMatch] = React.useState(true);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const [isBusinessCheck, setIsBusinessCheck] = React.useState(false);
  const [isPublicCheck, setIsPublicCheck] = React.useState(false);
  const [isGovernmentCheck, setIsGovernmentCheck] = React.useState(false);

  const [sliderAction, setSliderAction] = React.useState(false);
  const [tikToContinue, setTikToContinue] = React.useState(false);
  const [typeNotCheck, setTypeNotCheck] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState({
    email: false,
    mobile: false,
    password: false,
  });

  const {
    rightContainer,
    titleText,
    inputView,
    verticalInputView,
    checkBoxView,
    loginText,
    dividerStyleVerticle,
    loginContainBlock,
    title,
    subTitle,
    dividerStyle,
    dividerView,
    imageVideo,
    contentLeft,
    detailContainer,
    buttonClass,
    otpTitle,
    infoLabel,
    otpViewTitle,
    privacyPolicyTextView,
  } = UseStyles();

  const {t} = useTranslation()
  
  const validateData = () => {
    if ((!firstName || !lastName || !email || !mobilePhone) || (!isOTPEmail && !isOTPMobile) || !sliderAction) {
      setButtonDisabled(true);
    }
    else {
      setButtonDisabled(false);
    }

    if ((!isPublicCheck && !isGovernmentCheck && !isBusinessCheck) || !isOtpVerified || !tikToContinue || !password || !isConfirmPassword || !passwordsMatch || showErrorMessage.password) {
      setActiveButtonDisabled(true);
    } else {
      setActiveButtonDisabled(false);
    }

  };

  useEffect(() => {
    validateData();
  }, [firstName, lastName, email, mobilePhone, isOTPEmail, isOTPMobile,
    isPublicCheck, isBusinessCheck, isGovernmentCheck, invalidOtp,
    isShowRecaptcha, recaptchaResponse, typeNotCheck, isOtpVerified, sliderAction, tikToContinue, password, isConfirmPassword, passwordsMatch]);

  const validateEmailId = (value: string) => {
    const validEmail = validateEmail(value);
    setShowErrorMessage((prevFields) => ({
      ...prevFields,
      email: !validEmail,
    }));
    return validEmail;
  };

  const validatePhone = (mobilePhone: string) => {
    const validMobilePhone = validateMobilePhone(mobilePhone);
    setShowErrorMessage((prevFields) => ({
      ...prevFields,
      mobile: !validMobilePhone,
    }));
    return validMobilePhone;
  };

  const passwordValid = (password: string) => {
    const validPassword = validatePassword(password);
    setShowErrorMessage((prevFields) => ({
      ...prevFields,
      password: !validPassword,
    }));
    return validPassword;
  };


  function handleOpen() {
    setIsShowModal(true);
  }

  function handleClose() {
    setIsShowModal(false);
  }

  const slideBtnAction = (isOn: boolean) => {
    setIsShowRecaptcha(isOn);
    setSliderAction(isOn);
  };

  const handleRecaptchaChange = (token: string | null) => {
    if (token) {
      setRecaptchaResponse(token);
    }
  }

  const isShowError = (key: String) => {
    if (key === "email" && showErrorMessage.email) {
      return true;
    } else if (key === "mobile" && showErrorMessage.mobile) {
      return true;
    }
    return false;
  };

  const isShowErrorMessage = (key: String) => {
    if (key === "email" && showErrorMessage.email) {
      return t("YC_EMAIL_VALID");
    } else if (key === "mobile" && showErrorMessage.mobile) {
      return t("YC_PHONE_VALID");
    }
    return "";
  };

  const verifyMeBtnAction = (header: any) => {
    let CustomerOtpData = {mobilePhone, email, first_name : firstName, last_name : lastName };

    HomeApi.getOTP(CustomerOtpData, header).then(res => {
      const data = res.data;
      if (data.data.body) {
        setIsStepTwo(true);
      }
    }).catch(err => { });

  };

  const verifyOtp = (value: string, header: any) => {
    let verifyOTPData = { email, "otp": value };
    HomeApi.Verifyotp(verifyOTPData, header).then(res => {
      if (res.status === 200) {
        setInvalidOtp(false);
        setOtpVerified(true);
      } else {
        throw res.message;
      }
    }).catch(err => {
      setInvalidOtp(true);
      setOtpVerified(false);
      Notification.notifyError(t("YC_INVALID_OTP"));
    })
  }

  const activateBtnAction = (header: any) => {
    let type = "";
    if (isBusinessCheck) {
      type = "Business"
    } else if (isPublicCheck) {
      type = "Public"
    } else if (isGovernmentCheck) {
      type = "Government"
    }
    if (type.length === 0) {
      setTypeNotCheck(true);
    }

    const registerModel: RegisterModel = {
      fname: firstName,
      lname: lastName,
      email: email,
      mobile: mobilePhone,
      role: "guser",
      type: type,
      password: password,
      cpassword: isConfirmPassword
    }
    HomeApi.submitRegister(registerModel, header).then(res => {
      const data = res.data;
      if (data.message === "User register") {
        navigateTo('/wellCome')
        HomeApi.setAccessToken(data.token)
      } else {
        throw res.data;
      }
    }).catch(err => {
      Notification.notifyError(err.message);
    });

  }

  function subTitleView(subTitleText: any) {
    return (
      <Typography sx={{ my: 3 }} className={subTitle}>
        {subTitleText}
      </Typography>
    );
  }

  /**
   *
   * @returns SignUp Left Container Common View
   */
  function leftContainerView() {
    return (
      <Box className={loginContainBlock}>
        <Box
          sx={{ display: 'flex' }}
          className={dividerView}
        >
          <Box className={contentLeft}>
            <Typography className={title}>{t("YC_TITLE_CAM")}</Typography>
            <Typography className={title} sx={{ my: 1 }}>
              Logic - Easy - Secure
            </Typography>
          </Box>
          <Divider
            orientation="horizontal"
            variant="middle"
            flexItem
            className={dividerStyle}
          />
        </Box>
        <Box className={detailContainer}>
          {subTitleView(t("YC_SIGNUP_SUB_TITLE"))}
          <Box className={imageVideo}>
            <img
              src={DIGITAL_MARKETING}
              width={'100%'}
              alt={"unlock icon"}
            />
          </Box>
          <CustomizedPopper />
        </Box>
      </Box>
    );
  }

  const capitalize = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  const renderTextInput = (label: String, value: String, key: String) => {
    return (
      <TextInputField
        isError={isShowError(key)}
        helperText={isShowErrorMessage(key)}
        label={label}
        width={"100%"}
        fieldViewStyle={{ width: key !== 'first_name' || key !== 'last_name' ? "97%" : "100%" }}
        value={value}

        onChange={(text: any) => {
          if (key === "first_name") {
            setFirstName(capitalize(text.target.value));
          } else if (key === "last_name") {
            setLastName(capitalize(text.target.value));
          } else if (key === "email") {

            setEmail(text.target.value);
            validateEmailId(text.target.value);
          } else if (key === "mobile") {
            if (text.target.value.length <= 100) {
              const mob = text.target.value.replace(/(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4");
              setMobilePhone(mob);
              validatePhone(mob);
            }
          }
        }}
      />
    );
  };

  const renderCheckBox = (label: String, value: Boolean, key: String) => {
    return (
      <CheckBox
        checked={value}
        onChange={(value: any) => {
          if (key === "email_check") {
            setOTPEmail(value.target.checked);
          } else if (key === "mobile_check") {
            setOTPMobile(value.target.checked);
          } else if (key === "business_check") {
            setIsBusinessCheck(value.target.checked);
            setIsPublicCheck(false);
            setIsGovernmentCheck(false);
          } else if (key === "public_check") {
            setIsPublicCheck(value.target.checked);
            setIsGovernmentCheck(false);
            setIsBusinessCheck(false);
          } else if (key === "government_check") {
            setIsGovernmentCheck(value.target.checked);
            setIsBusinessCheck(false);
            setIsPublicCheck(false);
          }
          if (value.length !== 0) {
            setTypeNotCheck(false);
          }
        }}
        value="top"
        label={label}
        labelPlacement="end"
      />
    );
  };
  const handlePasswordBlur = () => {
    setShowPassword(false);
  };

  const handleConfirmPasswordBlur = () => {
    setShowConfirmPassword(false);
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleToggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const [siteKey, setSiteKey] = React.useState("");
  const getRecatchaKey = async () => {
    const response = await HomeApi.getRecaptcha();
    if (response.status === true) {
        setSiteKey(response.data.site_key)
    }
  }

  useEffect(() => {
    getRecatchaKey()
  }, [])

  const renderpassword = () => {
    return (
      <FormControl
        sx={{ width: "100%", background: WHITE }}
        variant="outlined"
      >
        <TextField
          type={showPassword ? "text" : "password"}
          label={t("YC_PASSWORD")}
          value={password}
          onChange={(value) => {
            setPassword(value.target.value);
            setPasswordsMatch(value.target.value === isConfirmPassword);
            passwordValid(value.target.value)
          }}
          onBlur={handlePasswordBlur}
          fullWidth
          margin="normal"
          error={showErrorMessage.password}
          helperText={showErrorMessage.password && t("YC_PASSWORD_VALID")}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleTogglePasswordVisibility}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
        />
        <TextField
          type={showConfirmPassword ? "text" : "password"}
          label={t("YC_CONFIRM_PASSWORD")}
          value={isConfirmPassword}
          onChange={(value) => {
            setConfirmPassword(value.target.value);
            setPasswordsMatch(value.target.value === password);
            passwordValid(value.target.value)
          }}
          onBlur={handleConfirmPasswordBlur}
          fullWidth
          margin="normal"
          error={!passwordsMatch}
          helperText={!passwordsMatch && t("YC_PASSWORD_NOT_MATCH")}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleToggleConfirmPasswordVisibility}>
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ),
          }}
        />
      </FormControl>
    );
  };

  /**
   *
   * @returns SignUp Step One Right Container View
   */
  function RightContainerSignUpStepOneView() {
    // Render SinUp View
    return (
      <Box className={rightContainer}>
        <Box className={titleText}>
          <Typography variant="h6" fontSize={24}>
            {t("YC_GET_STARTED_ACCOUNT_EX")}
          </Typography>
        </Box>
        <Box className={inputView}>
          <Box className={verticalInputView}>
            {renderTextInput(t("YC_FIRST_NAME"), firstName, "first_name")}
            {renderTextInput(t("YC_LAST_NAME"), lastName, "last_name")}
          </Box>
          <Box sx={{ mt: 0, width: "100%" }}>
            {renderTextInput(t("YC_EMAIL_ADDRESS"), email, "email")}
          </Box>
          <Box sx={{ mt: 0, width: "100%" }}>
            {renderTextInput(t("YC_MOBILE_PHONE"), mobilePhone, "mobile")}
          </Box>
          <Typography sx={{ pt: 3 }} fontSize={16} className={otpTitle}>
            {t("YC_SEND_OTP_MY")}
          </Typography>
          <Box sx={{ my: 2 }} className={checkBoxView}>
            {renderCheckBox(t("YC_EMAIL"), isOTPEmail, "email_check")}
            <Box sx={{ width: 20 }}></Box>
            {renderCheckBox(t("YC_MOBILE"), isOTPMobile, "mobile_check")}
          </Box>
          <SlideButton
            backgroundWidth={"75%"}
            boxWidth={"55%"}
            buttonWidth={"80%"}
            slideOnClick={slideBtnAction}
          />

          <Box
            sx={{
              mt: 2,
              mb: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isShowRecaptcha && (
              <ReCAPTCHA
                sitekey= {siteKey ? siteKey : "6Lct7xcqAAAAAGbna9-xTHfbV9DhWyUSH_Y9R88c"}//"6Ley9ZgjAAAAAGGGJDAAd4jb1R-d0xjyqxS0ZTe6"
                onChange={handleRecaptchaChange}
              />
            )}
          </Box>

          <Box sx={{ mt: 0, width: "45%" }}>
            <CustomButton
              disabled={isButtonDisabled}
              placeHolder={t("YC_VERIFY_ME")}
              textTransform="none"
              background={isButtonDisabled ? GREY : BLACK}
              height="40px"
              width={"100%"}
              borderRadius="7.2px"
              onClick={verifyMeBtnAction}
              buttonStyle={{
                HEADER2,
              }}
            />
          </Box>
          <Box className={loginText}>
            <Typography sx={{ pt: 3 }} className={infoLabel}>
              {t("YC_ALREADY_ACCOUNT")}
            </Typography>
            {/* <SignInPage /> */}
            <Button
              sx={{ mt: 3 }}
              variant="text"
              onClick={handleOpen}
              className={buttonClass}
            >
              <Link sx={{ color: LINK }} className={infoLabel}>
                {t("YC_LOGIN_HERE")}
              </Link>
            </Button>
            <Modal
              open={isShowModal}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div>
                <SignInPage />
              </div>
            </Modal>
          </Box>
        </Box>
      </Box>
    );
  }

  /**
   *
   * @returns SignUp Step Two Right Container View
   */
  function RightContainerSignUpStepTwoView() {
    const topMessage = t("YC_SIGN_UP_TEXT_ONE").replace(
      "[email - phone]",
      `${isOTPEmail && isOTPMobile
        ? `[${email} & ${mobilePhone}]`
        : isOTPEmail
          ? `[${email}]`
          : `[${mobilePhone}]`
      }`
    );
    return (
      <Box className={rightContainer}>
        <Box className={titleText}>
          <Typography variant="h6" fontSize={24}>
            {t("YC_VERIFICATION")}
          </Typography>
        </Box>
        <Box className={subTitle}>
          <Typography
            variant="subtitle1"
            sx={{ ml: 5, mr: 5, textAlign: "left" }}
            fontSize={16}
          >
            {topMessage}
          </Typography>
        </Box>
        <Divider
          orientation="vertical"
          variant="middle"
          sx={{ mr: 8, ml: 8, mt: 1, background: "rgba(0, 0, 0, 0.35)" }}
          flexItem
          className={dividerStyleVerticle}
        />
        <Box className={inputView}>
          <Typography variant="subtitle1" fontSize={16} sx={{ mt: 1, mb: 1 }}>
            {t("YC_WHICH_ACCOUNT_TYPE")}
          </Typography>
          <Box className={checkBoxView} sx={{ width: "95%" }}>
            {renderCheckBox(t("YC_BUSINESS"), isBusinessCheck, "business_check")}
            {renderCheckBox(t("YC_PUBLIC"), isPublicCheck, "public_check")}
            {renderCheckBox(
              t("YC_GOVERNMENT"),
              isGovernmentCheck,
              "government_check"
            )}
            <HelpOutline sx={{ display: { xs: "none", sm: "none", lg: "flex", xl: "flex" }, width: 20, height: 20 }} />
          </Box>
          {typeNotCheck &&
            <Typography color={RED}>{t("YC_CHECK_BOX")}</Typography>
          }
        </Box>
        <Divider
          orientation="vertical"
          variant="middle"
          sx={{ mr: 8, ml: 8, mt: 2, background: "rgba(0, 0, 0, 0.35)" }}
          flexItem
          className={dividerStyleVerticle}
        />
        <Typography sx={{ mt: 1 }} className={otpViewTitle}>
          {t("YC_OTP")}
        </Typography>
        <OtpInput
          value={OTP}
          isDisabled={isOtpVerified}
          onChange={(value: string, header: any) => {
            setOTP(value)
            if (value.length === 4) {
              // call verify api
              verifyOtp(value, header)
            }
          }}
          numInputs={4}
          separator={<span> {"   "} </span>}
          containerStyle={{
            marginTop: 10,
            justifyContent: "space-evenly",
            width: "50%",
          }}
          inputStyle={{
            width: 40,
            height: 40,
            background: "rgba(215, 215, 215, 0.01)",
            border: "1px solid #0F5CD0",
            boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.07)",
            borderRadius: 5,
          }}
        />
        {invalidOtp &&
          <Typography color={RED}>{t("YC_INVALID_OTP")}</Typography>
        }
        <Divider
          orientation="vertical"
          variant="middle"
          sx={{ mr: 8, ml: 8, background: "rgba(0, 0, 0, 0.35)", mt: 3 }}
          flexItem
          className={dividerStyleVerticle}
        />
        {isOtpVerified && <Box sx={{}} style={{ marginRight: 10, marginLeft: 10 }}>
          {renderpassword()}
        </Box>}
        {isOtpVerified && <Divider
          orientation="vertical"
          variant="middle"
          sx={{ mr: 8, ml: 8, background: "rgba(0, 0, 0, 0.35)", mt: 2 }}
          flexItem
          className={dividerStyleVerticle}
        />}
        <Box className={privacyPolicyTextView}>
          <Typography
            variant="subtitle1"
            sx={{ textAlign: "center", width: "70%", lineHeight: "20px" }}
            fontSize={16}
          >
            {t("YC_TERMS_POLICY_TEXT")}
            <br />
            <Link href="#">{t("YC_TOC")}</Link>
            {" and "}
            <Link href="#">{t("YC_PP")}</Link>
          </Typography>
        </Box>
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <CheckBox
            value="top"
            label={t("YC_TICK_CONTINUE")}
            labelPlacement="end"
            onChange={(value: any) => {
              setTikToContinue(value.target.checked);
            }}
          />
        </Box>
        <Box sx={{ mt: 1, width: "50%" }}>
          <CustomButton
            disabled={isactiveButtonDisabled}
            placeHolder={t("YC_ACTIVATE_MY_ACCOUNT")}
            textTransform="none"
            background={isactiveButtonDisabled ? GREY : BLACK}
            height="40px"
            width={"100%"}
            borderRadius="7.2px"
            buttonStyle={{
              HEADER2,
            }}
            onClick={activateBtnAction}
          />
        </Box>
        <Divider
          orientation="vertical"
          variant="middle"
          sx={{ mr: 8, ml: 8, background: "rgba(0, 0, 0, 0.35)", mt: 2.5 }}
          flexItem
          className={dividerStyleVerticle}
        />

        <Box sx={{ mt: 0.3, width: "50%" }}>
          <Typography
            sx={{ color: ERROR, mb: 1, display: "flex" }}
            variant="subtitle1"
            fontSize={16}
          >
            {"I did not receive my OTA"}
            <Typography
              sx={{ transform: "rotate(89.28deg)", marginLeft: "5px" }}
            >
              {" :-("}
            </Typography>
          </Typography>
          <CustomButton
            placeHolder={t("YC_RESEND_OTP_NOW_EX")}
            textTransform="none"
            background={ERROR}
            height="40px"
            width={"100%"}
            borderRadius="7.2px"
            buttonStyle={{
              HEADER2,
            }}
          />
        </Box>
        <Box className={loginText}>
          <Typography sx={{ pt: 3 }} className={infoLabel}>
            {t("YC_ALREADY_ACCOUNT")}
          </Typography>
          {/* <SignInPage /> */}
          <Button
            sx={{ mt: 3 }}
            variant="text"
            onClick={handleOpen}
            className={buttonClass}
          >
            <Link sx={{ color: LINK }} className={infoLabel}>
              {t("YC_LOGIN_HERE")}
            </Link>
          </Button>
          <Modal
            open={isShowModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <SignInPage />
          </Modal>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <HomepageHeader isFrom={"signup"} />
      <Box component="main" sx={{ mt: 10 }}>
        <Box sx={{display: { xs: "block", sm: "block", lg: "flex", xl: "flex" }}} className={mainContainer}>
          {leftContainerView()}
          {isStepTwo
            ? RightContainerSignUpStepTwoView()
            : RightContainerSignUpStepOneView()}
        </Box>
        <HomepageFooter
          isLinkFooter={true}
          displayText={t("YC_FOOTER_COPYRIGHT_2022")}
        />
      </Box>
    </>
  );
};

export default SignUpPage;
