import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { YC_FOOTER_CONCEPTUALISED, YC_MYP_MARKETING } from "utils/strings";
import { Link } from "@mui/material";
import { WHITE } from "utils/colors";

const useStyles = makeStyles(() => ({
  footer: {
    flexDirection: "row",
    justifyContent: "space-evenly",
    position: "fixed",
    color: "#000000",
    // marginTop: '10%',
    width: "100%",
    background: WHITE,
    height: 30,
    bottom: 0,
    zIndex: 2999,
    alignItems: 'center',
  },
  footerText: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12,
    lineHeight: "15px",
    textAlign: "center",
  },
}));

export default function HomepageFooter(props: any) {
  const { footer, footerText } = useStyles();
  return (
    <Box sx={{ display: "flex" }} className={footer}>
      <Typography className={footerText}>{props.displayText}</Typography>
      {props.isLinkFooter ? (
        <Typography className={footerText}>
          {YC_FOOTER_CONCEPTUALISED}
          <Link href="#">{YC_MYP_MARKETING}</Link>
        </Typography>
      ) : null}
    </Box>
  );
}
