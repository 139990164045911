import { configureStore } from "@reduxjs/toolkit";
import todoSlice from "./reducers/todo-slice";
import loaderSlice from "./reducers/loader-slice";

const store = configureStore({
  reducer: { 
    todo: todoSlice.reducer,
    loader:loaderSlice.reducer
   },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
