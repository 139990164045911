import * as React from "react";
import { Box, CssBaseline, FormControl, InputLabel, OutlinedInput, Select, Typography, } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DashboardPageHeader from "components/dashboard-header/dashboard-header";
import { SUBHEADER1, SUBHEADERBOLD } from "utils/fonts";
import { BLACK, BLUE, GREY, GREY_5, WHITE, YELLOW } from "utils/colors";
import CustomButton from "components/custom-button/custom-button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import crossIcon from "../../../assets/crossIcon.png";
import rightIcon from "../../../assets/rightIcon.png";

const UseStyles = makeStyles(() => ({
    loginContainBlock: {
        flex: 1.6,
        "@media (max-width: 100px)": {
            display: "none",
        },
    },
    buttonStep: {
        width: "40px",
        height: "40px",
        backgroundImage: crossIcon,
        background: "red"
    }
}));

const drawerWidth = 250;

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

function ApplicationBasicSetup(props: any) {

    // const { buttonStep } = UseStyles();
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [planData, setPlanData] = React.useState(props.planData)
    const [isAllowProtectedLog, setIsAllowProtectedLog] = React.useState(false)

    const textFieldView = (key: string, label: string = "", value: string) => {
        return (
            <Box sx={{ width: { xs: "100%", sm: "80%", lg: "80%", xl: "80%", height: "40px" } }}>
                <FormControl
                    sx={{
                        mb: 1,
                        width: "100%",
                        boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.07)",
                        height: "40px"
                    }}
                    variant="outlined"
                >
                    <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
                    <OutlinedInput
                        sx={{ height: "40px" }}
                        label={label}
                        value={value}
                        disabled={false}
                        onChange={(text: any) => {
                            const trimStr = text.target.value.trimStart();
                        }}
                    />
                </FormControl>
            </Box>
        );
    };

    const renderButtonView = (placeHolder: string, key: string, isClick: boolean) => {
        return (
            <Box sx={{
                width: { xs: "90%", sm: "15%", lg: "15%", xl: "15%" },
                marginLeft: "10px", marginRight: "10px"
            }}>
                <CustomButton
                    placeHolder={placeHolder}
                    textTransform="none"
                    background={isClick ? BLACK : WHITE}
                    // marginTop="10px"
                    height="40px"
                    alignItems="center"
                    width="100%"
                    borderRadius="0px"
                    border={BLACK}
                    titleColor={isClick ? WHITE : BLACK}
                    hoverColor={isClick ? WHITE : YELLOW}
                    onClick={() => { }}
                    buttonStyle={{
                        SUBHEADER1
                    }}
                />
            </Box>
        )
    }

    return (
        <div style={{ height: "100%", flex: 1 }}>
            <Box
                sx={{
                    marginBottom: "40px",
                    height: "100%",
                    flex: 1,
                }}
            >
                <CssBaseline />
                <DashboardPageHeader />

                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Box
                        sx={{
                            width: "100%",
                        }}
                    >
                        <Typography style={SUBHEADERBOLD}>
                            Mauritius Yellow Markets
                        </Typography>
                        <Typography>
                            Basic Setup Of Local Marketplace
                        </Typography>
                        <Box sx={{width: "100%",display: "flex", justifyContent: "flex-start"}}>
                            <CustomButton
                                placeHolder={ t("YC_BACK")}
                                textTransform="none"
                                background={BLUE}
                                height="30px"
                                alignItems="center"
                                marginLeft="30px"
                                marginTop="10px"
                                width= "10%"
                                borderRadius="4px"
                                titl
                                border={BLACK}
                                onClick={() => {navigate(-1)}}
                                buttonStyle={{
                                    SUBHEADER1
                                }}
                            />
                        </Box>
                        <hr
                            style={{
                                color: BLACK,
                                backgroundColor: BLACK,
                                width: "95%",
                                marginTop: 10,
                                marginLeft: "2.5%",
                                height: 2
                            }}
                        />
                        <Typography sx={{ marginLeft: "3%", width: "95%", textAlign: "start" }}>
                            {`${t("YC_APPLICATION") + " " + ":" + " " + t("YC_SETTING")}`}
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: { xs: "column", sm: "row", lg: "row", xl: "row" },
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    height: "100%",//{xs: "70", sm: "60%", lg: "50%", xl: "50%"},
                                    width: "95%", //{xs: "100", sm: "30%", lg: "30%", xl: "30%"},
                                    border: 2,
                                    borderColor: GREY,
                                    boxShadow: 2,
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                    marginLeft: "2.5%",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: { xs: "column", sm: "row", lg: "row", xl: "row" },
                                        width: "100%",
                                        marginTop: "10px",
                                        height: { xs: "250px", sm: "40px", lg: "40px", xl: "40px" },
                                    }}
                                >
                                    {renderButtonView("Basic Settings", "basic_settings", true)}
                                    {renderButtonView("Upload Files", "upload_files", false)}
                                    {renderButtonView("Category Settings", "category_settings", false)}
                                    {renderButtonView("Tax Settings", "tax_settings", false)}
                                    {renderButtonView("Brand Settings", "brand_settings", false)}
                                    {renderButtonView("Advanced Settings", "advanced_settings", false)}
                                </Box>
                                <hr
                                    style={{
                                        color: BLACK,
                                        backgroundColor: BLACK,
                                        width: "98%",
                                        marginLeft: "1%",
                                        height: 2
                                    }}
                                />
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%"
                                }}
                                >
                                    <Typography style={{ marginLeft: "10px", width: "20%", textAlign: "start" }}>
                                        {t("YC_DEFAULT_CURRENCY")}
                                    </Typography>
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginLeft: "5%",
                                        width: "70%"
                                    }}
                                    >
                                        <Select
                                            sx={{ height: 40, textAlign: "start" }}
                                            value={"Mauritius Rupees (MUR)"}
                                            displayEmpty
                                            inputProps={{ "aria-label": "Without label" }}
                                            onChange={() => { }}
                                            placeholder={t("YC_ADMINISTRATOR")}
                                            renderValue={(selected: any) => {
                                                if (selected.length === 0) {
                                                    return <>{t("YC_ADMINISTRATOR")}</>;
                                                }
                                                return selected;
                                            }}
                                        >
                                        </Select>
                                        <Typography sx={{ minWidth: "100%", textAlign: "start", color: GREY_5, fontSize: "12px" }}>
                                            {t("YC_CONTROLS_CURRENCY_TEXT")}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    marginTop: "30px"
                                }}
                                >
                                    <Typography style={{ marginLeft: "10px", width: "20%", textAlign: "start" }}>
                                        {t("YC_CURRENCY_POSITION")}
                                    </Typography>
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginLeft: "5%",
                                        width: "70%"
                                    }}
                                    >
                                        <Select
                                            sx={{ height: 40, textAlign: "start" }}
                                            value={"Left (MUR 99.99)"}
                                            displayEmpty
                                            inputProps={{ "aria-label": "Without label" }}
                                            onChange={() => { }}
                                            placeholder={t("YC_ADMINISTRATOR")}
                                            renderValue={(selected: any) => {
                                                if (selected.length === 0) {
                                                    return <>{t("YC_ADMINISTRATOR")}</>;
                                                }
                                                return selected;
                                            }}
                                        >
                                        </Select>
                                        <Typography sx={{ minWidth: "100%", textAlign: "start", color: GREY_5, fontSize: "12px" }}>
                                            {t("YC_CONTROLS_CURRENCY_SYMBOL_TEXT")}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    marginTop: "30px"
                                }}
                                >
                                    <Typography style={{ marginLeft: "10px", width: "20%", textAlign: "start" }}>
                                        {t("YC_THOUSAND_SEPARATOR")}
                                    </Typography>
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginLeft: "5%",
                                        width: "70%"
                                    }}
                                    >
                                        {textFieldView(
                                            "thousand_separator",
                                            t("YC_THOUSAND_SEPARATOR"),
                                            "inputData"
                                        )}
                                        <Typography sx={{ minWidth: "100%", textAlign: "start", color: GREY_5, fontSize: "12px" }}>
                                            {t("YC_THOUSAND_SEPARATOR_TEXT")}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    marginTop: "30px"
                                }}
                                >
                                    <Typography style={{ marginLeft: "10px", width: "20%", textAlign: "start" }}>
                                        {t("YC_DECIMAL_SEPARATOR")}
                                    </Typography>
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginLeft: "5%",
                                        width: "70%"
                                    }}
                                    >
                                        {textFieldView(
                                            "decimal_separator",
                                            t("YC_DECIMAL_SEPARATOR"),
                                            "inputData"
                                        )}
                                        <Typography sx={{ minWidth: "100%", textAlign: "start", color: GREY_5, fontSize: "12px" }}>
                                            {t("YC_DECIMAL_SEPARATOR_TEXT")}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    marginTop: "30px"
                                }}
                                >
                                    <Typography style={{ marginLeft: "10px", width: "20%", textAlign: "start" }}>
                                        {t("YC_NUMBER_DECIMALS")}
                                    </Typography>
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "flex-start",
                                        marginLeft: "5%",
                                        width: "40%"
                                    }}
                                    >
                                        {textFieldView(
                                            "number_decimals",
                                            t("YC_NUMBER_DECIMALS"),
                                            "inputData"
                                        )}
                                        <Typography sx={{ minWidth: "100%", textAlign: "start", color: GREY_5, fontSize: "12px" }}>
                                            {t("YC_NUMBER_DECIMALS_TEXT")}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    marginTop: "30px"
                                }}
                                >
                                    <Typography style={{ marginLeft: "10px", width: "20%", textAlign: "start" }}>
                                        {t("YC_ALLOW_EDIT_PROTECTED_LOGS")}
                                    </Typography>
                                    <div style={{ width: "40px", height: "40px", borderWidth: "1px", borderColor: BLACK }}
                                        onClick={() => {
                                            setIsAllowProtectedLog(!isAllowProtectedLog)
                                        }}>
                                        <img src={isAllowProtectedLog === true ? rightIcon : crossIcon} style={{ width: "30px", height: "30px" }} />
                                    </div>
                                    <Box sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        marginLeft: "2%",
                                        width: "70%"
                                    }}
                                    >
                                        <Typography sx={{ minWidth: "100%", textAlign: "start", color: GREY_5, fontSize: "12px" }}>
                                            {t("YC_ALLOW_EDIT_PROTECTED_LOGS_TEXT")}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    marginTop: "30px",
                                    justifyContent: "flex-start"
                                }}
                                >
                                    <CustomButton
                                        placeHolder={t("YC_SAVE_SETTINGS")}
                                        textTransform="none"
                                        background={BLUE}
                                        marginLeft="10px"
                                        height="40px"
                                        alignItems="center"
                                        width="18%"
                                        borderRadius="5px"
                                        border={BLACK}
                                        titleColor={WHITE}
                                        hoverColor= {YELLOW}
                                        onClick={() => { }}
                                        buttonStyle={{
                                            SUBHEADER1
                                        }}
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <hr
                            style={{
                                color: BLACK,
                                backgroundColor: BLACK,
                                width: "95%",
                                marginTop: 50,
                                marginLeft: "2.5%",
                                height: 2
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </div>
    );
}

export default ApplicationBasicSetup;
