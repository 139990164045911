import { Avatar, Box, Divider, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, Stack, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckBox from "components/check-box/check-box";
import CustomButton from "components/custom-button/custom-button";
import FileUpload from "components/file-uploader/image-uploader";
import PasswordField from "components/password-field/password-field";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import HomeApi from "services/HomeAPi";
import { BLACK, BLUE, DARKGREY, DARK_GRAY, GREY, RED, WHITE, YELLOW } from "utils/colors";
import { SUBHEADER1, SUBHEADERBOLD } from "utils/fonts";
import { validateEmail } from "utils/helper";
import Notification from "views/Notifications/Notification";

const EditUserDialogStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        borderRadius: "7.2px",
        marginTop: "10px",
        marginLeft: "20px",
        marginBottom: "10px",
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "90%",
        overflowY: 'auto',
        OverflowX: "none",
        maxWidth: 700,
        minWidth: 450,
        background: "white",
    },
    dividerStyleVerticle: {
        borderColor: GREY,
        borderWidth: 0.5,
        opacity: 1,
    },
    otpViewTitle: {
        fontSize: 16,
        fontWeight: "500",
        color: "#1F2328",
        lineHeight: "24px",
    },
    titleText: {
        display: "flex",
        marginTop: 20,
        marginBottom: 20,
        justifyContent: "center",
        alignItems: "center",
    },
    subTitle: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "28px",
        textAlign: "left",
        color: BLACK,
    }

}))
function UserSettings(props: any) {
    const { boxView, dividerStyleVerticle, otpViewTitle, titleText, subTitle } = EditUserDialogStyle();
    const { t } = useTranslation()

    const [controllerOfAccount, setControllerOfAccount] = useState("");
    const [profilePhoto, setProfilePhoto] = useState<any | null>();
    const [profileToUpload, setProfileToUpload] = useState("")
    const [isDisablePersonalOptionData, setIsDisablePersonalOptionData] = React.useState(true);
    const [isDisableContactInfo, setIsDisableContactInfo] = React.useState(true);
    const [isValidEmail, setIsValidEmail] = React.useState({ value: true, error: "" })
    const [OTP, setOTP] = React.useState("");
    const [isOTPEmail, setOTPEmail] = React.useState(false);
    const [invalidOtp, setInvalidOtp] = React.useState(false);
    const [isOtpVerified, setOtpVerified] = React.useState(false);
    const [isShowModal, setIsShowModal] = React.useState(false);
    const [inputData, setInputData] = React.useState({
        userName: "",
        firstName: "",
        lastName: "",
        email: "",
        mobile: "",
        displayMobileNo: "",
        userRole: ""
    });


    const [isPasswordNotMatch, setIsPasswordNotMatch] = React.useState(false)
    const [isDisableForAccountManage, setIsDisableForAccountManage] = React.useState(true)
    const [roleList, setRoleList] = React.useState([t("YC_ADMIN"), t("YC_HEAD_OF_DEPARTMENT"), t("YC_EDITOR_IN_CHIEF"), t("YC_EDITORIAL"), t("YC_SALES_MANAGER"), t("YC_SALES_REP")])
    const [roleNameList, setRoleNameList] = React.useState([])
    // const [userRole, setuserRole] = React.useState("");
    const [accountManageData, setAccountManageData] = React.useState({
        displayMobileNo: "",
        mobile: "",
        code: "",
        email: "",
        newPassword: "",
        retypePassword: ""
    });

    const [isHover, setIsHover] = React.useState(false);

    const handleMouseEnter = () => {
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setIsHover(false);
    };

    const getUserId = () => {
        let userId
        const loginUserDetails = localStorage.getItem("user_Details")
        if (loginUserDetails) {
            const details = JSON.parse(loginUserDetails)
            userId = details.id
        }
        return userId
    }

    const getUserProfilePhoto = async () => {
        const response = await HomeApi.getUserProfilePhoto(getUserId());
        if (response.photo_url !== "") {
            setProfilePhoto(response.photo_url);
            let uploadImage: any
            fetch(response.photo_url)
            .then((res) => res.blob())
            .then((myBlob) => {
                uploadImage = new File([myBlob], 'image.jpeg', {type: myBlob.type});
                setProfileToUpload(uploadImage)
            });
        }
    }

    const getRoleList = async () => {
        let userId;
        const loginUserDetails = localStorage.getItem("user_Details")
        if (loginUserDetails) {
            const details = JSON.parse(loginUserDetails)
            userId = details.id
        }
        const response = await HomeApi.getRole(userId);
        if (response.status === true) {
            setRoleList(response.data)
            let tempArray: any = [];
            response.data.map((role: any) => {
                tempArray.push(role.doc)
            });
            setRoleNameList(tempArray)
        }
    }

    useEffect(() => {
        getUserProfilePhoto()
        getRoleList()
        getExistingUserData()
    }, [])

    const isValid = () => {
        if (inputData.firstName !== "" && inputData.lastName !== "") {
            setIsDisablePersonalOptionData(false);
        } else {
            setIsDisablePersonalOptionData(true);
        }

        if (inputData.email !== "" && isValidEmail.value) { // isValidEmail.value === true && contactData.mobile !== ""
            setIsDisableContactInfo(false);
        } else {
            setIsDisableContactInfo(true);
        }

    };

    function setProfilePhotoData(data: any) {
        setProfileToUpload(data.target.files[0])
        setProfilePhoto(URL.createObjectURL(data.target.files[0]));
    }

    useEffect(() => {
        isValid()
    }, [inputData.firstName, inputData.lastName, isValidEmail.value, inputData.mobile])

    const getExistingUserData = async () => {
        const response = await HomeApi.getExistingUserData(getUserId());
        if (response.status === true) {
            // Notification.notifySuccess(response.message)
            setInputData({
                userName: response.data[0].username,
                firstName: response.data[0].name,
                lastName: response.data[0].lname,
                email: response.data[0].email,
                mobile: response.data[0].mobile,
                displayMobileNo: response.data[0].mobile,
                userRole: response.data[0].role
            })
        } else {
            Notification.notifyError(response.message)
        }
    };

    const uploadPhoto = async () => {
        let formData = new FormData();    //formdata object  //append the values with key, value pair
        formData.append('profile_photo', profileToUpload)

        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        const response = await HomeApi.uploadUserProfilePhoto(formData, config, getUserId());
        if (response.status === true) {
            Notification.notifySuccess(response.message)
            props.setIsUserEdited(true)
        } else {
            Notification.notifyError(response.message)
        }
    };

    const postUpdateExistingUser = async () => {
        const payload = 
            {
                username: inputData.userName,
                name: inputData.firstName,
                lname: inputData.lastName,
                mobile: inputData.mobile,
                email: inputData.email,
                role: inputData.userRole
            }
        const response = await HomeApi.postUpdateExistingUser(payload, getUserId());
        if (response.status === true) {
        } else {
            Notification.notifyError(response.message)
        }
    }

    return (
        <Box >
            <Box sx={{
                borderRadius: "7.2px", margin: 2, padding: 2,
                boxShadow: 2,
                border: 2,
                display: "flex",
                flexDirection: "row",
                marginTop: 10,
                justifyContent: "space-between",
                borderColor: 'primary.light',
                width: {xs: "80%", sm: "60%", lg: "40%", xl: "40%"}
            }}>
                {/* Profile name and photo */}
                <Box sx={{
                    display: "block",
                    flexDirection: "column",
                    paddingBottom: "10px",
                    marginRight: 3
                }}>
                    <Typography style={{ fontStyle: "normal", fontWeight: 'bold', fontSize: 14 }} gutterBottom>{t("YC_PROFILE_PHOTO")}</Typography>
                    <Avatar variant="square" src={profilePhoto} sx={{ width: 80, height: 90 }} />
                    {/* <img src={file} /> */}
                </Box>

                {/* //choose media and update avtart */}
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "10px",
                    marginTop: "10px",
                    width: "50%"
                }}>
                    <div style={{
                        height: "30px",
                        alignItems: "center",
                        marginRight: "10px",
                        marginLeft: "5px",
                        marginTop: "20px",
                        marginBottom: "20px",
                        width: "80%",
                        borderRadius: "4px"
                    }}>
                        <label htmlFor="profileFile" style={{
                            background: BLACK,
                            height: "32px",
                            textAlign: "center",
                            paddingTop: 5,
                            color: isHover ? YELLOW : WHITE,
                            width: "100%",
                            borderRadius: "4px", fontWeight: "bold", fontSize: 15
                        }}
                            onMouseEnter={handleMouseEnter}
                            onMouseLeave={handleMouseLeave}>
                            {t("YC_CHOOSE_MEDIA")}
                        </label>
                        <input id="profileFile" style={{ visibility: "hidden" }} type={"file"} onChange={setProfilePhotoData} />
                    </div>
                    <CustomButton
                        placeHolder={t("YC_UPDATE_AVATA")}
                        textTransform="none"
                        background={BLUE}
                        height="30px"
                        alignItems="center"
                        marginRight="10px"
                        marginLeft="5px"
                        width="80%"
                        borderRadius="4px"
                        onClick={() => uploadPhoto()}
                        buttonStyle={{
                            SUBHEADER1
                        }}>
                    </CustomButton>

                </Box>

            </Box>
            <Box sx={{
                borderRadius: "7.2px", margin: 2, padding: 2,
                boxShadow: 2,
                border: 2,
                display: "block",
                borderColor: 'primary.light',
                width: {xs: "90%", sm: "70%", lg: "70%", xl: "70%"}
            }}>

                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "10px"
                }}>
                    <Typography variant="caption" sx={{textAlign: "start", width: "20%"}}> {t("YC_USERNAME_REQUIRED")}  </Typography>
                    <Box sx={{
                        marginLeft: "13%",
                        width: "100%",
                        boxShadow: 4
                    }}>
                        <TextField style={{
                            width: "100%",
                            fontStyle: "italic"
                        }} id="username" label={t("YC_USE_LETTERS_WITHOUT_SPACE")} variant="outlined"
                            size="small"
                            value={inputData.userName}
                            onChange={(text) => {
                                const trimStr = text.target.value.trimStart();
                                setInputData((prevFields) => ({
                                    ...prevFields,
                                    userName: trimStr,
                                }));
                            }} />
                    </Box>
                </Box>

                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "10px"
                }}>
                    <Typography variant="caption" sx={{textAlign: "start", width: "20%"}}>{t("YC_NAME_REQUIRED")}</Typography>

                    <Box sx={{
                    display: "flex",
                    width: "72.5%",
                    flexDirection: {xs: "column", sm: "row", lg: "row", xl: "row"},
                    justifyContent: "space-between",
                    paddingBottom: "10px"
                }}>
                    <TextField style={{
                        fontStyle: "italic",
                    }}
                        sx={{ boxShadow: 4, width: {xs: "60%", sm: "60", lg: "45%", xl: "45%"}, marginLeft: {xs: "1px", sm: "0px", lg: "0px", xl: "0px"}, marginTop: {xs: "10px"} }}
                        id="mobile" label={t("YC_FIRST_NAME")} variant="outlined" size="small"
                        value={inputData.firstName}
                        onChange={(text) => {
                            const trimStr = text.target.value.trimStart();
                            setInputData((prevFields) => ({
                                ...prevFields,
                                firstName: trimStr,
                            }));
                        }} />

                    <TextField style={{
                        fontStyle: "italic",
                    }}
                        sx={{ boxShadow: 4, width: {xs: "60%", sm: "60", lg: "45%", xl: "45%"}, marginLeft: {xs: "1px"}, marginTop: {xs: "10px"} }}
                        id="mobile" label={t("YC_LAST_NAME")} variant="outlined" size="small"
                        value={inputData.lastName}
                        onChange={(text) => {
                            const trimStr = text.target.value.trimStart();
                            setInputData((prevFields) => ({
                                ...prevFields,
                                lastName: trimStr,
                            }));
                        }}
                    />
                </Box>
                </Box>

                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "10px"
                }}>
                    <Typography variant="caption" sx={{textAlign: "start", width: "20%"}}>{t("YC_EMAIL_REQUIRED")}</Typography>
                    <Box sx={{
                        marginLeft: "13%",
                        width: "100%", boxShadow: 4
                    }}>
                        <TextField style={{
                            width: "100%",
                            fontStyle: "italic",
                        }}
                            id="email" label={t("YC_OFFICIAL_MAIL_ID")} variant="outlined" size="small"
                            value={inputData.email}
                            onChange={(text) => {
                                const trimStr = text.target.value.trimStart();
                                const validEmail = validateEmail(trimStr);
                                setIsValidEmail({ value: validEmail, error: !validEmail ? t("YC_EMAIL_VALID") : "" })
                                setInputData((prevFields) => ({
                                    ...prevFields,
                                    email: trimStr,
                                }));
                            }} />
                        {(inputData.email !== "" && !isValidEmail.value) && (<div style={{ color: "red" }}> {t("YC_EMAIL_VALID")} </div>)}
                    </Box>
                </Box>

                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "10px"
                }}>
                    <Typography variant="caption" sx={{textAlign: "start", width: "20%"}}>{t("YC_MOBILE_NO_REQUIRED")}</Typography>
                    <Box sx={{
                        marginLeft: "13%",
                        width: "100%", boxShadow: 4
                    }}>
                        <Box sx={{ width: { xs: "70%", sm: "71%", lg: '71%', xl: '72%' } }}>
                            <PhoneInput
                                country={"ma"}
                                value={inputData.displayMobileNo}
                                containerStyle={{ marginLeft: 5, marginTop: 3, border: 'none', height: 30, background: '#F9F9FA' }}
                                buttonStyle={{ background: '#F9F9FA', border: 'none' }}
                                inputStyle={{ border: 'none', width: "80%", height: 30, background: '#F9F9FA', fontSize: 12, color: BLACK }}
                                onChange={(phone: string, country: any) => {
                                    const reducedPhone = phone.replace(
                                        country.dialCode, '',);
                                    setInputData((prevFields) => ({
                                        ...prevFields,
                                        displayMobileNo: phone,
                                        mobile: reducedPhone,
                                        code: country.dialCode
                                    }))
                                }}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    // justifyContent: "space-between",
                    paddingBottom: "10px"

                }}>
                    <Typography variant="caption"> {t("YC_ROLE")}   </Typography>
                    <Box sx={{ marginLeft: {xs: "21.5%", sm: "22.5%", lg: "24.5%", xl: "24.5%"}, height: 40, boxShadow: 4 }}>
                        <Select
                            sx={{ height: 40 }}
                            value={inputData.userRole}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            onChange={(event: any) => {
                                const selectedRole: any = roleNameList.filter((role: any) => (role === event.target.value))
                                roleList.map((roleData: any) => {
                                    if (roleData.doc === selectedRole[0]) {
                                        setInputData((prevFields) => ({
                                            ...prevFields,
                                            userRole: roleData.doc,
                                        }));
                                    }
                                })
                            }}
                            // placeholder={t("YC_ADMINISTRATOR")}
                            // renderValue={(selected) => {
                            //     if (selected.length === 0) {
                            //         return <>{t("YC_ADMINISTRATOR")}</>;
                            //     }
                            //     return selected;
                            // }}
                        >
                            {roleNameList.map((item: any) => {
                                return (
                                    <MenuItem
                                        value={item}
                                    >
                                        {item}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                    marginTop: "30px",
                                    marginBottom: "30px",
                                    justifyContent: "flex-start"
                                }}
                                >
                                    <CustomButton
                                        placeHolder={t("YC_SAVE_SETTINGS")}
                                        textTransform="none"
                                        background={BLUE}
                                        marginLeft="15px"
                                        height="40px"
                                        alignItems="center"
                                        width="18%"
                                        borderRadius="5px"
                                        border={BLACK}
                                        titleColor={WHITE}
                                        hoverColor= {YELLOW}
                                        onClick={() => { postUpdateExistingUser() }}
                                        buttonStyle={{
                                            SUBHEADER1
                                        }}
                                    />
                                </Box>
        </Box>
    );
}

export default UserSettings;
