import { Box, Button, Divider, IconButton, SvgIcon, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import flagIcon from '../../assets/flagIcon.png';
import { BLACK, WHITE, PINK, DARKGREY, YELLOW } from "utils/colors";
import moment from "moment";
import TextInputField from "components/text-field/text-field";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const useStyles = makeStyles(() => ({
  boxView: {
    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
    background: "#FFFFFF",
    minHeight: "266px",
    borderRadius: "8px",
  },
  titleText: {
    color: BLACK,
    fontSize: 20,
    fontWeight: "700",
  },
  bodyText: {
    display: "flex",
    fontSize: 16,
    marginTop: 1,
    textAlign: "left",
    fontWeight: "700",
    lineHeight: "18px",
  },
  button: {
    minHeight: "29px",
    width: "20%",
    borderRadius: "8px",
    fontSize: 16,
    fontWeight: "700",
    '&:hover': {
      color: BLACK,
      backgroundColor: YELLOW,
    },
  },
  dividerStyle: {
    opacity: 1,
    width: "83%",
    margin: "auto",
    minHeight: "3px",
  },
  dividerVerticle: {
    opacity: 1,
    height: "28px",
    width: "3px",
    float: "left",
    background: "#1E56AA",
  },
  customeButton: {
    color: BLACK,
    float: "left",
    height: 30,
    width: "99px",
    border: "1px solid black",
    marginLeft: "40px",
    borderRadius: "8px",
    marginTop: "13px",
  }
}));

function DashboardsActivity(props: any) {
  const { boxView, button, titleText, bodyText, dividerStyle, customeButton, dividerVerticle } = useStyles();
  const datetime: string = moment(new Date()).format('DD/MM/YYYY - HH:mm');

  return (
    <Box className={boxView}>
      <Box sx={{ pb: 2, mb: 0.1 }}>
        <Box sx={{ pt: 3 }}>
          <Divider
            orientation="horizontal"
            flexItem
            className={dividerStyle}
            sx={{ mb: 1, marginTop: 0.2 }}
            style={{ background: BLACK }}
          />
        </Box>
        <Typography className={titleText} > My Next Activity </Typography>
        <Box sx={{ my: 1.1, }}>
          <Divider
            orientation="horizontal"
            flexItem
            className={dividerStyle}
            sx={{ mb: 1, marginTop: 0.2 }}
            style={{ background: BLACK }}
          />
        </Box>
        <Box className="row m-0 p-0 ">
          <Box className='col-6 col-lg-6 m-0 p-0'>
            <Typography className={customeButton}>Scheduled</Typography>
            <Typography className={customeButton}>Sport</Typography>
            <Typography className={customeButton}>Location</Typography>
          </Box>
          <Box className='col-6 col-lg-6 m-0 p-0  datetimepicker'>
            <Box className='inputparent'>
              <Divider
                orientation="vertical"
                flexItem
                className={dividerVerticle}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DateTimePicker label={datetime} />
              </LocalizationProvider>
            </Box>
            <Box className='inputparent' >
              <Divider
                orientation="vertical"
                flexItem
                className={dividerVerticle}
              />
              <TextInputField
                label={"Foodball"}
                width={"100%"}
              />
            </Box>
            <Box className='inputparent' >
              <Divider
                orientation="vertical"
                flexItem
                className={dividerVerticle}
              />
              <TextInputField
                label={"Sodnac - Wellness"}
                width={"100%"}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ my: 2, }}>
          <Divider
            orientation="horizontal"
            flexItem
            className={dividerStyle}
            sx={{ mb: 2, marginTop: 0.2 }}
            style={{ background: BLACK }}
          />
        </Box>
        <Box sx={{
          minHeight: "47px",
          width: "95%",
          margin: "auto",
          borderRadius: "8px",
          bgcolor: YELLOW,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
        }}
        >
          <IconButton>
            <img src={flagIcon} alt="icons" />
          </IconButton>
          <Typography className={bodyText} sx={{ color: BLACK }} > My Activities </Typography>
          <Button variant="contained" className={button}>Access</Button>
        </Box>
      </Box>

    </Box>
  );
}
export default DashboardsActivity;
