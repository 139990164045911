import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button, TextField, Typography } from '@mui/material';
import { BLACK, BLUE, GREY, WHITE, YELLOW } from 'utils/colors';
import { useTranslation } from 'react-i18next';
import { HEADER2, SUBHEADERBOLD2 } from 'utils/fonts';
import CustomButton from 'components/custom-button/custom-button';
import HomeApi from 'services/HomeAPi';
import { validateEmail, validateMobilePhone } from "utils/helper";

const useStyles = makeStyles(() => ({
  appText: {
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "23.3397px",
    lineHeight: "50px",
    color: "#FFFFFF",
    textAlign: "start",
  },
  labelMainContainer: { marginTop: 10, marginLeft: 20, width: "30%", display: "flex" },
  labelText1: { textAlign: "end", width: "29.5%" },
  labelText: { textAlign: "end", width: "90%" },
  rightMainContainer: {
    display: "flex",
    border: "1px solid #0F5CD0",
    marginTop: 10,
    marginBottom: 10,
    borderColor: BLUE,
    width: "70%",
    justifyContent: "flex-start",
    flexWrap: "wrap"
  },
  rightMainContainerYellowBorder: {
    display: "flex",
    border: "1px solid #0F5CD0",
    marginTop: 10,
    marginBottom: 10,
    borderColor: YELLOW,
    width: "70%",
    justifyContent: "flex-start",
    flexWrap: "wrap"
  }
}));

export default function ContentManagement() {
  const [value, setValue] = useState(0);
  const { appText, rightMainContainer, rightMainContainerYellowBorder, labelText, labelText1, labelMainContainer } = useStyles();
  const { t } = useTranslation()
  const [isSaveButtonDisable, setIsSaveButtonDisable] = useState(true)
  const [mainCategory, setMainCategory] = useState(["xxxxx", "yyyyyy"])
  const [subCategory, setSubCategory] = useState(["xxxxx", "yyyyy", "zzzzz"])
  const [childCategory, setChildCategory] = useState(["aaaaa", "bbbbbb", "ccccc"])
  const [keywordsData, setKeywordsData] = useState([{
    keywordName: "ICT Services",
    state: false
  },
  {
    keywordName: "Online Marketing",
    state: false
  },
  {
    keywordName: "Digital Marketing",
    state: false
  }, 
  {
    keywordName: "Web Development",
    state: false
  },  
  {
    keywordName: "Graphic Design",
    state: false
  },
  {
    keywordName: "SEO",
    state: false
  },  
  {
    keywordName: "Social Media MArketing",
    state: false
  },
  {
    keywordName: "Banner Advertising",
    state: false
  },  
  {
    keywordName: "Mobile Marketing",
    state: false
  },
  {
    keywordName: "Domain Name Registration",
    state: false
  },
  {
    keywordName: "Hosting",
    state: false
  },
  {
    keywordName: "Consulting",
    state: false
  }
])
  
  const [isValidEmail, setIsValidEmail] = React.useState({ value: true, error: "" })
  const [isValidMobileNumber, setIsValidMobileNumber] = React.useState({value: true, error: ""})

  const [mainCategoryID,setMainCategoryID] = useState(0)
  const [subCategoryID, setSubCategoryID] = useState(0)
  const [childCategoryID, setChildCategoryID] = useState(0)

  const [mainCategoryList, setMainCategoryList] = useState([])
  const [mainCategoryNameList, setMainCategoryNameList] = useState([{
    name: "",
    state: false
  }])

  const [subCategoryList, setSubCategoryList] = useState([])
  const [subCategoryNameList, setSubCategoryNameList] = useState([{
    name: "",
    state: false
  }])

  const [childCategoryList, setChildCategoryList] = useState([])
  const [childCategoryNameList, setChildCategoryNameList] = useState([{
    name: "",
    state: false
  }])

  const [inputData, setInputData] = useState({
    mainCategoryName: "",
    keyword: "",
    companyName: "",
    tradingName: "",
    email: "",
    mobileNumber: "",
    phone: "",
    faxNumber: "",
    streetAddress: "",
    businessDescription: ""
  })
  
  useEffect(() => {
    validateForNextStep()
}, [inputData.companyName, inputData.email, inputData.mobileNumber, inputData.streetAddress, inputData.businessDescription])


const validateForNextStep = () => {
if (inputData.companyName !== "" && inputData.email !== "" && inputData.mobileNumber !== "" && inputData.streetAddress
  !== "" &&  inputData.businessDescription) {
      setIsSaveButtonDisable(false)
} else {
  setIsSaveButtonDisable(true)
}
}

const handleClickOfKeyword = (index: any, keywordName : string) => {
  setKeywordsData((prevKeywords) =>
    prevKeywords.map((keyword, i) =>
        i === index ? { ...keyword, state: !keyword.state } : { ...keyword, state: false }
    )
  );
  setInputData((prevFields) => ({
    ...prevFields,
    keyword: keywordName,
  }))
}

const handleClickOfSubCategory = (index: any, name: string) => {
  const selectedCategory: any = subCategoryNameList.filter((data: any) => (data.name === name))
  subCategoryList.map((category: any) => {
    if (category.content === selectedCategory[0].name) {
      setSubCategoryID(category.elementid)
      getChildCategoryList(`${mainCategoryID}`, category.elementid)
    }
  })
  setSubCategoryNameList((preSubCat) =>
    preSubCat.map((category, i) =>
        // i === index ? { ...category, state: !category.state } : category
      i === index ? { ...category, state: !category.state } : { ...category, state: false }
    )
   
);
}

const handleClickOfChildCategory = (index: any, name: string) => {
  const selectedCategory: any = childCategoryNameList.filter((data: any) => (data.name === name))
  childCategoryList.map((category: any) => {
    if (category.content === selectedCategory[0].name) {
      setChildCategoryID(category.elementid)
    }
  })
  setChildCategoryNameList((preSubCat) =>
    preSubCat.map((category, i) =>
      i === index ? { ...category, state: !category.state } : { ...category, state: false }
    )
  )
}

  const getUserId = () => {
    let userId;
    const loginUserDetails = localStorage.getItem("user_Details")
    if (loginUserDetails){
      const details = JSON.parse(loginUserDetails)
      userId = details.id
    }
    return userId
  }

  const getBusinessDetails = async () => {
    const response = await HomeApi.getBusinessDetails(getUserId())
    if (response.status === true) {
      const data = response.data[0]
      let tempArray: any = [];
      setMainCategoryID(data.mainCategory)
      setSubCategoryID(data.subCategory)
      setChildCategoryID(data.childCategory)
       setInputData({
        mainCategoryName: data.mainCatName,
        keyword: data.keyword,
        companyName: data.companyName,
        tradingName: data.tradeName,
        email: data.email,
        mobileNumber: data.mobile,
        phone: data.phone,
        faxNumber: data.fax_phone_number,
        streetAddress: data.address,
        businessDescription: data.businessdescribtion
      })

      tempArray.push({
        name: data.mainCatName,
        state: false
      })
      setMainCategoryNameList(tempArray)
      getSubCategoryList(data.mainCategory)
      getChildCategoryList(data.mainCategory, data.subCategory)
    }
  }

  useEffect(() => {
    getMainCategoryList()
    getBusinessDetails()
  }, [])

  const getMainCategoryList = async () => {
    const response = await HomeApi.getMainCategoryData();
    if (response.status === true) {
        setMainCategoryList(response.data)
        let tempArray: any = [];
        response.data.map((element: any) => {
          if (mainCategoryID === element.elementid){
            tempArray.push({
              name: element.content,
              state: false
            })
          }
        });
        setMainCategoryNameList(tempArray)
    }
}

useEffect(() => {
  let tempArray: any = [];
  tempArray.push({
    name: inputData.mainCategoryName,
    state: false
  })
  setMainCategoryNameList(tempArray)
},[inputData.mainCategoryName, mainCategoryNameList])

  const getSubCategoryList = async (mainCategoryId: string) => {
    const response = await HomeApi.getSubCategoryData(mainCategoryId);
    if (response.status === true) {
      setSubCategoryList(response.data)
      let tempArray: any = [];
      response.data.map((element: any) => {
        tempArray.push({
          name: element.content,
          state: false
        })
      });
      setSubCategoryNameList(tempArray)
    }
  }

  const getChildCategoryList = async (mainCategoryId: string, subCategoryId: string) => {
    const response = await HomeApi.getChildCategoryData(mainCategoryId, subCategoryId);
    if (response.status === true) {
      setChildCategoryList(response.data)
      let tempArray: any = [];
      response.data.map((element: any) => {
        tempArray.push({
          name: element.content,
          state: false
        })
      });
      setChildCategoryNameList(tempArray)
      getChildCategoryName(response.data)
    }
  }

  const getChildCategoryName = (dataList: any) => {
    childCategoryList.map((category: any) => {
      if (category.content === childCategoryID) {
        setChildCategoryID(category.elementid)
        setInputData((prevFields) => ({
          ...prevFields,
          childCategoryName: category.content,
        }))
      }
    })
  }

  const postBusinessDetails = async () => {
    const payload = {
      mainCategory : mainCategoryID,
      subCategory : subCategoryID,
      childCategory : childCategoryID,
      keyword: inputData.keyword,
      companyName : inputData.companyName,
      tradeName : inputData.tradingName,
      email : inputData.email,
      phone : inputData.phone,
      mobile : inputData.mobileNumber,
      fax_phone_number : inputData.faxNumber,
      address : inputData.streetAddress,
      businessdescribtion : inputData.businessDescription
    }

    const response = await HomeApi.postBusinessDetails(payload, getUserId())

    if (response.status = true) {
    }
  }

  const renderCategoryView = (categoryData: any, label: string, key: string) => {
    return (
      <Box sx={{ marginLeft: "10px", width: "80%", display: "flex", flexDirection: "row" }} >
        <Box className={labelMainContainer}>
          <Typography className={labelText}>
            {label}
            {/* {t("YC_CHILD_CATEGORY")} */}
          </Typography>
        </Box>
        <Box className={rightMainContainer}>
          {categoryData.map((category: any, index: any) => {
            return (
              // <Typography sx={{ ml: 1, mt: 1, mb: 1, p: 1, display: 'inline-block', textAlign: "start", border: "1px solid #CCD0D4",
              //    borderColor: GREY,
              //    "&:hover" : {background: BLACK, color: WHITE}}}
              //  >
              //   {category}
              // </Typography>
              <Button sx={{
                marginLeft: "20px", //{xs: "-10%", sm: "-1%", lg: "-26%", xl: "-45%"},
               border: "1px solid #CCD0D4",
               height: "40px",
               background:  category.state ? BLACK : WHITE,
               color: category.state ? WHITE : BLACK, 
               marginTop: 1,
               marginBottom: 1,
               fontWeight: 400, 
               fontSize: {xs: 8, sm: 9, lg: 12, xl: 12},
               '&:hover': {
                   backgroundColor: BLACK,
                   color: WHITE
               }
           }}
           onClick={() => {key === "subCategory" ? handleClickOfSubCategory(index, category.name) : handleClickOfChildCategory(index, category.name)}}>
            {category.name}
            </Button>
            )
          })}
        </Box>
      </Box>
    )
  }

  const renderKeywordView = () => {
    return (
      <Box sx={{ marginLeft: "10px", width: "80%", display: "flex", flexDirection: "row" }} >
        <Box sx={{ ml: 2.5, width: "30%", display: "flex", flexDirection: "column" }} >
          <Typography className={labelText}>
            {t("YC_KEYWORDS")}
          </Typography>
          <Typography className={labelText}>
            {t("YC_ENTER_KEYWORDS")}
          </Typography>
        </Box>
        {/* <Box sx={{display :"flex", border: "1px solid #0F5CD0", mt: 1.5, mb:1, borderColor: BLUE, boxShadow: 2, width: "70%", justifyContent: "flex-start", flexWrap: "wrap" }}> */}
        <Box className={rightMainContainerYellowBorder}>
          {keywordsData.map((keyword: any, index: any) => {
            return (
              // <Typography sx={{ ml: 1, mt: 1, mb: 1, p: 1, display: 'inline-block', textAlign: "start", border: "1px solid #CCD0D4", borderColor: GREY }}>
              //   {category}
              // </Typography>
              <Button sx={{
                marginLeft: "20px", //{xs: "-10%", sm: "-1%", lg: "-26%", xl: "-45%"},
               border: "1px solid #CCD0D4",
               height: "40px", 
               background:  keyword.state ? BLACK : WHITE,
               color: keyword.state ? WHITE : BLACK,
               marginTop: 1,
               marginBottom: 1,
               fontWeight: 400, 
               fontSize: {xs: 8, sm: 9, lg: 12, xl: 12},
               '&:hover': {
                   backgroundColor: BLACK,
                   color: WHITE
               }
           }}
           onClick={() => {handleClickOfKeyword(index, keyword.keywordName)}}>
            {keyword.keywordName}
            </Button>
            )
          })}
        </Box>
      </Box>
    )
  }

  const textFieldView = (key: string, value: string) => {
    return (
      <>
      <TextField style={{
        width: "100%",
      }} label={""} variant="outlined"
        size="small"
        value={value}
        onChange={(text) => {
          const trimStr = text.target.value.trimStart();
          if (key === "companyName") {
            setInputData((prevFields) => ({
              ...prevFields,
              companyName: trimStr,
            }));
          } else if (key === "tradingName") {
            setInputData((prevFields) => ({
              ...prevFields,
              tradingName: trimStr,
            }));
          } else if (key === "email") {
            const validEmail = validateEmail(trimStr);
            setIsValidEmail({ value: validEmail, error: !validEmail ? t("YC_EMAIL_VALID") : "" })
            setInputData((prevFields) => ({
              ...prevFields,
              email: trimStr,
            }));
          } else if (key === "mobileNumber") {
            const mob = text.target.value.replace(/(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4");
            const isValidNumber = validateMobilePhone(mob);
            setIsValidMobileNumber({value:isValidNumber, error: !isValidNumber ? t("YC_PHONE_VALID") : ""})
            setInputData((prevFields) => ({
              ...prevFields,
              mobileNumber: trimStr,
            }));
          } else if (key === "phone") {
            setInputData((prevFields) => ({
              ...prevFields,
              phone: trimStr,
            }));
          } else if (key === "faxNumber") {
            setInputData((prevFields) => ({
              ...prevFields,
              faxNumber: trimStr,
            }));
          } else if (key === "streetAddress") {
            setInputData((prevFields) => ({
              ...prevFields,
              streetAddress: trimStr,
            }));
          } else if (key === "businessDescription") {
            setInputData((prevFields) => ({
              ...prevFields,
              businessDescription: trimStr,
            }));
          }
        }} 
        />
        {(key === "email" && inputData.email !== "" && !isValidEmail.value) && (<div style={{ color: "red" }}> {t("YC_EMAIL_VALID")} </div>)}
        {(key === "mobileNumber" && inputData.mobileNumber !== "" && !isValidMobileNumber.value) && (<div style={{ color: "red" }}> {t("YC_PHONE_VALID")} </div>)}
        </>
    )
  }

  const renderTextFieldAndLabelViewInRow = (label: string, key: string, key2: string,
    value: string, value2: string,
    isCompalsory: boolean, isCompalsory2: boolean) => {
    return (
      <Box sx={{ mt: 2, mb: 2, marginLeft: "10px", width: "80%", display: "flex", flexDirection: "row" }} >
        <Box className={labelMainContainer}>
          <Typography className={labelText}>
            {label}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", width: "70%" }}>
          <Box sx={{ width: "50%", border: "1px solid #0F5CD0", borderColor: isCompalsory ? BLUE : YELLOW }}>
            {textFieldView(key, value)}
          </Box>
          <Box sx={{ width: 10 }} />
          <Box sx={{ width: "50%", border: "1px solid #0F5CD0", borderColor: isCompalsory ? BLUE : YELLOW }}>
            {textFieldView(key2, value2)}
          </Box>
        </Box>
      </Box>
    )
  }

  const renderTextFieldAndLabelView = (label: string, key: string, value: string, isCompalsory: boolean) => {
    return (
      <Box sx={{ marginLeft: "10px", width: "80%", display: "flex", flexDirection: "row" }} >
        <Box className={labelMainContainer}>
          <Typography className={labelText}>
            {label}
          </Typography>
        </Box>
        <Box className={isCompalsory ? rightMainContainer : rightMainContainerYellowBorder}>
          {textFieldView(key, value)}
        </Box>
      </Box>
    )
  }

  const renderLocationResetView = () => {
    return (
      <Box sx={{ marginLeft: "10px", width: "80%", display: "flex", flexDirection: "row" }} >
        <Box sx={{ ml: 2.5, width: "30%", display: "flex", flexDirection: "column" }} >
          <Typography className={labelText}>
            {t("YC_LOCATION")}
          </Typography>
          <Typography className={labelText}>
            {t("YC_RESET")}
          </Typography>
        </Box>
        {/* <Box sx={{display :"flex", border: "1px solid #0F5CD0", mt: 1.5, mb:1, borderColor: BLUE, boxShadow: 2, width: "70%", justifyContent: "flex-start", flexWrap: "wrap" }}> */}
        <Box>
          <Typography sx={{ ml: 1, mt: 1, mb: 1, textAlign: "start", border: "0px solid #CCD0D4", borderColor: "transparent" }}>
            {`${"Mauritius Island" + " " + ">" + " " + "Plaines Wilhems District" + " " + ">" + " " + "Quatre Bornes"}`}
          </Typography>
        </Box>
      </Box>
    )
  }

  const underlineView = () => {
    return (
      <hr
        style={{
          color: BLACK,
          backgroundColor: BLACK,
          width: "95%",
          marginLeft: "2.5%",
          marginTop: 10,
          height: 2
        }}
      />
    )
  }
  return (
    <Box>
      <Box sx={{
        minHeight: "50px",
        borderRadius: "0px",
        backgroundColor: "#000000",
        marginTop: "16px",
      }}
      >
        <Typography sx={{ ml: 2.5 }} className={appText}>
          {t("YC_CONTENT_MANAGEMENT")}
        </Typography>
      </Box>
      <Box sx={{
        borderRadius: "0px",
        marginTop: "15px",
        textAlign: "start",
      }}
      >
        <Typography sx={{ ml: 2.5 }}>
          {t("YC_CONTENT_MANAGEMENT_TEXT")}
        </Typography>
      </Box>
      <hr
        style={{
          color: BLACK,
          backgroundColor: BLACK,
          width: "100%",
          marginTop: 10,
          // marginLeft: "2.5%",
          height: 2
        }}
      />
      <Typography sx={{ ml: 2.5, fontStyle: SUBHEADERBOLD2, textAlign: "start" }}>
        {t("YC_BUSINESS_DETAILS_GENERAL")}
      </Typography>
      <Box sx={{ border: "1px solid #CCD0D4", mt: 1.5, borderColor: GREY, boxShadow: 2 }}>
        {renderCategoryView(mainCategoryNameList, t("YC_MAIN_CATEGORY"), "mainCategory")}
        {renderCategoryView(subCategoryNameList, t("YC_SUB_CATEGORY"), "subCategory")}
        {renderCategoryView(childCategoryNameList, t("YC_CHILD_CATEGORY"), "childCategory")}
        {renderKeywordView()}
        {renderTextFieldAndLabelView(t("YC_COMPANY_NAME"), "companyName", inputData.companyName, true)}
        {renderTextFieldAndLabelView(t("YC_TRADING_NAME"), "tradingName", inputData.tradingName, false)}
        {renderTextFieldAndLabelViewInRow(t("YC_CONTACT"), "email", "mobileNumber", inputData.email, inputData.mobileNumber, true, true)}
        {renderTextFieldAndLabelViewInRow("", "phone", "faxNumber", inputData.phone, inputData.faxNumber, false, false)}
        {renderLocationResetView()}
        {renderTextFieldAndLabelView(t("YC_STREET_ADDRESS"), "streetAddress", inputData.streetAddress, true)}
        {renderTextFieldAndLabelView(t("YC_BUSINESS_DESCRIPTION"), "businessDescription", inputData.businessDescription, true)}
        <Box sx={{ ml: 2, mb: 2, display: "flex", width: "95%", justifyContent: "flex-end" }}>
          <CustomButton
            disabled={isSaveButtonDisable}
            placeHolder={t("YC_SAVE")}
            textTransform="none"
            background={isSaveButtonDisable ? GREY : BLUE}
            height="40px"
            width={100}
            borderRadius="7.2px"
            buttonStyle={{
              HEADER2
            }}
            onClick={() => {postBusinessDetails()}}
          />
        </Box>
        {underlineView()}
        <Typography sx={{ ml: 4, fontStyle: SUBHEADERBOLD2, textAlign: "start" }}>
          {t("YC_BUSINESS_DETAILS_ADDITIONAL")}
        </Typography>
        {underlineView()}
        <Box sx={{ marginLeft: "5%", mb: 2, display: "flex", width: "90%", justifyContent: "flex-start", border: "1px solid #CCD0D4", borderColor: BLUE }}>
             <Typography sx={{ ml: 1, mt: 1, mb: 1, textAlign: "start", fontStyle: SUBHEADERBOLD2 }}>
                {t("YC_YELLOW_PAGES")}
             </Typography>
        </Box>
        <Box sx={{ marginLeft: "5%", mb: 2, display: "flex", width: "90%", justifyContent: "flex-start", border: "1px solid #CCD0D4", borderColor: BLUE }}>
             <Typography sx={{ ml: 1, mt: 1, mb: 1, textAlign: "start", fontStyle: SUBHEADERBOLD2 }}>
                {t("YC_YELLOW_MARKETS")}
             </Typography>
        </Box>
        <Box sx={{ ml: 2, mb: 2, display: "flex", width: "95%", justifyContent: "flex-end" }}>
          <CustomButton
            disabled={true}
            placeHolder={t("YC_SAVE")}
            textTransform="none"
            background={GREY}
            height="40px"
            width={100}
            borderRadius="7.2px"
            buttonStyle={{
              HEADER2
            }}
            onClick={() => { }}
          />
        </Box>
      </Box>
    </Box>
  );
}