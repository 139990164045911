import { Box, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "components/custom-button/custom-button";
import ImageUploadDisplay from "components/file-uploader/image-loaderDisplay";
import CustomSwitch from "components/switch-button/switch-button";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ErrorExclamation from "../../../assets/exclamation-mark.png";
import { BLACK, BLACK_2, BLUE, GREY, GREY_3, GREY_4, WHITE } from "utils/colors";
import { HEADER2, SUBHEADER1, SUBHEADERBOLD2 } from "utils/fonts";
import HomeApi from "services/HomeAPi";
import Notification from "views/Notifications/Notification";

const EditUserDialogStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        borderRadius: "7.2px",
        marginTop: "10px",
        marginLeft: "20px",
        marginBottom: "10px",
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "90%",
        overflowY: 'auto',
        OverflowX: "none",
        maxWidth: 700,
        minWidth: 450,
        background: "white",
    },
    dividerStyleVerticle: {
        borderColor: GREY,
        borderWidth: 0.5,
        opacity: 1,
    },
    otpViewTitle: {
        fontSize: 16,
        fontWeight: "500",
        color: "#1F2328",
        lineHeight: "24px",
    },
    titleText: {
        display: "flex",
        marginTop: 20,
        marginBottom: 20,
        justifyContent: "center",
        alignItems: "center",
    },
    subTitle: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "28px",
        textAlign: "left",
        color: BLACK,
    }

}))
function BrandSettings(props: any) {
    const { t } = useTranslation()
    const [subItemDataId, setSubItemDataId] = useState<any>([])
    const [subItemStatusDataId, setSubItemStatusDataId] = useState<any>([])
    const [logoPath, setLogoPath] = useState("")
    const [inputData, setInputData] = useState({
        logo: "",
        slogan: ""
    });
    const [mainGraphicsList, setMainGraphicsList] = React.useState([{
        name: t("YC_GRAPHICS-GENERAL"),
        subData: [
            {
                id: 1,
                name: t("YC_APPLY_BUSINESS_LOGO"),
                isON: false
            },
            {
                id: 2,
                name: t("YC_APPLY_BUSINESS_COLOUR"),
                isON: false
            }
        ]
    }]);

    const getUserId = () => {
        let userId
        const loginUserDetails = localStorage.getItem("user_Details")
        if (loginUserDetails) {
            const details = JSON.parse(loginUserDetails)
            userId = details.id
        }
        return userId
    }

    const postBrandSettingsData = async () => {
        let formData = new FormData();    //formdata object
        formData.append('logo', inputData.logo[0] );   //append the values with key, value pair
        formData.append('preference_id', subItemDataId);
        formData.append('preference_status', subItemStatusDataId)
        formData.append('business_slogan', inputData.slogan)
        const config = {
            headers: { 'content-type': 'multipart/form-data' }
        }

        const response = await HomeApi.postBrandSettingsData(formData, config, getUserId());
        if (response.status === true) {
            Notification.notifySuccess(response.message)
        } else {
            Notification.notifyError(response.message)
        }
    }

    // Function to toggle the isON value
    const toggleSpecificationItem = (specificationIndex: any, itemId: any) => {
        setMainGraphicsList(prevList =>
            prevList.map((specification, index) => {
                if (index === specificationIndex) {
                    return {
                        ...specification,
                        subData: specification.subData.map(item =>
                            item.id === itemId ? { ...item, isON: !item.isON } : item
                        )
                    };
                }
                return specification;
            })
        );
    };

    const getItemIdAndSubDataItemStatus = () => {
        let tempSubItemDataId: any = [];
        let tempSubItemStatusDataId: any = [];
        mainGraphicsList.map((data: any) => {
            data.subData.map((subData: any) => {
                tempSubItemDataId.push(subData.id)
                tempSubItemStatusDataId.push(subData.isON === true ? 1 : 0)
            });
        });
            setSubItemDataId(tempSubItemDataId)
            setSubItemStatusDataId(tempSubItemStatusDataId)
    }


    useEffect(() => {
        getItemIdAndSubDataItemStatus()
    }, [mainGraphicsList])

    const setLogoData = (data: any) => {
        setInputData((prevFields) => ({
            ...prevFields,
            logo: data,
        }))
    }

    const renderGraphicFieldView = () => {
        return(
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                // height: "30px",
                width: "90%",
                marginLeft: "5%",
                background: GREY_4
            }}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                // marginTop: "2px",
            }}>
                <Box sx={{ width: "100%", height: "5px", background: WHITE }} />
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    height: "30px",
                    width: "100%",
                    background: BLACK_2
                }}>
                    <Typography sx={{ width: "79%", marginLeft: "10px", marginTop: "4px", fontStyle: SUBHEADER1, textAlign: "start", color: WHITE }}>
                        {t("YC_BRANDING_GRAPHICS")}
                    </Typography>
                    <Box sx={{ height: "100%", width: "0.2%", background: WHITE }} />
                    <Typography sx={{ width: "20%", marginTop: "2px", textAlign: "center" }}></Typography>
                </Box>
                <Box sx={{ width: "100%", height: "5px", background: WHITE }} />
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    height: "100px",
                    width: "100%",
                    border: "1px solid #CCD0D4",
                    background : WHITE
                }}>
                    <Typography sx={{ width: "30%", marginLeft: "10px", marginTop: { xs: "10px", sm: "10px", lg: "30px", xl: "30px" }, fontStyle: SUBHEADERBOLD2, textAlign: "start" }}>
                        {t("YC_UPDATE_BUSINESS_LOGO")}
                    </Typography>
                    <Box sx={{
                        width: { xs: "100%", sm: "80%", lg: "50%", xl: "50%" },
                        height: '60px',
                        display: "flex", //{ xs: "block", sm: "block", lg: "flex", xl: "flex" },
                        flexDirection: "row",
                        borderWidth: 1,
                        borderStyle: 'solid',
                        borderRadius: 2,
                        marginTop: "20px",
                        mr: "50px"
                    }}
                    >
                        <Typography sx={{ width: '65%', textAlign: 'start', paddingTop: '4%', paddingLeft: 2, fontStyle: SUBHEADERBOLD2 }}>{t("UC_UPLOAD_LOGO")}</Typography>
                        <Box sx={{
                            width: { xs: "70%", sm: "70%", lg: "35%", xl: "35%" },
                            height: '60px',
                            borderStyle: 'solid',
                            borderWidth: '0.5px',
                            borderRadius: 2
                        }}
                        >
                            <ImageUploadDisplay
                                width='100%'
                                height='100%'
                                onDrop={setLogoData}
                                maxLength={12}
                                imageUrl={logoPath}
                                imageAdded={inputData.logo}
                                imageKey={"logo"}
                                isMarginLeftSet={props.isFromDashBoard ? true : false}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
            </Box>
        )
    }

    const renderBrandingmarketingView = () => {
        return(
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                width: "90%",
                marginLeft: "5%",
                background: GREY_3
            }}>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
            }}>
                <Box sx={{ width: "100%", height: "5px", background: WHITE }} />
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    height: "30px",
                    width: "100%",
                    background: BLACK_2
                }}>
                    <Typography sx={{ width: "79%", marginLeft: "10px", marginTop: "4px", fontStyle: SUBHEADER1, textAlign: "start", color: WHITE }}>
                        {t("YC_BRANDING_MARKETING")}
                    </Typography>
                    <Box sx={{ height: "100%", width: "0.2%", background: WHITE }} />
                    <Typography sx={{ width: "20%", marginTop: "2px", textAlign: "center" }}></Typography>
                </Box>
                <Box sx={{ width: "100%", height: "5px", background: WHITE }} />
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    height: "100px",
                    width: "100%",
                    background: GREY_3,
                    border: "1px solid #CCD0D4",
                }}>
                    <Typography sx={{ width: "30%", height: "40px", marginLeft: "10px", marginTop: "10px", fontStyle: SUBHEADERBOLD2, textAlign: "start" }}>
                        {t("YC_ADD_BUSINESS_SLOGAN")}
                    </Typography>
                    <TextField
                        sx={{ boxShadow: 4,
                             width: "50%", 
                             height: "40px",
                             marginTop: "10px",
                            background: WHITE }}
                        id="mobile" label={t("YC_SLOGAN")} variant="outlined" size="small"
                        value={inputData.slogan}
                        onChange={(text) => {
                            const trimStr = text.target.value.trimStart();
                            setInputData((prevFields) => ({
                                ...prevFields,
                                slogan: trimStr,
                            }));
                        }} />
                        <Box sx={{width: "20%", justifyContent: "center", mt: "10px"}}>
                            <img
                                src={ErrorExclamation}
                                height={35}
                                alt={"icon"}
                            />
                        </Box>
                </Box>
            </Box>
            </Box>
        )
    }

    const renderButtonView = () => {
        return (
            <Box sx={{ mt: "20px", mb: "20px", width: "80%", marginLeft: {xs: "25%", sm : "25%", lg: "10%", xl: "10%"}, display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                <CustomButton
                    placeHolder={t("YC_UPDATED")}
                    textTransform="none"
                    background={BLUE}
                    height="40px"
                    width={"10%"}
                    borderRadius="7.2px"
                    buttonStyle={{
                        HEADER2,
                        minWidth: "200px",
                    }}
                    onClick={() => {postBrandSettingsData()}}
                />
                <CustomButton
                    placeHolder={t("YC_CANCEL")}
                    textTransform="none"
                    background={GREY}
                    height="40px"
                    width={"10%"}
                    marginLeft="10px"
                    marginRight="50px"
                    borderRadius="7.2px"
                    buttonStyle={{
                        HEADER2,
                        minWidth: "200px",
                    }}
                    onClick={() => { }}
                />
            </Box>
        )
    }

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: { xs: "100%", sm: "100%", lg: "80%", xl: "80%" },
                    border: 2,
                    borderColor: GREY,
                    boxShadow: 2,
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginLeft: { xs: "0%", sm: "0%", lg: "10%", xl: "10%" },
                }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    height: "30px",
                    width: "90%",
                    marginLeft: "5%",
                    border: "1px solid #CCD0D4",
                    background: GREY_3,
                    marginTop: "20px"
                }}>
                    <Typography sx={{ width: "79%", marginLeft: "10px", marginTop: "2px", fontStyle: SUBHEADERBOLD2, textAlign: "start" }}>{t("YC_BRAND_SETTINGS")}</Typography>
                    <Box sx={{ height: "100%", width: "0.2%", background: WHITE }} />
                    <Typography sx={{ width: "20%", marginTop: "2px", textAlign: "center" }}>OFF/ON</Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    // height: "30px",
                    width: "90%",
                    marginLeft: "5%",
                    background: GREY_4
                }}>
                    {mainGraphicsList.map((data: any, specIndex: any) => {
                        return (
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                // marginTop: "2px",
                            }}>
                                <Box sx={{ width: "100%", height: "5px", background: WHITE }} />
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    height: "30px",
                                    width: "100%",
                                    background: BLACK_2
                                }}>
                                    <Typography sx={{ width: "79%", marginLeft: "10px", marginTop: "4px", fontStyle: SUBHEADER1, textAlign: "start", color: WHITE }}>
                                        {data.name}
                                    </Typography>
                                    <Box sx={{ height: "100%", width: "0.2%", background: WHITE }} />
                                    <Typography sx={{ width: "20%", marginTop: "2px", textAlign: "center" }}></Typography>
                                </Box>
                                {data.subData.map((subData: any, index: any) => {
                                    return (
                                        <Box sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}>
                                            <Box sx={{ width: "100%", height: "5px", background: WHITE }} />
                                            <Box sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                height: "30px",
                                                width: "100%",
                                                border: "1px solid #CCD0D4",
                                                background: index % 2 === 0 ? GREY_3 : WHITE
                                            }}>
                                                <Typography sx={{ width: "79%", marginLeft: "10px", marginTop: "2px", fontStyle: SUBHEADERBOLD2, textAlign: "start" }}>
                                                    {subData.name}
                                                </Typography>
                                                <Box sx={{ height: "100%", width: "0.2%", background: index % 2 === 0 ? WHITE : GREY_3 }} />
                                                <Box sx={{ width: "20%", marginTop: 0.2 }}>
                                                    <CustomSwitch isOn={subData.isON} handleToggle={() => toggleSpecificationItem(specIndex, subData.id)} key={subData.specificationitem} />
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                })}
                            </Box>
                        )
                    })}
                </Box>
                {renderGraphicFieldView()}
                {renderBrandingmarketingView()}
                <Box sx={{height: "200px"}}/>
            </Box>
            
            {renderButtonView()}
        </Box>
    );
}

export default BrandSettings;
