import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialLoderState = {isLoaderShow: false}
const loaderSlice = createSlice({
  name: "loading",
  initialState: initialLoderState,
  reducers: {
    setLoader(state, action: PayloadAction<boolean>) {
      state.isLoaderShow = action.payload;
    },   
  },
});

export default loaderSlice;