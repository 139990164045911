import { Avatar, Box, Divider, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, Stack, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckBox from "components/check-box/check-box";
import CustomButton from "components/custom-button/custom-button";
import FileUpload from "components/file-uploader/image-uploader";
import PasswordField from "components/password-field/password-field";
import CustomSwitch from "components/switch-button/switch-button";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import HomeApi from "services/HomeAPi";
import { BLACK, BLACK_2, BLACK_3, BLUE, DARKGREY, DARK_GRAY, GREY, GREY_2, GREY_3, GREY_4, RED, WHITE, YELLOW } from "utils/colors";
import { HEADER2, SUBHEADER1, SUBHEADER2, SUBHEADERBOLD, SUBHEADERBOLD2 } from "utils/fonts";
import { validateEmail } from "utils/helper";
import Notification from "views/Notifications/Notification";

const EditUserDialogStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        borderRadius: "7.2px",
        marginTop: "10px",
        marginLeft: "20px",
        marginBottom: "10px",
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "90%",
        overflowY: 'auto',
        OverflowX: "none",
        maxWidth: 700,
        minWidth: 450,
        background: "white",
    },
    dividerStyleVerticle: {
        borderColor: GREY,
        borderWidth: 0.5,
        opacity: 1,
    },
    otpViewTitle: {
        fontSize: 16,
        fontWeight: "500",
        color: "#1F2328",
        lineHeight: "24px",
    },
    titleText: {
        display: "flex",
        marginTop: 20,
        marginBottom: 20,
        justifyContent: "center",
        alignItems: "center",
    },
    subTitle: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "28px",
        textAlign: "left",
        color: BLACK,
    }

}))
function Preferences(props: any) {
    const { t } = useTranslation()
    const [subItemDataId, setSubItemDataId] = useState([])
    const [subItemStatusDataId, setSubItemStatusDataId] = useState([])
    const [specificationList, setSpecificationList] = React.useState([{
        specificationName: "",
        subData: [
            {
                id: 0,
                specificationitem: "",
                preference_status: 0
            }
        ]
    }])
    const [specificationList2, setSpecificationList2] = React.useState([{
        specificationName: "Event Notifications",
        subData: [
            {
                "id": 1,
                "specificationitem": "Upcoming events in Mauritius",
                isON: true
            },
            {
                "id": 2,
                "specificationitem": "YP events and competitions for businesses",
                isON: true
            },
            {
                "id": 4,
                "specificationitem": "test1",
                isON: true
            }
        ]
    },
    {
        specificationName: "Help Your Local Business",
        subData: [
            {
                "id": 1,
                "specificationitem": "Upcoming events in Mauritius BBB",
                isON: true
            },
            {
                "id": 2,
                "specificationitem": "YP events and competitions for businesses BBB",
                isON: true
            },
            {
                "id": 4,
                "specificationitem": "test1 BBB",
                isON: true
            }
        ]
    },
    {
        specificationName: "Special Offer Notifications",
        subData: [
            {
                "id": 1,
                "specificationitem": "Upcoming events in Mauritius CCC",
                isON: true
            },
            {
                "id": 2,
                "specificationitem": "YP events and competitions for businesses CCC",
                isON: true
            },
            {
                "id": 4,
                "specificationitem": "test1 CCC",
                isON: true
            }
        ]
    },
    {
        specificationName: "Support Your Business Community",
        subData: [
            {
                "id": 1,
                "specificationitem": "Upcoming events in Mauritius DDD",
                isON: true
            },
            {
                "id": 2,
                "specificationitem": "YP events and competitions for businesses DDD",
                isON: false
            },
            {
                "id": 4,
                "specificationitem": "test1 DDD",
                isON: false
            }
        ]
    }]);
    const [isOn, setIsOn] = useState(false);

    const [isHover, setIsHover] = React.useState(false);

    const handleMouseEnter = () => {
        setIsHover(true);
    };

    const handleMouseLeave = () => {
        setIsHover(false);
    };

    const getUserId = () => {
        let userId
        const loginUserDetails = localStorage.getItem("user_Details")
        if (loginUserDetails) {
            const details = JSON.parse(loginUserDetails)
            userId = details.id
        }
        return userId
    }

    const getSpecificationData = async () => {
        const response = await HomeApi.getSpecificationData(getUserId());
        if (response.status === true) {
            setSpecificationList(response.data)
            // let tempArray: any = [];
            // response.data.map((role: any) => {
            //     tempArray.push(role.doc)
            // });
            // setRoleNameList(tempArray)
        }
    }

    useEffect(() => {
        getSpecificationData()
    }, [])

     // Function to toggle the isON value
  const toggleSpecificationItem = (specificationIndex: any, itemId: any) => {
    setSpecificationList(prevList =>
      prevList.map((specification, index) => {
        if (index === specificationIndex) {
          return {
            ...specification,
            subData: specification.subData.map(item => 
              item.id === itemId ? { ...item, preference_status: item.preference_status === 0 ? 1 : 0 } : item
            )
          };
        }
        return specification;
      })
    );
  };

  const getItemIdAndSubDataItemStatus = () => {
    let tempSubItemDataId: any = [];
    let tempSubItemStatusDataId: any = [];
    specificationList.map((data: any) => {
        data.subData.map((subData: any) => {
            tempSubItemDataId.push(subData.id)
            tempSubItemStatusDataId.push(subData.preference_status === 1 ? 1 : 0)
        });
    });
        setSubItemDataId(tempSubItemDataId)
        setSubItemStatusDataId(tempSubItemStatusDataId)
}


useEffect(() => {
    getItemIdAndSubDataItemStatus()
}, [specificationList])
 
const postPreferenceData = async () => {
    const payload = {
        preference_id: subItemDataId,
        preference_status: subItemStatusDataId
    }
    const response = await HomeApi.postPreferenceData(payload, getUserId())
    if (response.status === true) {
        Notification.notifySuccess(response.message)
    } else {
        Notification.notifyError(response.message)
    }
}
    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: {xs: "100%", sm: "100%", lg: "80%", xl: "80%"},
                    border: 2,
                    borderColor: GREY,
                    boxShadow: 2,
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginLeft: {xs: "0%", sm: "0%", lg: "10%", xl: "10%"},
                }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    height: "30px",
                    width: "90%",
                    marginLeft: "5%",
                    background: GREY_4,
                    marginTop: "20px"
                }}>
                    <Typography sx={{ width: "79%", marginLeft: "10px", marginTop: "2px", fontStyle: SUBHEADERBOLD2, textAlign: "start" }}>{t("YC_SPECIFICATIONS")}</Typography>
                    <Box sx={{ height: "100%", width: "0.2%", background: WHITE }} />
                    <Typography sx={{ width: "20%", marginTop: "2px", textAlign: "center" }}>OFF/ON</Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    // height: "30px",
                    width: "90%",
                    marginLeft: "5%",
                    background: GREY_4
                }}>
                    {specificationList.length > 0 && specificationList.map((specificationData: any, specIndex: any) => {
                        return (
                            <Box sx={{
                                display: "flex",
                                flexDirection: "column",
                                // marginTop: "2px",
                            }} key={specificationData.specificationName}>
                                <Box sx={{ width: "100%", height: "5px", background: WHITE }} />
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    height: "30px",
                                    width: "100%",
                                    background: BLACK_2
                                }}>
                                    <Typography sx={{ width: "79%", marginLeft: "10px", marginTop: "4px", fontStyle: SUBHEADER1, textAlign: "start", color: WHITE }}>
                                        {specificationData.specificationName}
                                    </Typography>
                                    <Box sx={{ height: "100%", width: "0.2%", background: WHITE }} />
                                    <Typography sx={{ width: "20%", marginTop: "2px", textAlign: "center" }}></Typography>
                                </Box>
                                {specificationData.subData.map((subData: any, index: any) => {
                                    return (
                                        <Box sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}>
                                            <Box sx={{ width: "100%", height: "5px", background: WHITE }} />
                                            <Box sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                height: "30px",
                                                width: "100%",
                                                border: "1px solid #CCD0D4",
                                                background: index % 2 === 0 ? GREY_3 : WHITE
                                            }}>
                                                <Typography sx={{ width: "79%", marginLeft: "10px", marginTop: "2px", fontStyle: SUBHEADERBOLD2, textAlign: "start" }}>
                                                    {subData.specificationitem}
                                                </Typography>
                                                <Box sx={{ height: "100%", width: "0.2%", background: index % 2 === 0 ? WHITE : GREY_3 }} />
                                                <Box sx={{ width: "20%", marginTop: 0.2 }}>
                                                    <CustomSwitch isOn={subData.preference_status === 1 ? true : false} handleToggle={() => toggleSpecificationItem(specIndex, subData.id)} key={subData.specificationitem} />
                                                </Box>
                                            </Box>
                                        </Box>
                                    )
                                })}
                            </Box>
                        )
                    })}
                </Box>
                <Box sx={{height: "20px"}}/>
            </Box>
            <Box sx={{mt: "20px", mb: "20px", width: "80%", marginLeft: {xs: "25%", sm : "25%", lg: "10%", xl: "10%"}, display: "flex", flexDirection: "row", justifyContent: "flex-end"}}>
                   <CustomButton
                        placeHolder={t("YC_UPDATED")}
                        textTransform="none"
                        background={BLUE}
                        height="40px"
                        width={"10%"}
                        borderRadius="7.2px"
                        buttonStyle={{
                            HEADER2,
                            minWidth: "200px",
                        }}
                        onClick={() => {postPreferenceData()}}
                    />
                    <CustomButton
                        placeHolder={t("YC_CANCEL")}
                        textTransform="none"
                        background={GREY}
                        height="40px"
                        width={"10%"}
                        marginLeft="10px"
                        marginRight="50px"
                        borderRadius="7.2px"
                        buttonStyle={{
                            HEADER2,
                            minWidth: "200px",
                        }}
                        onClick={() => {}}
                    />
                   </Box>
        </Box>
    );
}

export default Preferences;
