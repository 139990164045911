import { Avatar, Box, FormControl, InputLabel, OutlinedInput, SelectChangeEvent, Stack, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckBox from "components/check-box/check-box";
import CustomButton from "components/custom-button/custom-button";
import FileUpload from "components/file-uploader/image-uploader";
import React from "react";
import { useTranslation } from "react-i18next";
import { BLACK, DARKGREY, WHITE } from "utils/colors";
import { SUBHEADER1, SUBHEADERBOLD } from "utils/fonts";

const EditUserDialogStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        borderRadius: "7.2px",
        marginTop: "10px",
        marginLeft: "20px",
        marginBottom: "10px",
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "50%",
        overflowY: 'auto',
        OverflowX: "none",
        maxWidth: 700,
        minWidth: 450,
        background: "white",
    }

}))
function NewFileUploadModelView(props: any) {
    const { boxView } = EditUserDialogStyle();

    const { t } = useTranslation()

    const handleChange = (event: SelectChangeEvent) => {
        // setControllerOfAccount(event.target.value);
    }

    const uploadTCFile = (data: any) => {
        props.setDocumentUpload(data)
        // props.setIsShowModal(false)
    }

    // const renderIcon = (iconName: any) => {
    //     return (
    //         <img
    //             src={iconName}
    //             height={iconName === ErrorExclamation ? 35 : 22}
    //             alt={"icon"}
    //         />
    //     );
    // };

    const dummyView = () => {
        return <Box sx={{ display: "flex", minWidth: 80, height: 18 }} />;
    };

    const textFieldView = (key: string, label: string = "", value: string) => {
        return (
            <Box sx={{
                width: "80%",
                marginLeft: "10%",
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.07)",
            }}>
                <FormControl
                    sx={{
                        width: "100%",
                        boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.07)",
                        background: WHITE,
                    }}
                    variant="outlined"
                >
                    <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
                    <OutlinedInput
                        // endAdornment={
                        //     value?.length === 0 ? (<InputAdornment position="end">
                        //         {renderIcon(ErrorExclamation)}
                        //     </InputAdornment>) :
                        //         <InputAdornment position="end">
                        //             {renderIcon(SuccessIcon)}
                        //         </InputAdornment>
                        // }
                        label={label}
                        value={value}
                        onChange={(text) => {
                            const trimStr = text.target.value.trimStart();
                            if (key === "document_name") {
                                props.setDocumentName(trimStr);
                            }
                        }}
                    />
                </FormControl>
            </Box>
        );
    };
    return (
        <Box className={boxView}>
            <Typography style={{ fontStyle: "normal", fontWeight: 'bold', fontSize: 17, marginLeft: "30px", marginBottom: "3%", marginTop: "7%" }} gutterBottom>{t("YC_UPLOAD_NEW_FILE")}</Typography>
            {dummyView()}
            {textFieldView(
                "document_name",
                t("YC_DOCUMENT_NAME"),
                props.documentName
            )}
            {dummyView()}
            <Box sx={{
                width: "80%",
                marginLeft: "10%",
                display: "flex",
                flexDirection: "row",
                borderWidth: 10,
                borderStyle: 'solid',
                borderRadius: 2,
                boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
                border: 0.1,
                background: "#F9F9FA"
            }}
            >
                <InputLabel sx={{
                    width: "40%", //{ xs: "40%", sm: "80%", lg: "40%", xl: "40%" },
                    textAlign: 'start', 
                    paddingTop: '4%', 
                    paddingLeft:"2%", 
                    fontWeight: "700",
                    fontSize: 16, color: BLACK
                }}>{t("YC_UPLOAD_FILE")}</InputLabel>
                <Box sx={{
                    width: "60%", //{ xs: "60%", sm: "25%", lg: "60%", xl: "60%" },
                    height: '60px',
                    borderStyle: 'solid',
                    borderWidth: '0.1px',
                    borderRadius: 1,
                    marginLeft: { xs: 13.5, sm: '40%', lg: 10, xl: 10 }
                }}
                >
                    <FileUpload
                        width='100%'
                        height='100%'
                        onDrop={uploadTCFile}
                        maxLength={35}
                    />
                </Box>
            </Box>
        </Box>
    );
}

export default NewFileUploadModelView;
