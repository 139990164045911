import * as React from "react";
import { Box, Button, CssBaseline, Typography, } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { YC_FOOTER_COPYRIGHT, YC_GETSTART_FREE, YC_THANK, YC_THANK_SUPPORT_MSG, YC_YOU_READY_SETUP } from "utils/strings";
import HomepageFooter from "components/homepage-footer/homepage-footer";
import { BLACK, WHITE, YELLOW } from "utils/colors";
import DashboardPageHeader from "components/dashboard-header/dashboard-header";
import { navigateTo } from "utils/helper";
import SideNav from "components/reusable-sideNav/SideNav";


const UseStyles = makeStyles(() => ({
    boxView: {
        boxShadow: " 0px 11.67662px  9.35323px rgba(0, 0, 0, 0.15)",
        borderRadius: "8px",
        background: "#FFFFFF",
        paddingRight: "5px !important",
    },
    thanksTxt: {
        marginBottom: "35px",
        marginTop: "7%",
        fontSize: 24,
        fontWeight: 700,

    },
    suportTxt: {
        marginBottom: "5%",
    },
    readySetTxt: {
        color: "#CA1F1F",
        fontWeight: "700",
        marginBottom: '7%',
    },
    button: {
        fontWeight: 'bold',
        fontSize: 20,
        minHeight: 40,
        marginBottom: '5%',
        color: WHITE,
        textTransform: "capitalize",
        fontStyle: "normal",
        alignSelf: 'center',
        '&:hover': {
            color: BLACK,
            backgroundColor: YELLOW,
        },
    },
}));

const drawerWidth = 250;

interface Props {
    window?: () => Window;
}

export default function WellCome(props: Props) {
    const {
        suportTxt,
        boxView,
        thanksTxt,
        readySetTxt,
        button,
    } = UseStyles();

    return (
        <div style={{ height: "100%", flex: 1 }}>
            <Box
                sx={{
                    marginBottom: "40px",
                    height: "100%",
                    flex: 1,
                }}
            >
                <CssBaseline />
                <DashboardPageHeader />

                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <SideNav />
                    <Box sx={{ width: { sm: `calc(100% - ${drawerWidth}px)` } }} >
                        <Box
                            className="mx-3 ms-lg-4 me-lg-4"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                flexGrow: 1,
                                mt: 7,
                                ml: 5,
                            }}
                        >
                            <Box className={boxView + " me-lg-5 "}>
                                <Typography className={`mx-auto ${thanksTxt}`} >{YC_THANK}</Typography>
                                <Typography className={`mx-auto ${suportTxt}`} >{YC_THANK_SUPPORT_MSG}</Typography>
                                <Typography className={`mx-auto ${readySetTxt}`} >{YC_YOU_READY_SETUP}</Typography>
                                <Button variant="contained" className={button} onClick={() => { navigateTo('/dashboard') }}>{YC_GETSTART_FREE}</Button>
                            </Box>
                        </Box>
                    </Box>

                </Box>
            </Box>
            <HomepageFooter isLinkFooter={true} displayText={YC_FOOTER_COPYRIGHT} />
        </div>
    );
};