import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { BLACK, WHITE, YELLOW } from "utils/colors";
import { YC_TITLE } from "utils/strings";
import PreferencesImg from "../../assets/Preferences.png";
import AddApplications from "../../assets/Add_applications.png";
import DataMining from "../../assets/Data_mining.png";
import ContentManagement from "../../assets/Content_management.png";
import Accounting from "../../assets/Accounting.png";
import SocialMedia from "../../assets/Social_media.png";
import UploadFiles from "../../assets/Upload_files.png";
import Documents from "../../assets/Documents.png";
import Tutorials from "../../assets/Tutorials.png";
import YellowMailFill from "../../assets/yellow_mail_fill.png";
import BellPinFill from "../../assets/Bell_pin_fill.png";
import HomeDashboard from "../../assets/Home_dashboard.png";
import { Collapse, ListItemIcon, Modal } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import settingService from "../../assets/service-tool-icon.png";
import userIcon from "../../assets/User_fill.png";
import ServiceToolIcon from "../../assets/Service_Tool.png";
import UserProfile from "../../assets/User_fill.png";
import CustomButton from "components/custom-button/custom-button";
import { useTranslation } from "react-i18next";
import PopUpModal from "components/pop-up-view/popup-modal";
import HomeApi from "services/HomeAPi";

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: YELLOW,
    paddingRight: "60px",
    paddingLeft: "60px",
    "@media (max-width: 1100px)": {
      paddingLeft: 0,
      paddingRight: 0,
    },
    height: 40,
    width: "100%",
    boxShadow: "0px 2px 4px #fff",
    justifyContent: "center",
  },
  logo: {
    fontWeight: 500,
    fontStyle: "normal",
    fontSize: 16,
    lineHeight: "32px",
    color: BLACK,
    textAlign: "left",
    marginTop: 5,
    cursor: "pointer",
  },
  menuButton: {
    fontWeight: "bold",
    fontSize: 19,
    height: 29,
    marginRight: 20,
    color: BLACK,
    marginTop: 5,
    textTransform: "capitalize",
    cursor: "pointer",
    fontStyle: "normal",
    "@media (max-width: 950px)": {
      marginRight: 3,
    },
    "&:hover": {
      color: WHITE,
      backgroundColor: YELLOW,
    },
  },
  button: {
    fontWeight: "bold",
    fontSize: 19,
    height: 29,
    color: BLACK,
    marginTop: 5,
    textTransform: "capitalize",
    fontStyle: "normal",
    alignSelf: "center",
    marginLeft: 60,
    "&:hover": {
      color: WHITE,
      backgroundColor: YELLOW,
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  dividerStyle: {
    borderColor: "#F0F0F0",
    borderWidth: 0.5,
    opacity: 1,
    // height: 30,
    marginRight: 10,
    alignSelf: "center",
  },
  customizeToolbar: {
    minHeight: "40px !important",
    height: "40px !important",
  },
  cellView: {
    color: YELLOW,
    background: BLACK,
  },
  cellText: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "space-between",
  },
}));

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;
const navItems = ["Business Name"];

export default function DashboardPageHeader(props: any) {
  const {
    header,
    logo,
    dividerStyle,
    customizeToolbar,
    cellView,
    cellText,
  } = useStyles();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [userFullname, setUserFullname] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false)
  const { t } = useTranslation()
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  React.useEffect(() => {
    const loginUserDetails = localStorage.getItem("login_user_Details")
    if (loginUserDetails){
      const details = JSON.parse(loginUserDetails)
      setUserFullname("Hello" + " " + details.name+ " "+ details.lname)
    }
  })

  const togglePopup = () => {
    setIsOpen(!isOpen);
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography className={logo}>{YC_TITLE}</Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const renderIcon = (iconName: any, key: any = '') => {
    return (
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={() => {
          if (key === 'setting_tool') {
            props.handleAppBar()
          } else if (key === 'User_profile'){
            setIsDisplayLogoutOption(!isDisplayLogoutOption)
          } else {
            handleDrawerToggle()
          }
        }}
        sx={{ mr: 1 }}
      >
        <img
          src={iconName}
          height={30}
          alt={"icons"}
        />
      </IconButton>
    );
  };

  const [openDashboard, setOpenDashboard] = React.useState(false);
  const [openAppTools, setOpenAppTools] = React.useState(false);
  const [openFunctions, setOpenFunctions] = React.useState(false);
  const [openOthers, setOpenOthers] = React.useState(false);
  const [isDisplayLogoutOption, setIsDisplayLogoutOption] = React.useState(false)
  const [resourceCodeList, setResourceCodeList] = React.useState([])
  const [resourceDataList, setResourceDataList] = React.useState([])
  const [dashboardOptionList, setDashboardOptionList] = React.useState([
    { name: "Overview" },
    { name: "Customise" }
  ])
  const [appToolsOptionList, setAppToolsOptionList] = React.useState([])
  const [functionOptionList, setFunctionOptionList] = React.useState([])

  const isResourseAvailable = (resourseCode: string) => {
    let result
    if (resourceCodeList !== null) {
      result = resourceCodeList.find((resourse: any) => resourse.includes(resourseCode));
    }
    return result === resourseCode ? true : false
  }

  React.useEffect(() => {
    getResourseManagementData()
  },[])

  const getResourseManagementData = async() => {
    const response = await HomeApi.getResourseManagementData("sidebar")
    if (response.status === true) {
      setResourceDataList(response.data)
      createAppToolsOptionArray(response.data)
      createFunctionOptionArray(response.data)
      let tempArray: any = [];
      response.data.map((resourse: any) => {
        tempArray.push(resourse.code)
      });
      setResourceCodeList(tempArray)
    }
  }

  const createAppToolsOptionArray = (array: any) => {
    let tempArray: any = []
    array.map((resourse: any) => {
      if (resourse.code === "app_directory") {
        tempArray.push({ name: "Directory", icon: null })
      }       
    });
    setAppToolsOptionList(tempArray)
  }

  const createFunctionOptionArray = (array: any) => {
    let tempArray: any = []
    array.map((resourse: any) => {
      if (resourse.code === "fun_application") {
        tempArray.push({ name: "Application"})
      } else if (resourse.code === "fun_notification") {
        tempArray.push({ name: "Notifications" })
      } else if (resourse.code === "fun_user_access") {
        tempArray.push({ name: "User Access" })
      } else if (resourse.code === "fun_transaction") {
        tempArray.push({ name: "Transactions" })
      } else if (resourse.code === "fun_task_list") {
        tempArray.push({ name: "Tasks List" })
      } else if (resourse.code === "fun_editorial") {
        tempArray.push({ name: "Editorial" })
      } else if (resourse.code === "fun_widgets") {
        tempArray.push({ name: "Widgets" })
      } else if (resourse.code === "fun_other") {
        tempArray.push({ name: "Others", icon: null })
      }
      
    });
    setFunctionOptionList(tempArray)
  }

  const renderItem = (itemName: string, icon: any = null) => {
    return (
      <Box>
        <ListItemButton
          className={cellView}
          sx={icon ? null : { ml: 5 }}
          onClick={() => {
            if (itemName === "Logout"){
              setIsOpen(true)
              // localStorage.clear();
              // navigate("/")
            }
          }}
        >
          {icon && (
            <ListItemIcon>
              <img src={icon} height={30} alt={"icons"} />
            </ListItemIcon>
          )}
          <Box className={cellText}>
            <Typography>{itemName}</Typography>
          </Box>
        </ListItemButton>
      </Box>
    );
  };


  const renderNestedItem = (
    itemName: string,
    isNested: boolean = false,
    nestedItems: any,
    isOpen: boolean = false,
    icon: any = null
  ) => {
    return (
      <Box>
        <ListItemButton
          className={cellView}
          sx={icon ? null : { ml: 5 }}
          onClick={() => {
            if (itemName === "DASHBOARD") {
              setOpenDashboard(!openDashboard);
              navigate("/incomplete-dashboard")
            }
            else if (itemName === "APPS-TOOLS") setOpenAppTools(!openAppTools);
            else if (itemName === "FUNCTIONS") setOpenFunctions(!openFunctions);
            else if (itemName === "openOthers") setOpenOthers(!openOthers);
          }}
        >
          {icon && (
            <ListItemIcon>
              <img src={icon} height={40} alt={"icons"} />
            </ListItemIcon>
          )}
          <Box className={cellText}>
            <Typography>{itemName}</Typography>
            {isNested ? (
              isOpen ? (
                <ExpandMore htmlColor="white" />
              ) : (
                <ChevronRightIcon htmlColor="white" />
              )
            ) : null}
          </Box>
        </ListItemButton>
        {isNested && nestedItems.length > 0 && isOpen && <Divider
            orientation="vertical"
            flexItem
            className={dividerStyle}
            sx={{ ml: 1 }}
          />}
        {isNested &&
          nestedItems.map((item: any) => (
            <Collapse key={`head_list_${item.name}`} in={isOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  className={cellView}
                  sx={{ pl: 4 }}
                  onClick={() => {
                    if(item.name === "Application") {
                      navigate("/application-dash")
                    } else if (item.name === "User Access") {
                      navigate("/user-access");
                    }
                  }}
                >
                  {item.icon && (
                    <ListItemIcon>
                      <img src={item.icon} height={25} alt={"icons"} />
                    </ListItemIcon>
                  )}
                  <ListItemText
                    sx={item.icon ? null : { ml: 7 }}
                    primary={item.name}
                  />
                  {item.showExpanIcon &&
                    <ChevronRightIcon htmlColor="white" />
                  }
                </ListItemButton>
              </List>
            </Collapse>
          ))}

        <Divider
          orientation="vertical"
          flexItem
          className={dividerStyle}
          sx={{ ml: 1 }}
        />
      </Box>
    );
  };

  const sidePanel = (
    <div style={{minHeight:"100%"}}>
      <List
        sx={{ width: "100%", minHeight:"100%", maxWidth: 360, bgcolor: BLACK , paddingBottom: "85px" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {renderNestedItem("DASHBOARD", true, [], openDashboard)}
        {renderNestedItem(
          "APPS-TOOLS",
          true,
          appToolsOptionList,
          // [
          //   { name: "Directory", icon: null },
          //   { name: "eMarket", icon: null },
          //   { name: "eServices", icon: null },
          //   { name: "Events", icon: null },
          // ],
          openAppTools,
        )}
        {renderNestedItem(
          "FUNCTIONS",
          true,
          functionOptionList,
          // [
          //   { name: "Notifications" },
          //   { name: "User Access" },
          //   { name: "Transactions" },
          //   { name: "Tasks List" },
          //   { name: "Editorial",showExpanIcon:true },
          //   { name: "Widgets" ,showExpanIcon: true},
          // ],
          openFunctions
        )}
        {renderNestedItem(
          "Others",
          true,
          [
            // { name: "Notifications" },
            // { name: "User Access"},
            // { name: "Transactions" },
            // { name: "Tasks List" },
            // { name: "Editorial",icon:ChevronRightIcon },
            // { name: "Widgets",icon:ChevronRightIcon },
            // { name: "Others",icon:ChevronRightIcon },
          ],
          openOthers
        )}
        <Divider
          orientation="vertical"
          flexItem
          className={dividerStyle}
          sx={{ ml: 1 }}
        />
        {renderItem("ADMINISTRATION")}
        {renderItem("Billing")}
        {renderItem("Apply")}
        {renderItem("Documentation")}
        {renderItem("Settings")}
        {renderItem("Support")}
        {renderItem("Logout")}
        <Divider
          orientation="vertical"
          flexItem
          className={dividerStyle}
          sx={{ ml: 1 }}
        />
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex", flexDirection: 'column' }}>
      <AppBar className={header} position="fixed" color="transparent">
        <Toolbar sx={{ display: "flex" }} className={customizeToolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: "none", sm: "flex" }, flexGrow: 0.7 }}>
            <Typography
              onClick={() => navigate("/")}
              component="div"
              sx={{
                display: { xs: "none", sm: "none", lg: "flex", xl: "flex" },
              }}
              className={logo}
            // style={HEADER1}
            >
              {YC_TITLE + " - Mauritius "}
            </Typography>
          </Box>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            className={dividerStyle}
            sx={{ display: { xs: "none", sm: "none", lg: "flex", xl: "flex" } }}
          />
          <Box sx={{ display: { xs: "flex", sm: "flex" }, flexGrow: 1 }}>
            {renderIcon(HomeDashboard)}
            <Box
              sx={{
                color: BLACK,
                fontSize: 16,
                fontWeight: "700",
                alignSelf: "center",
                display: { xs: "none", sm: "none", lg: "flex", xl: "flex" },
              }}
            >
              Business Name
            </Box>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "flex" }, flexGrow: 1 }}>
            {renderIcon(PreferencesImg)}
            {renderIcon(AddApplications)}
            {renderIcon(DataMining)}
            {renderIcon(ContentManagement)}
            {renderIcon(Accounting)}
            {renderIcon(SocialMedia)}
            {renderIcon(UploadFiles)}
            {renderIcon(Documents)}
            {renderIcon(Tutorials)}
          </Box>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            className={dividerStyle}
            sx={{ display: { xs: "none", sm: "flex" } }}
          />
          <Box
            sx={{
              display: {xs: "none", sm: "flex" },
              justifyContent: "center",
              flexGrow: 0.4,
            }}
          >
            {renderIcon(YellowMailFill)}
            {renderIcon(BellPinFill)}
          </Box>
          <Box
              sx={{
                color: BLACK,
                fontSize: 16,
                fontWeight: "700",
                alignSelf: "center",
                display: { xs: "flex", sm: "flex", lg: "flex", xl: "flex" },
              }}
            >
              <Typography
              onClick={() => {
                // setIsDisplayLogoutOption(!isDisplayLogoutOption)
              }}
              component="div"
              sx={{
                display: { xs: "none", sm: "flex", lg: "flex", xl: "flex" },
              }}
              className={logo}
            // style={HEADER1}
            >
              {userFullname}
            </Typography>
            </Box>
          <Box
            sx={{
              display: { xs: "flex", sm: "none" },
              justifyContent: "flex-end",
              flexGrow: 1,
            }}
          >
            {renderIcon(ServiceToolIcon, 'setting_tool')}
            {renderIcon(YellowMailFill)}
            {renderIcon(BellPinFill)}
            {renderIcon(UserProfile, 'User_profile')}
          </Box>
        </Toolbar>
      </AppBar>
      {/* {isDisplayLogoutOption && (
        <Box sx={{ marginLeft: { xs: "100%", sm: "390%", lg: "550%", xl: "0%" }, marginTop: "-10px", display: 'flex', justifyContent: 'flex-end', width: '100%', height: "5%" }}>
        <CustomButton
          placeHolder={t("YC_LOGOUT")}
          textTransform="none"
          background={"red"}
          height="100%"
          width={"100%"}
          borderRadius="7.2px"
          buttonStyle={{
            minWidth: "200px", color: BLACK
          }}
          onClick={() => {
            localStorage.clear();
            navigate("/signup")
           }}
        />
      </Box>
      )} */}
      <Box component="nav" >
        <Drawer
          container={container}
          anchor="left"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: { BLACK },
              mt: 6,
              pb:9.8,
            },
          }}
        >
          {/* {drawer} */}
          {sidePanel}
        </Drawer>
      </Box>
      <Modal
              open={isOpen}
              onClose={togglePopup}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div>
              <PopUpModal
                title={"Logout"}
                description={"By clicking yes, you will get logout from the application"}
                handleClose={togglePopup}
                firstbuttonName={t("YC_YES")}
                secondbuttonName={t("YC_NO")}
                firstbuttonAction={() => {
                    localStorage.clear();
                    navigate("/")
                }}
                secondbuttonAction={() => {
                    togglePopup()
                }}
      />
              </div>
            </Modal>
    </Box>
  );
}
