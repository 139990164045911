import { Box, Button, Divider, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BLACK, RED, WHITE, YELLOW } from "utils/colors";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  boxView: {
    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
    borderRadius: "8px",
    background: "#FFFFFF",
    minHeight: "266px",
  },
  titleText: {
    color: BLACK,
    fontSize: 20,
    fontWeight: "700",
    marginTop: "15px",
    marginBottom: "20px",
  },
  bodyText: {
    color: RED,
    fontSize: 16,
    marginTop: 1,
    textAlign: "left",
    fontWeight: "700",
    lineHeight: "18px",
    fontStyle: "italic",
  },
  button: {
    fontWeight: 'bold',
    fontSize: 14,
    minHeight: 30,
    color: WHITE,
    textTransform: "capitalize",
    fontStyle: "normal",
    alignSelf: 'center',
    '&:hover': {
      color: BLACK,
      backgroundColor: YELLOW,
    },
  },

}));

function DashboardNotification(props: any) {
  const { boxView, titleText, button, bodyText } = useStyles();

  // 👇️ const now: Date
  const datetime: string = moment(new Date()).format('DD/MM/YYYY - HH:mm');
  const navigate = useNavigate();
  const { t } = useTranslation()
  const help = `${t("YC_WE_WILL_HELP_TO_TAKE_ADMIN_PANEL")}!`
  return (
    <Box className={boxView+" col-12 col-lg-7 m-0 p-0"}>
      <Typography className={titleText} > {t("YC_NOTICEBOARD")} </Typography>

      <Box
        sx={{
          minHeight: "65%",
          borderRadius: "8px",
          border: "1px solid #000000",
          mx: 4,
          mb: 2,
        }}
      >
        <Box sx={{
          display: "flex",
          justifyContent: "space-between",
          mx: 2,
          my: 1.5,
        }}
        >
          <Button variant="contained" sx={{ backgroundColor: BLACK, }} className={button}>Sys.</Button>
          <Typography>{datetime}</Typography>
        </Box>
        <Box className={bodyText}
          sx={{
            textAlign: "start",
            ml: 5,
          }}
        >
          <Typography> {t("YC_WELCOME_TO")} Y-Click, </Typography>
          <Typography> {help} </Typography>
          <Link href="#" color="inherit" underline="none" fontWeight={"bold"}> {t("YC_READ_MORE")} </Link>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            mr: 2,
            mb: 2,
          }}
        >
          <Button variant="contained" className={button} onClick={() => {
              navigate("/dashboard-new");

          }}>{t("YC_MORE_NOTIFICATION")}</Button>
        </Box>
      </Box>
    </Box>
  );
}
export default DashboardNotification;
