import Axios from "axios";

const apiBaseUrl = 'https://y-click.io/'; // Add API url

const handleError = (error: any) => {
    // debugger;
    if (error.response !== undefined) {
        if (error.response.data.code === 401) {
            localStorage.clear();
            window.location.href ="./signup";
        } else if (error.response.data.code === 403) {
            return error.response.data.code;
        }
        else if(error.response.status === 404){
            return error.response;
        }
        return error.response.data.code;
    }
}

export default class BaseApi {
    static accessToken = localStorage.getItem('access_token');
    
    static setAccessToken(accessToken:string){
        localStorage.setItem('access_token',accessToken);
        this.accessToken=accessToken;
    }
    static headers = {
        Authorization: `Bearer ${this.accessToken}`
    }

    static async get(endpoint: string, headers?: any) {
        const res = await Axios.get(apiBaseUrl + endpoint, {
            headers: {Authorization: `Bearer ${this.accessToken}`},
            ...headers
        })
            .then((response: any) => {
                if (response.code && response.code === 401) {
                    return false;
                } else {
                    return response;
                }
            })
            .catch(error => {
                return handleError(error);
            });
        return await res;
    }

    static async put(endpoint: any, payload: any) {
        const res = await Axios.put(apiBaseUrl + endpoint, payload, {
            headers: { Authorization: `Bearer ${this.accessToken}`}
        })
            .then((response: any) => {
                if (response.code && response.code === 401) {
                    return false;
                } else {
                    return response;
                }
            })
            .catch(error => {
                return handleError(error);
            });
        return await res;
    }

    static async post(endpoint: any, payload: any, headers: any) {
        const res = await Axios.post(apiBaseUrl + endpoint, payload, {
            headers: { ...BaseApi.headers, ...headers },
          
        })
            .then((response: any) => {
                if (response.code && response.code === 401) {
                    return false;
                } else {
                    return response;
                }
            })
            .catch(error => {
                return handleError(error);
            });
        return await res;
    }

    static async delete(endpoint: any) {
        const res = await Axios.delete(apiBaseUrl + endpoint, {
            headers: BaseApi.headers
        })
            .then((response: any) => {
                if (response.code && response.code === 401) {
                    return false;
                } else {
                    return response;
                }
            })
            .catch(error => {
                return handleError(error);
            });
        return await res;
    }

    static async putWithoutToken(endpoint: any, payload: any) {
        const res = await Axios.put(apiBaseUrl + endpoint, payload)
            .then((response: any) => {
                if (response.code && response.code === 401) {
                    return false;
                } else {
                    return response;
                }
            })
            .catch(error => {
                return handleError(error);
            });
        return await res;
    }
}