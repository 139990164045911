import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { WHITE } from "utils/colors";

function PasswordField(props: any) {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const [onFocus, setOnFocus] = React.useState(false);
  return (
    <Box
        style={{
            ...props?.fieldStyle ?? null
        }}
        sx={{maxWidth: props.maxWidth ?? '60%'}}
    >
      <FormControl sx={{ display: 'flex', width: "100%", background: WHITE }} variant="outlined">
        <InputLabel
          htmlFor="outlined-adornment-password"
          sx={{ mt: props.value !== "" ? 0 : onFocus ? 0 : -1, background: WHITE }}
        >
          {props.label}
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={showPassword ? "text" : "password"}
          endAdornment={props.endAdornment ??
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
          sx={{fontSize: 14, fontWeight: "400"}}
          value={props.value}
          label={props.label}
          size="small"
          onChange={props.onChange}
          onBlur={() => {
            setOnFocus(false);
          }}
          onFocus={() => {
            setOnFocus(true);
          }}
        />
      </FormControl>
    </Box>
  );
}

export default PasswordField;
