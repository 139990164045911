import {
  Box,
  InputLabel,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "components/custom-button/custom-button";
import React, { useEffect } from "react";
import SlideButton from "components/slide-button/slide-button";
import ReCAPTCHA from "react-google-recaptcha";
import { BLACK, BLUE, GREY, RED, WHITE, YELLOW } from "utils/colors";
import { HEADER2 } from "utils/fonts";
import {
  YC_CONFIRM_PASSWORD,
  YC_FORGOT_PASSWORD_TEXT_1,
  YC_FORGOT_PASSWORD_TEXT_2,
  YC_NEW_PASSWORD,
  YC_PASSWORD_NOT_MATCH,
  YC_POWERED_BY_Y_CLICK,
  YC_SEND,
  YC_YOUR_UNIVERSAL_ONLINE_PANEL,
} from "utils/strings";
import { getStrength } from "utils/helper";
import PasswordField from "components/password-field/password-field";
import PasswordStrength from "components/password-field/password-strength-indicator";
import HomeApi from "services/HomeAPi";
import Notification from "views/Notifications/Notification";
import { useNavigate } from "react-router-dom";

const UseStyles = makeStyles(() => ({
  rightContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    background: WHITE,
    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    width: "100%",
    height: "100%",
    // height: 500,
    alignItems: "center",
    overflowY: "auto",
  },
  titleText: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    fontWeight: 600,
    lineHeight: "48px",
    fontSize: 24,
    color: BLACK,
  },
  inputView: {
    width: "70%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  poweredByText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 30,
    marginBottom: 30,
    height: 25,
    background: BLACK,
    
  },
  subTitleText: {
    fontWeight: 400,
    lineHeight: "24px",
    fontSize: 16,
    color: BLACK,
  },
}));

function ResetPasswordView(props: any) {
  const { rightContainer, titleText, inputView, poweredByText, subTitleText } = UseStyles();
  const [recaptchaResponse, setRecaptchaResponse] = React.useState("");
  const [isValidData, setIsValidData] = React.useState(false);
  const [sliderAction, setSliderAction] = React.useState(false);
  const [isShowRecaptcha, setIsShowRecaptcha] = React.useState(false);

  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [passwordStrength, setPasswordStrength] = React.useState(10);
  const [isPasswordNotMatch, setIsPasswordNotMatch] = React.useState(false);

  const navigate = useNavigate();
  const isValid = () => {
    if (password !== "" && confirmPassword !== "" && (password === confirmPassword)) {
      setIsValidData(true);
    } else {
      setIsValidData(false);
    }
  };

  useEffect(() => {
    isCreatePasswordValid()
    isValid()
  }, [password, confirmPassword])
  
  const slideBtnAction = (isOn: boolean) => {
    setIsShowRecaptcha(isOn);
    setSliderAction(isOn);
  };

  const handleRecaptchaChange = (token: string | null) => {
    if (token) {
      setRecaptchaResponse(token);
    }
  }

  const submitAction = async() => {
    const getEmail = localStorage.getItem('emailFromUrl')
    const payload = {
      email: getEmail,
      password: password,
      cpassword: confirmPassword
    };
    const response = await HomeApi.resetPassword(payload);
    if (response.status === true) {
      Notification.notifySuccess(response.msg)
      navigate("/signup");
    } else {
      Notification.notifyError(response.msg)
    }
  }

  const isCreatePasswordValid = () => {
    if (
      password !== "" &&
      confirmPassword !== "" &&
      password !== confirmPassword
    ) {
      setIsPasswordNotMatch(true);
    } else {
      setIsPasswordNotMatch(false);
    }
  };

  return (
    <Box className={rightContainer}>
      <Typography className={titleText}>{YC_NEW_PASSWORD}</Typography>
      <Box sx={{mr: 5, ml: 5, width: "85%", display:'flex', flexDirection: 'column' }}>
        <Typography textAlign={"center"} className={subTitleText}>
          {YC_FORGOT_PASSWORD_TEXT_1}
        </Typography>
        <Typography textAlign={"center"} className={subTitleText}>
          {YC_FORGOT_PASSWORD_TEXT_2}
        </Typography>
      </Box>
      <Box className={inputView}>
          <PasswordField
            label={YC_NEW_PASSWORD}
            value={password}
            fieldStyle={{ marginTop: 30 }}
            maxWidth={'100%'}
            width={"100px"}
            onChange={(text: any) => {
              setPasswordStrength(getStrength(text.target.value));
              setPassword(text.target.value);
              isCreatePasswordValid();
            }}
          />
          <PasswordStrength
            strength={passwordStrength}
            password={password}
            fromForgotPassword={true}
          />
          <PasswordField
            label={YC_CONFIRM_PASSWORD}
            value={confirmPassword}
            fieldStyle={{ marginTop: 10 }}
            width={"100px"}
            maxWidth={'100%'}
            onChange={(text: any) => {
              setConfirmPassword(text.target.value);
              isCreatePasswordValid();
            }}
          />
          {
            isPasswordNotMatch && (
              <InputLabel style={{ color: RED, textAlign: 'start', fontSize: 12, width: '70%' }}>{YC_PASSWORD_NOT_MATCH}</InputLabel>
            )
          }
        <SlideButton
          backgroundWidth={"75%"}
          boxWidth={"55%"}
          buttonWidth={"80%"}
          slideOnClick={slideBtnAction}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 6
          }}
        >
          {isShowRecaptcha && (
            <ReCAPTCHA
              sitekey="6Ley9ZgjAAAAAGGGJDAAd4jb1R-d0xjyqxS0ZTe6"
              onChange={handleRecaptchaChange}
            />
          )}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 7, mb: 5 }}>
          <CustomButton
            disabled={!isValidData}
            placeHolder={YC_SEND}
            textTransform="none"
            background={isValidData ? BLUE : GREY}
            height="40px"
            width={"70%"}
            borderRadius="7.2px"
            buttonStyle={{
              HEADER2,
            }}
            onClick={() => { submitAction() }}
          />
        </Box>
      </Box>
    </Box>
  );
}

function ResetPasswordPage(props: any) {
  return (
    <Box sx={{ p: 3 }}>
      {ResetPasswordView(props)}
      <Box sx={{
          display: "flex",
          flexDirection: {xs:"column", sm: "row", lg: "row", xl: "row"},
          justifyContent: "center",
          alignItems: "center",
          marginTop: 2,
          width: {xs:'100%', sm: '50%', lg: '50%', xl: '50%'},
          marginLeft: {xs:'0%', sm: '25%', lg: '25%', xl: '25%'},
          marginBottom: 10,
          height:{xs:38, sm: 25, lg: 25, xl: 25},
          background: BLACK
        }}>
          <Typography sx={{color:YELLOW}} fontSize={14}>
            {YC_POWERED_BY_Y_CLICK}
          </Typography>
          <Typography sx={{color:WHITE}} fontSize={14}>
            {`${" - " + YC_YOUR_UNIVERSAL_ONLINE_PANEL}`}
          </Typography>
        </Box>
    </Box>
  );
}

export default ResetPasswordPage;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "90%",
  maxWidth: 700,
  minWidth: 450,
  display: "flex",
  p: 4,
};
