import React from "react";
import {
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "components/custom-button/custom-button";
import LockImg from "../../assets/Lock_fill.png";
import UnlockImg from "../../assets/Unlock_fill.png";
import { BLACK } from "utils/colors";
import { SUBHEADER1 } from "utils/fonts";
import { useTranslation } from "react-i18next";
// import {
//   YC_SLIDE_LEFT,
//   YC_SLIDE_RIGHT,
//   YC_SLIDE_RIGHT_INFO,
// } from "utils/strings";

const UseStyles = makeStyles(() => ({
  mainView: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  slideButtonView: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    height: "40px",
    borderRadius: "7.2px",
    background: "#FFFFFF",
  },
  boxClass: {
    display: "flex",
    justifyContent: "center",
    width: "50%",
  },
  infoLabel: {
    fontSize: 16,
    fontWeight: "700",
  },
}));

function SlideButton(props: any) {
  const { slideButtonView, mainView, boxClass, infoLabel } = UseStyles();
  const [isRight, setIsRight] = React.useState(false);

  const { t } = useTranslation()
  
  const renderButton = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          zIndex: 999,
          width: "100%",
          marginTop: "-44px",
        }}
      >
        {!isRight ? (
          <CustomButton
            placeHolder={t("YC_SLIDE_RIGHT")}
            textTransform="none"
            background={BLACK}
            height="40px"
            width={props.buttonWidth ?? "40%"}
            borderRadius="7.2px"
            endIcon={<KeyboardDoubleArrowRight />}
            onClick={() => {
              setIsRight(true);
              props?.slideOnClick?.(true);
            }}
            buttonStyle={SUBHEADER1}
          />
        ) : (
          <Box sx={{ width: props.buttonWidth ?? "40%" }} />
        )}
        {isRight ? (
          <CustomButton
            placeHolder={t("YC_SLIDE_LEFT")}
            textTransform="none"
            background={BLACK}
            height="40px"
            width={props.buttonWidth ?? "40%"}
            borderRadius="7.2px"
            startIcon={<KeyboardDoubleArrowLeft />}
            onClick={() => {
              setIsRight(false);
              props?.slideOnClick?.(false);
            }}
            buttonStyle={SUBHEADER1}
          />
        ) : (
          <Box sx={{ width: props.buttonWidth ?? "40%" }} />
        )}
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{ mt: 5, display: "flex", justifyContent: "center", width: "100%" }}
      >
        <Box
          className={slideButtonView}
          sx={{ width: props.backgroundWidth ?? "66%", position: "relative" }}
        >
          <Box className={mainView}>
            <Box className={boxClass}>
              <img
                src={UnlockImg}
                height={40}
                style={{ marginTop: "4px" }}
                alt={"unlock icon"}
              />
            </Box>
            <Box className={boxClass}>
              <img
                src={LockImg}
                height={40}
                style={{ marginTop: "4px" }}
                alt={"lock icon"}
              />
            </Box>
          </Box>
          {renderButton()}
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        {!isRight && (
          <Typography
            sx={{
              color: "#E32222",
            }}
            className={infoLabel}
          >
            {t("YC_SLIDE_RIGHT_INFO")}
          </Typography>
        )}
      </Box>
    </>
  );
}

export default SlideButton;
