import { useEffect, useState } from "react";
import { Box, Button, CssBaseline, FormControl, InputLabel, Link, OutlinedInput, Select, SvgIcon, TextField, Typography, } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DashboardPageHeader from "components/dashboard-header/dashboard-header";
import { SUBHEADER1, SUBHEADERBOLD } from "utils/fonts";
import { BLACK, BLUE, GREY, GREY_3, LINK, WHITE, YELLOW } from "utils/colors";
import CustomButton from "components/custom-button/custom-button";
import { useTranslation } from "react-i18next";
import crossIcon from "../../../assets/crossIcon.png";
import rightIcon from "../../../assets/rightIcon.png";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useNavigate } from "react-router-dom";
import HomeApi from "services/HomeAPi";

const UseStyles = makeStyles(() => ({
    loginContainBlock: {
        flex: 1.6,
        "@media (max-width: 100px)": {
            display: "none",
        },
    },
    buttonStep: {
        width: "40px",
        height: "40px",
        backgroundImage: crossIcon,
        background: "red"
    }
}));

function ApplicationsDataList(props: any) {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [applicationData, setApplicationData] = useState(props.applicationData)

    const [currentPage, setCurrentPage] = useState(1);

    const totalPages = Math.ceil(applicationData.length / itemsPerPage);

    const handlePageClick = (page: any) => {
        setCurrentPage(page);
    };

    const planActivationAction = (isPlanActive: string, applicationId: any) => {
        if (isPlanActive === "Activated") {
        } else {
            navigate("/application-dash-planpricing", { state: { applicationId } })
        }
    }

    const renderItems = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return (
            <Box sx={{
                width: "100%",
                display: "flex",
                flexWrap: "wrap",
                gap: "20px" /* Space between items */
            }}>
                {applicationData.slice(startIndex, endIndex).map((appData: any) => {
                    return (
                        renderApplicationDataView(appData)
                    )
                })}
            </Box>
        )
    };

    const renderApplicationDataView = (applicationData: any) => {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "100%", sm: "48%", lg: "48%", xl: "48%" },
                border: 2,
                borderColor: GREY,
                boxShadow: 2,
                borderRadius: 2,
                marginTop: "10px"
            }}
            >
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "95%",
                    marginLeft: "2.5%",
                    marginTop: "10px",
                    justifyContent: "space-between"
                }}>
                    <Typography sx={{ width: "50%", textAlign: "start" }}>
                        {applicationData.applicationName}
                    </Typography>
                    <CustomButton
                        placeHolder={applicationData.isActivated === "Activated" ? "Inactivate" : "Activated"}
                        textTransform="none"
                        background={applicationData.isActivated === "Activated" ? YELLOW : BLUE}
                        height="30px"
                        alignItems="center"
                        width="20%"
                        borderRadius="5px"
                        border={BLACK}
                        titleColor={applicationData.isActivated === "Activated" ? BLACK : WHITE}
                        hoverColor={applicationData.isActivated === "Activated" ? WHITE : YELLOW}
                        onClick={() => {planActivationAction(applicationData.isActivated, applicationData.applicationId)}}
                        buttonStyle={{
                            SUBHEADER1
                        }}
                    />
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "85%",
                    marginLeft: "2.5%",
                    marginTop: "10px",
                    justifyContent: "space-between"
                }}>
                    <Typography sx={{ wordBreak: "break-word", textAlign: "start", fontSize: "12px" }}>
                        {applicationData.appDescription}
                    </Typography>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "95%",
                    marginLeft: "2.5%",
                    marginTop: "10px",
                    justifyContent: "space-between"
                }}>
                    {
                        applicationData.optIn === true ? (
                            <Typography sx={{ alignItems: "start" }}>
                                <SvgIcon component={PowerSettingsNewIcon} sx={{ color: BLACK }} inheritViewBox />
                                <Button
                                    sx={{
                                        textTransform: "none",
                                        "&:hover": {
                                            backgroundColor: "#fff",
                                        },
                                    }}
                                    variant="text"
                                    onClick={() => { }}
                                >{"Opt-in"}</Button>
                            </Typography>
                        )
                            :
                            (
                                <Box sx={{ width: "20%" }} />
                            )
                    }
                    <Button
                        sx={{
                            textTransform: "none",
                            "&:hover": {
                                backgroundColor: "#fff",
                            },
                        }}
                        variant="text"
                        onClick={() => { }}
                    >
                        <Link sx={{ mx: 1, color: LINK }} variant="h6" fontSize={12}>
                            {t("YC_MORE_INFO")}
                        </Link>
                    </Button>
                </Box>
            </Box>
        )
    }

    const renderPagination = () => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    onClick={() => handlePageClick(i)}
                    style={{ borderStyle: "none", backgroundColor: currentPage === i ? '#007bff' : '#fff', color: currentPage === i ? '#fff' : '#000' }}
                >
                    {i}{`${i < totalPages ? "," : ""}`}
                </button>
            );
        }
        return pageNumbers;
    };

    return (
        <>
            <Box >
                {renderItems()}
            </Box>
            <Box sx={{ marginTop: "20px", display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <button
                    onClick={() => { }}
                    style={{ margin: '0 0.5px', borderStyle: "none", backgroundColor: "transparent" }}
                >
                    {"<"}
                </button>
                {renderPagination()}
                <button
                    onClick={() => { }}
                    style={{ margin: '0 0.5px', borderStyle: "none", backgroundColor: "transparent" }}
                >
                    {">"}
                </button>
            </Box>
        </>
    );
}

export default ApplicationsDataList;
