import * as React from "react";
import Box from "@mui/material/Box";
import Popper from "@mui/material/Popper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { Cancel } from "@mui/icons-material";
import { IconButton, Link } from "@mui/material";
import { WHITE, YELLOW } from "utils/colors";

const windowWidth = window.innerWidth;

const UseStyles = makeStyles(() => ({
  poweredText: {
    marginTop: 30,
    boxShadow: "2px 8px 8px rgba(0, 0, 0, 0.35);",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#000000",
    height: 30,
  },
  btnStyle: {
    fontSize: 15,
    textTransform: "none",
  }
}));

export default function CustomizedPopper() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  const { poweredText, btnStyle } = UseStyles();

  return (
    <Box sx={{ width: 370}}>
      <Box className={poweredText}>
        <Button style={{ color: YELLOW }} onClick={handleClick} className={btnStyle}>
          Powered by Y-Click
        </Button>
        <Button style={{ color: WHITE, height: 30 }} onClick={handleClick} className={btnStyle}>
          - Your universal online panel
        </Button>
      </Box>
      <Popper open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper style={{boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)",}}>
              <Box sx={{ display: "flex", flexDirection: "column", maxWidth: windowWidth * 0.4, ml: 3, mr: 1}}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <IconButton aria-label="cancel" onClick={handleClick}>
                    <Cancel sx={{ height: 30, width: 30, color: "black" }} />
                  </IconButton>
                </Box>
                <Typography variant="h6" sx={{mb: 2}} fontSize={18}>
                  Y-Click - Slogan pending
                </Typography>
                <Typography sx={{mb: 2}} variant="subtitle1" fontSize={16}>
                  This account will enable you to access the Y-Click and
                  other online applications that have opted for an Y-Click
                  integration. Your Y-click admin panel is FREE, and you have
                  full control of which applications to access.
                </Typography>
                <Typography sx={{mb: 2}} variant="subtitle1" fontSize={16}>
                  Our main
                  objective is to facilitate local online usage in a
                  transparent, safe and ethical manner.
                </Typography>
                <Link sx={{mb: 2}} href="/why">To obtain more information access this link.</Link>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
}
