import { HelpOutline } from "@mui/icons-material";
import { Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { LINK } from "utils/colors";
import { BODY1 } from "utils/fonts";

const useStyles = makeStyles(() => ({
  containerView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    alignContent: "flex-start",
    width: "97%",
    marginBottom: "20px",
    padding: '10px'
  },
}));

function StepFooter(props: any) {
  const { containerView } = useStyles();
  return (
    <Box className={containerView +" "+ props.className}>
      <HelpOutline sx={{ width: 20, height: 20, mr: 1 }} />
      <Typography fontSize={14} fontWeight="bold">
        {props.text}
      </Typography>
      <Link
        href=""
        sx={{ mx: 1, color: LINK }}
        fontSize={14}
        fontWeight="bold"
      >
        {props.link}
      </Link>
    </Box>
  );
}

export default StepFooter;
