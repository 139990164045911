import * as React from "react";
import { AppBar, Box, CssBaseline, IconButton, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { YC_FOOTER_COPYRIGHT } from "utils/strings";
import HomepageFooter from "components/homepage-footer/homepage-footer";
import { WHITE } from "utils/colors";
import DashboardPageHeader from "components/dashboard-header/dashboard-header";
import MenuIcon from "@mui/icons-material/Menu";
import ControllerSetupContainer from "./controller-setup/controller-setup-controller";
import PreferencesImg from "../../assets/Preferences.png";
import AddApplications from "../../assets/Add_applications.png";
import DataMining from "../../assets/Data_mining.png";
import ContentManagement from "../../assets/Content_management.png";
import Accounting from "../../assets/Accounting.png";
import SocialMedia from "../../assets/Social_media.png";
import UploadFiles from "../../assets/Upload_files.png";
import Documents from "../../assets/Documents.png";
import Tutorials from "../../assets/Tutorials.png";

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: WHITE,
    height: 42,
    width: "100%",
    "@media (max-width: 599px)": {
      height: 82,
    },
  },
  customToolbar: {
    minHeight: 130,
  },
  containerStyle: {
    "@media (max-width: 599px)": {
      marginTop: 50,
    },
  }
}));

function Dashboard(props: any) {
  const { header, customToolbar, containerStyle } = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isSettingTools, setIsSettingTools] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleSettingTools = () => {
    setIsSettingTools(!isSettingTools);
  }

  const renderIcon = (iconName: any, key: any = '') => {
    return (
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 1 }}
      >
        <img
          src={iconName}
          height={30}
          alt={"icons"}
        />
      </IconButton>
    );
  };

  const renderTopNavBar = () => {
    return (
      <AppBar style={{height: isSettingTools ? 82 : 42}} className={header} elevation={2} position="fixed">
          <Toolbar className={customToolbar}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <DashboardPageHeader
              handleAppBar={handleSettingTools}
            />
          </Toolbar>
          {isSettingTools && <Box sx={{display: { xs: "flex", sm: "none" }}}>
            {renderSecondTopNavBar()}
          </Box>}
      </AppBar>
    );
  };

  const renderSecondTopNavBar = () => {
    return (
      <AppBar sx={{mt: 5.5}} elevation={2} position="fixed" color="default">
        <Toolbar sx={{height: '30px', justifyContent: 'center', alignItems: 'center'}}>
          <Box sx={{ display: { xs: "flex", sm: "none" } }}>
            {renderIcon(PreferencesImg)}
            {renderIcon(AddApplications)}
            {renderIcon(DataMining)}
            {renderIcon(ContentManagement)}
            {renderIcon(Accounting)}
            {renderIcon(SocialMedia)}
            {renderIcon(UploadFiles)}
            {renderIcon(Documents)}
            {renderIcon(Tutorials)}
          </Box>
        </Toolbar>
      </AppBar>
    );
  };

  return (
    <div style={{ height: "100%", flex: 1 }}>
      <Box
        sx={{
          marginBottom: "40px",
          height: "100%",
          flex: 1,
        }}
      >
        <CssBaseline />
        {renderTopNavBar()}
        <Box className={containerStyle}>
          <ControllerSetupContainer />
        </Box>
      </Box>
      <HomepageFooter isLinkFooter={true} displayText={YC_FOOTER_COPYRIGHT} />
    </div>
  );
}

export default Dashboard;
