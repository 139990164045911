import HomepageHeader from "../../components/homepage-header/homepage-header";
import { Box, Button, Link, Modal, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CountrySelect from "components/country-picker/country-picker";
// import {
//   YC_EMAIL,
//   YC_MOBILE,
//   YC_SUB_TITLE_1,
//   YC_SUB_TITLE_2,
//   YC_SUB_TITLE_3,
//   YC_TITLE_1,
//   YC_TITLE_2,
//   YC_LOGIN_HERE,
//   YC_ALREADY_ACCOUNT,
//   YC_FOOTER_COPYRIGHT_ORIGINAL,
// } from "utils/strings";
import CustomButton from "components/custom-button/custom-button";
import { useNavigate } from "react-router-dom";
import { BLACK, GREY, WHITE, YELLOW } from "utils/colors";
import BACKGROUNDIMAGE from "../../assets/y-click-universal-admin-panel.jpg";
import NewHomepageFooter from "components/homepage-footer/new-homepage-footer";
import TextInputField from "components/text-field/text-field";
import React from "react";
import CheckBox from "components/check-box/check-box";
import SignInPage from "views/signin/signinpage";
import SmallSlider from "components/slide-button/small-slide-button";
import { validateEmail, validateMobilePhone } from "utils/helper";
import { BODY } from "utils/fonts";
import HomeApi from "services/HomeAPi";
import { SignUpModel } from "models/redux-models";
import Notification from "views/Notifications/Notification";
import { useTranslation } from "react-i18next";

const UseStyles = makeStyles(() => ({
  leftContainer: {
    // display: "flex",
    width: "33%",
    marginBottom: 10,
  },
  leftContainerBoxView: {
    width: "100%",
    minWidth: "450px",
    // height: "100%",
    background: BLACK,
    border: "1px solid #FFD703",
    borderWidth: 5,
    borderColor: YELLOW,
    borderRadius: 10,
  },
  rightContainer: {
    display: "flex",
    width: "60%",
    "@media (max-width: 1200px)": {
      marginBottom: 20,
    },
  },
  loginContainBlock: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: 10,
    width: "100%",
    minWidth: "450px",
    height: "100%",
    "@media (max-width: 100px)": {
      display: "none",
    },
  },
  subtitleView: {
    marginTop: 50,
    marginRight: 30,
    marginLeft: 30,
  },
  subTitle: {
    lineHeight: "28px",
    textAlign: "left",
    letterSpacing: "0em",
    color: WHITE,
  },
  boxButtonPicker: {
    display: "flex",
    height: "25%",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "space-between",
    marginRight: 40,
    marginTop: 50,
  },
  titleView: {
    flexDirection: "column",
    justifyContent: "space-between",
  },
  titleStyle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 45,
    lineHeight: "60px",
    textAlign: "center",
    color: WHITE,
  },
  mainTitle: {
    textAlign: "left",
    color: YELLOW,
    fontSize: 70,
    fontWeight: "bold",
  },
  bodyText: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 20,
    lineHeight: "60px",
    textAlign: "left",
    marginLeft: 20,
    color: WHITE,
  },
  checkBoxView: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 40,
    width: "45%",
    justifyContent: "space-between",
    alignContent: "space-between",
  },
  loginText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
  },
}));

function Homepage() {
  const [isButtonDisabled, setButtonDisabled] = React.useState(false);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailAddress, setEmailAddress] = React.useState("");
  const [mobilePhone, setMobilePhone] = React.useState("");
  const [inputData, setInputData] = React.useState([
    { firstName: "" },
    { lastName: "" },
    { emailAddress: "" },
    { mobilePhone: "" },
  ]);
  const [isShowModal, setIsShowModal] = React.useState(false);
  const [isOTPEmail, setOTPEmail] = React.useState(false);
  const [isOTPMobile, setOTPMobile] = React.useState(false);

  const navigate = useNavigate();
  const {t} = useTranslation()
  const {
    leftContainer,
    rightContainer,
    leftContainerBoxView,
    bodyText,
    checkBoxView,
    loginText,
  } = UseStyles();

  function handleOpen() {
    setIsShowModal(true);
  }

  function handleClose() {
    setIsShowModal(false);
  }

  const validateFn = () => {
    if (firstName && lastName && emailAddress && mobilePhone) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  const clearData = () => {
    setFirstName("");
    setLastName("");
    setEmailAddress("");
    setMobilePhone("");
    setOTPEmail(false);
    setOTPMobile(false);
  };

  const genHelperText = (key: any) => {
    if (key === "first_name" && firstName === "") {
      return t("YC_PLEASE_ENTER_YOUR_FIRST_NAME");
    } else if (key === "last_name" && lastName === "") {
      return t("YC_PLEASE_ENTER_YOUR_LAST_NAME");
    } else if (key === "email_address") {
      if (emailAddress === "") {
        return t("YC_HT_EMAIL_ADDRESS");
      } else if (!validateEmail(emailAddress)) {
        return t("YC_EMAIL_INVALID_PLEASE_ENTER_VALID_EMAIL");
      } else {
        return "";
      }
    } else if (key === "mobile_phone") {
      if (mobilePhone === "") {
        return t("YC_ENTER_MOBILE_NUMBER");
      } else if (!validateMobilePhone(mobilePhone)) {
        return t("YC_NUMBER_INVALID_PLEASE_ENTER_VALID_MOBILE_NUM");
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const validateAllData = async () => {
    if (
      firstName &&
      lastName &&
      validateEmail(emailAddress) &&
      validateMobilePhone(mobilePhone)
    ) {
      const res = await signUpUser();
      console.log("res ---? ", res);
      if (res?.status) {
        clearData();
        Notification.notifySuccess("Successfully submitted enquiry form");
        // navigate("/dashboard");
      } else {
        Notification.notifyError("Something went wrong, please try again!");
      }
    } else {
      setButtonDisabled(true);
    }
  };

  const signUpUser = async () => {
    const payload = {
      fname: firstName,
      lname: lastName,
      email: emailAddress,
      mobile: mobilePhone,
      country: "India",
    };
    const response = await HomeApi.signUpUser(payload);
    return response;
  };

  function subTitleView(subTitleText: any) {
    const { subTitle } = UseStyles();
    return (
      <Typography sx={{ my: 3 }} className={subTitle} style={BODY}>
        {subTitleText}
      </Typography>
    );
  }

  const renderTextField = (key: any, label: any, fieldValue: any) => {
    return (
      <TextInputField
        label={label}
        shrink={true}
        isError={isButtonDisabled ? (genHelperText(key) ? true : false) : false}
        helperText={isButtonDisabled ? genHelperText(key) : ""}
        width={"100%"}
        fieldViewStyle={{ width: "96%" }}
        value={fieldValue}
        onChange={(text: any) => {
          if (key === "first_name") {
            setFirstName(text.target.value);
          } else if (key === "last_name") {
            setLastName(text.target.value);
          } else if (key === "email_address") {
            setEmailAddress(text.target.value);
          } else if (key === "mobile_phone") {
            const mob = text.target.value.replace(
              /(\d{2})(\d{2})(\d{2})(\d{2})/,
              "$1 $2 $3 $4"
            );
            setMobilePhone(mob);
          }
          validateFn();
        }}
      />
    );
  };

  const LeftContainView = () => {
    return (
      <Box
        className={leftContainerBoxView}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {renderTextField("first_name", t("YC_FIRST_NAME"), firstName)}
          {renderTextField("last_name", t("YC_LAST_NAME"), lastName)}
        </Box>
        {renderTextField("email_address", t("YC_EMAIL_ADDRESS"), emailAddress)}
        {renderTextField("mobile_phone", t("YC_MOBILE_PHONE"), mobilePhone)}
        <CountrySelect width={"96%"} height={40} fieldStyle={{ margin: 10 }} />
        {/* <Typography className={bodyText}>
          Send my One Time Password on my:
        </Typography> */}
        {/* <Box className={checkBoxView}>
          <CheckBox
            checked={isOTPEmail}
            sx={{ color: YELLOW, "&.Mui-checked": { color: YELLOW } }}
            onChange={(value: any) => {
              setOTPEmail(value.target.checked);
            }}
            value="top"
            label={YC_EMAIL}
            labelPlacement="end"
            labelColor={WHITE}
          />
          <CheckBox
            checked={isOTPMobile}
            sx={{ color: YELLOW, "&.Mui-checked": { color: YELLOW } }}
            onChange={(value: any) => {
              setOTPMobile(value.target.checked);
            }}
            value="top"
            label={YC_MOBILE}
            labelPlacement="end"
            labelColor={WHITE}
          />
        </Box>

        <SmallSlider /> */}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
            mb: 4,
          }}
        >
          <CustomButton
            placeHolder={t("YC_SUBMIT")}
            textTransform="none"
            background={YELLOW}
            titleColor={BLACK}
            height={40}
            width={"70%"}
            borderRadius={7.2}
            onClick={() => {
              validateAllData();
            }}
            hoverColor={WHITE}
            s
            buttonStyle={{ backgroundColor: YELLOW, fontSize: 20 }}
          />
        </Box>
        {/* <Box className={loginText}>
          <Typography sx={{ pt: 3, color: WHITE, fontSize: 16 }}>
            {YC_ALREADY_ACCOUNT}
          </Typography>
          \
          <Button sx={{ mt: 3 }} variant="text" onClick={handleOpen}>
            <Link sx={{ color: YELLOW, fontSize: 16 }}>{YC_LOGIN_HERE}</Link>
          </Button>
          <Modal
            open={isShowModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div>
              <SignInPage />
            </div>
          </Modal>
        </Box> */}
      </Box>
    );
  };

  const RightContainView = () => {
    const {
      loginContainBlock,
      titleStyle,
      titleView,
      subtitleView,
      mainTitle,
    } = UseStyles();
    return (
      <Box
        sx={{ display: { xs: "block", sm: "block", lg: "block", xl: "block" } }}
        className={loginContainBlock}
      >
        <Box
          sx={{ display: { xs: "flex", sm: "flex" }, mt: 2 }}
          className={titleView}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography className={mainTitle} sx={{ ml: 3, mb: 4.5 }}>
              {"Y "}
            </Typography>
            <Typography
              className={mainTitle}
              sx={{ ml: 3 }}
              style={{ color: WHITE }}
            >
              {" "}
              - CLICK
            </Typography>
          </Box>
          <Typography className={titleStyle}>{t("YC_TITLE_1")}</Typography>
          <Typography className={titleStyle} sx={{ my: 1 }}>
            {`- ${t("YC_TITLE_2")}`}
          </Typography>
        </Box>
        <Box className={subtitleView}>
          {subTitleView(t("YC_SUB_TITLE_1"))}
          {subTitleView(t("YC_SUB_TITLE_2"))}
          {subTitleView(t("YC_SUB_TITLE_3"))}
        </Box>
      </Box>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        background: "url(" + BACKGROUNDIMAGE + ")",
        objectFit: "cover",
      }}
    >
      <Box
        sx={{
          display: "flex",
          marginBottom: "40px",
          pb: 10,
        }}
      >
        <HomepageHeader />
        <Box
          component="main"
          sx={{
            display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
            justifyContent: "space-between",
            alignContent: "space-between",
            flexDirection: "row-reverse",
            p: 5,
            mt: 12,
          }}
        >
          <Box className={rightContainer}>{RightContainView()}</Box>
          <Box className={leftContainer}>{LeftContainView()}</Box>
        </Box>
      </Box>
      <NewHomepageFooter
        isLinkFooter={true}
        displayText={t("YC_FOOTER_COPYRIGHT_ORIGINAL")}
      />
    </div>
  );
}

export default Homepage;
