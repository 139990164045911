import { Box, InputLabel } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CustomButton from 'components/custom-button/custom-button';
import { BLACK, BLUE } from 'utils/colors';
import { YC_POPUP_QUESTION } from 'utils/strings';

const useStyles = makeStyles(() => ({
    closeIcon: {
        cursor: 'pointer',
        border: 1,
        width: 25,
        height: 25,
        borderRadius: '50%',
        background: '#fff',
        marginRight:5
    }

}));

const PopUpModal = (props: any) => {
    const {closeIcon } = useStyles();
    return (
        <div className="popup-box">
            <Box sx={{
                position: 'relative',
                width: '40%',
                minHeight: props.isHideQuestion ? '120px' : '220px',
                // border: '1px solid',
                borderRadius: 2,
                marginLeft: '35%',
                marginTop: { xs: "40%", sm: "20%", lg: "10%", xl: "10%" },
                overflow: 'auto',
                background: '#fff'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <Box sx={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between',
                        marginTop: 1
                    }}>
                        <Box sx={{ display: "flex", minWidth: 10, height: 15 }} />
                        <InputLabel sx={{color:BLACK, fontWeight:'bold'}}>{props.title}</InputLabel>
                        <button className={closeIcon} onClick={props.handleClose}>X</button>
                    </Box>
                    <>
                        <Box sx={{ display: "flex", minWidth: 80, height: 15 }} />
                        <p style={{ textAlign: 'center' }}>{props.description}</p>
                        {props.isHideQuestion ? null : (
                        <p style={{ textAlign: 'center', fontWeight: 'bold' }}>{YC_POPUP_QUESTION}</p>
                        ) }
                        <Box sx={{
                            display: 'flex',
                            width: { xs: "50%", sm: "95%", lg: "95%", xl: "95%" },
                            flexDirection: { xs: "column", sm: "row", lg: "row", xl: "row" },
                            justifyContent: "flex-end",
                            marginLeft: { xs: 9, sm: 0, lg: 0, xl: 0 }
                        }}>
                            <CustomButton
                                placeHolder={props.firstbuttonName}
                                textTransform="none"
                                background={BLUE}
                                height="40px"
                                width={"18%"}
                                borderRadius="7.2px"
                                onClick={props.firstbuttonAction}
                            />
                            <Box sx={{ display: "flex", minWidth: 10, height: 15 }} />
                            {props.isHideSecondButton ? null : (<CustomButton
                                placeHolder={props.secondbuttonName}
                                textTransform="none"
                                background={BLUE}
                                height="40px"
                                width={"18%"}
                                borderRadius="7.2px"
                                onClick={props.secondbuttonAction}
                            />) }
                        </Box>
                        <Box sx={{ display: "flex", minWidth: 10, height: 5 }} />
                    </>
                </Box>
            </Box>
        </div>
    );
};
export default PopUpModal;