import loaderSlice from "store/reducers/loader-slice";
import { AnyAction } from "@reduxjs/toolkit";
import { ThunkAction } from "@reduxjs/toolkit";
import { RootState } from "store/store";
export const loaderActions = loaderSlice.actions;
export const toggleLoader = (value:boolean): ThunkAction<
void,
RootState,
unknown,
AnyAction
> => {
return async (dispatch ) => {
    dispatch(loaderActions.setLoader(value));
};
};