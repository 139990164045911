import {toast } from 'react-toastify';

 class Notification {
    
   static notify = (data:string) => toast.info(data, { position: "top-right" });

   static notifyError = (data:string) => toast.error(data, {
        icon: "🤯",
        position: "top-right",
        autoClose: 5000
    });

    static notifySuccess = (data:string) => toast.success(data, {
        icon: "🚀",
        position: "top-right",
        autoClose: 5000
    });
   
}
 
export default Notification;