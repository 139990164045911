import * as React from "react";
import { Box, CssBaseline, Divider, Typography, } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BLACK, WHITE, YELLOW } from "utils/colors";
import StepFooter from "../dashboard-reusables/step-footer";
import DashboardMyApplication from "../../../components/dashboard/dashboard-my-application";
import DashboardBox from "../../../components/dashboard/dashboard-box/dashboard-box";
import { NoticeBoard } from "../../../components/dashboard/dashboard-box/NoticeBoard";
import moment from "moment";
import DashboardStatusAccordian from "components/dashboard/dashboard-status-accordian/dashboard-status-accordian";
import DasboardAppsTabs from "./dashboard-apps-tab";
import DashboardsActivity from "components/dashboard/dashboard-activity";
import './dashboard-app-tab.scss';
import { AccordionData } from "components/dashboard/dashboard-status-accordian/AccordianData";
import SideNav from "components/reusable-sideNav/SideNav";
import DashboardPageHeader from "components/dashboard-header/dashboard-header";
import HomepageFooter from "components/homepage-footer/homepage-footer";
import { YC_FOOTER_COPYRIGHT } from "utils/strings";
import { useTranslation } from "react-i18next";
import HomeApi from "services/HomeAPi";


const UseStyles = makeStyles(() => ({
    titleText: {
        color: BLACK,
        fontSize: 20,
        fontWeight: "700",
        marginTop: 20,
    },
    dividerStyle: {
        borderColor: WHITE,
        borderWidth: 0.5,
        opacity: 1,
    },
    bannerAdvert: {
        width: "80%",
        minHeight: "90px",
        backgroundColor: "#D9D9D9",
        margin: "auto",
        "@media (max-width: 800px)": {
            width: "100%",
            margin: "0px",
        }
    },
    boxView: {
        boxShadow: " 0px 11.67662px  9.35323px rgba(0, 0, 0, 0.15)",
        borderRadius: "8px",
        background: "#FFFFFF",
        paddingRight: "5px !important",
    },
    noticebox: {
        height: "98.8%",
        boxShadow: " 0px 4.67662px 9.35323px rgba(0, 0, 0, 0.15)",
        marginBottom: "-10px !important",
        marginTop: "-18px !important",
        borderRadius: "8px",
        position: "relative",
    }
}));

const drawerWidth = 250;

interface Props {

}

function DashboardComplete(props: Props) {
    const {
        titleText,
        dividerStyle,
        bannerAdvert,
        boxView,
        noticebox,
    } = UseStyles();
    const { t } = useTranslation()
    const [accordionData, setAccordionData] = React.useState<AccordionData[]>([])
  const [resourceCodeList, setResourceCodeList] = React.useState(["dash_business_setup", "dash_brand_identity", "dash_accounting",
    "dash_communication", "dash_dashboard_overview", "dash_manage_your_preferances"
  ])
    const [resourceDataList, setResourceDataList] = React.useState([])

  const getResourseManagementData = async() => {
    const response = await HomeApi.getResourseManagementData("incompleteDash")
    if (response.status === true) {
        setResourceDataList(response.data)
        let tempArray: any = [];
        response.data.map((resourse: any) => {
        tempArray.push(resourse.code)
        });
        setResourceCodeList(tempArray)
    }
  }

  React.useEffect(() => {
    getResourseManagementData()
    generateAccordionData()
  }, [])

  const generateAccordionData = async() => {
    const response = await HomeApi.getResourseManagementData("useraccess")
    let tempArray: any = [];
    resourceCodeList.map((code: any) => {
      if (code === "dash_business_setup" ) {
         tempArray.push({
              accordianTitle: "Business Set Up",
              accordianList: [{
                  businessStepOne: "Business step 1",
                  businessStepTwo: "Business step 2",
                  businessStepThree: "Business step 3",
                  businessStepFour: "Business step 4"
              }],
          })
      } else if (code === "dash_brand_identity") {
          tempArray.push({
            accordianTitle: "Brand Identity",
            accordianList: [{
                businessLogo: "Upload business Logo",
                businessColor: "Set business colours",
                businessSlogan: "Add business slogan",
            }],
        })
      } else if (code === "dash_accounting") {
          tempArray.push({
            accordianTitle: "Accounting",
            accordianList: [],
        })
      } else if (code === "dash_communication") {
          tempArray.push({
            accordianTitle: "Communication",
            accordianList: [],
        })
      } else if (code === "dash_dashboard_overview") {
          tempArray.push({
            accordianTitle: "Dashboard Overview",
            accordianList: [],
        })
      } else if (code === "dash_manage_your_preferances") {
          tempArray.push({
            accordianTitle: "Manage your preferances",
            accordianList: [],
        })
      }
    })
    setAccordionData(tempArray)
  }

  const isResourseAvailable = (resourseCode: string) => {
    let result
    if (resourceCodeList !== null) {
      result = resourceCodeList.find((resourse: any) => resourse.includes(resourseCode));
    }
    return result === resourseCode ? true : false
  }

    return (
        <div style={{ height: "100%", flex: 1 }}>
            <Box
                sx={{
                    marginBottom: "40px",
                    height: "100%",
                    flex: 1,
                }}
            >
                <DashboardPageHeader />

                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <CssBaseline />

                    <SideNav />

                    <Box
                        sx={{ width: { sm: `calc(100% - ${drawerWidth}px)` } }}
                    >
                        <Box
                            className="mx-3 me-lg-4"
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                flexGrow: 1,
                                mt: 7,
                                ml: 5,

                            }}
                        >
                            <Box
                                className={" d-flex flex-wrap row m-0 p-0"}
                                justifyContent="space-between"
                            >
                                <Box className={boxView + " col-12 col-lg-7 m-0 p-0 "}>
                                    <Box className={noticebox}>
                                        <Typography className={titleText} > {t("YC_NOTICEBOARD")} </Typography>
                                        {notifications.map((element) => (
                                            <DashboardBox element={element} />
                                        ))}
                                        <Box sx={{ height: 65, }}>
                                            <StepFooter
                                                text={t("YC_DO_YOU_NEED_ASSISTANCE")}
                                                link={t("YC_CLICK_HERE_TO_ACCESS_TUTORIALS")}
                                                className={" StepFooterDashebord"}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                                <DashboardStatusAccordian accordionData={accordionData} />
                            </Box>
                            <Box sx={{ mt: 5, }}>
                                <Divider
                                    orientation="horizontal"
                                    flexItem
                                    className={dividerStyle}
                                    style={{ borderColor: "rgba(0, 0, 0, 0.5)", borderWidth: 0.5 }}
                                />
                            </Box>
                            <Box sx={{
                                marginTop: 4,
                            }}
                            >
                                <Box className={bannerAdvert} >

                                </Box>

                            </Box>

                            <Box
                                className=" d-flex flex-wrap row m-0 p-0 mt-5"
                                justifyContent="space-between"
                            >
                                <Box className="col-12 col-lg-7 m-0 p-0" >
                                    <DashboardMyApplication />
                                </Box>
                                <Box className='col-12 col-lg-4  p-0  mt-lg-0 mt-2'>
                                    <DashboardsActivity />
                                </Box>
                            </Box>
                            <Box sx={{ mt: 4, }}>
                                <Divider
                                    orientation="horizontal"
                                    flexItem
                                    className={dividerStyle}
                                    sx={{ mb: 2, marginTop: 0.2 }}
                                    style={{ borderColor: "rgba(0, 0, 0, 0.5)", borderWidth: 0.5 }}
                                />
                            </Box>
                            <DasboardAppsTabs />
                        </Box>
                    </Box>
                </Box>
            </Box>
            <HomepageFooter isLinkFooter={true} displayText={YC_FOOTER_COPYRIGHT} />
        </div>
    );
}

export default DashboardComplete;
const datetime: string = moment(new Date()).format('DD/MM/YYYY - HH:mm');
const notifications: NoticeBoard[] = [
    {
        heading1Text: "Yellow Market",
        postedBy: "Posted by: System",
        postedDate: datetime,
        headingText2: "NEWS FUNCTION",
        content: "We have update Yellow Market catalogue functions, and ....",
        readMoreLink: "Read More",
        buttonfirstText: "Updates",
        secondbuttonText: "More Notifications",
        buttonfirstTextClass: "UpdateButton",
        id: 1,
        hrColor: YELLOW,
    },
    {
        heading1Text: "Resident of Quatre Bornes",
        postedBy: "Posted by: John Smith",
        postedDate: datetime,
        headingText2: "WATER PIPE LEAK - SODNAC AVENUE",
        content: "The water pipe leakage.........",
        readMoreLink: "Read More",
        buttonfirstText: "Resolved",
        secondbuttonText: "More Notifications",
        buttonfirstTextClass: "ResolvedButton",
        id: 2,
        hrColor: "#081F97"
    }
]

const accordionData1: AccordionData[] = [
    {
        accordianTitle: "Business Set Up",
        accordianList: [{
            businessStepOne: "Business step 1",
            businessStepTwo: "Business step 2",
            businessStepThree: "Business step 3",
            businessStepFour: "Business step 4"
        }],
    },
    {
        accordianTitle: "Brand Identity",
        accordianList: [{
            businessLogo: "Upload business Logo",
            businessColor: "Set business colours",
            businessSlogan: "Add business slogan",
        }],
    },
    {
        accordianTitle: "Accounting",
        accordianList: [],
    },
    {
        accordianTitle: "Communication",
        accordianList: [],
    },
    {
        accordianTitle: "Dashboard Overview",
        accordianList: [],
    },
    {
        accordianTitle: "Manage your preferances",
        accordianList: [],
    },

]