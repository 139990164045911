import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { BLACK } from "utils/colors";

function CheckBox(props: any) {
  return (
    <div>
      <Box>
        <FormControlLabel
          style={{color: props.labelColor ? props.labelColor : BLACK, fontWeight: '400', fontSize: '14px'}}
          value={props.value ?? ""}
          control={
            <Checkbox
              onChange={props.onChange}
              onClick={props.onClick}
              checked={props.checked}
              sx={
                props.sx
                  ? props.sx
                  : {
                      color: "#0F5CD0",
                      "&.Mui-checked": {
                        color: "#0F5CD0",
                      },
                    }
              }
            />
          }
          label={props.label ?? ""}
          labelPlacement={props.labelPlacement ?? ""}
        />
      </Box>
    </div>
  );
}

export default CheckBox;
