import * as React from "react";
import { Box, CssBaseline,} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { YC_FOOTER_COPYRIGHT } from "utils/strings";
import HomepageFooter from "components/homepage-footer/homepage-footer";
import { BLACK, WHITE, YELLOW } from "utils/colors";
import DashboardPageHeader from "components/dashboard-header/dashboard-header";
import InCompleteDashboard from "./dasheboard-new/incomplet-dashboard";
import SideNav from "components/reusable-sideNav/SideNav";

const UseStyles = makeStyles(() => ({
  mainContainer: {
    display: "flex",
    flexDirection: "row",
  },
  loginContainBlock: {
    flex: 1.6,
    "@media (max-width: 100px)": {
      display: "none",
    },
  },
  title: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 36,
    lineHeight: "60px",
    textAlign: "center",
    color: BLACK,
  },
  titleText: {
    color: BLACK,
    fontSize: 20,
    fontWeight: "700",
    marginTop: 1,
  },
  cellView: {
    color: YELLOW,
    background: BLACK,
  },
  cellText: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "space-between",
  },
  dividerStyle: {
    borderColor: WHITE,
    borderWidth: 0.5,
    opacity: 1,
    marginRight: 20,
  },
  header: {
    backgroundColor: WHITE,
    height: 42,
    width: "100%",
  },
  customToolbar: {
    minHeight: 42,
  },
}));

const drawerWidth = 250;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

function DashboardStatus(props: Props) {
  const {

  } = UseStyles();

  return (
    <div style={{ height: "100%", flex: 1 }}>
      <Box
        sx={{
          marginBottom: "40px",
          height: "100%",
          flex: 1,
        }}
      >
        <CssBaseline />
        <DashboardPageHeader />

        <Box sx={{ display: "flex", flexDirection: "row" }}>

          <SideNav />

          <Box sx={{ width: { sm: `calc(100% - ${drawerWidth}px)` } }}>
            <InCompleteDashboard />
          </Box>
        </Box>
      </Box>
      <HomepageFooter isLinkFooter={true} displayText={YC_FOOTER_COPYRIGHT} />
    </div>
  );
}

export default DashboardStatus;
