import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { BLACK } from "utils/colors";
import StepFooter from "../dashboard-reusables/step-footer";
import BusinessProcessOne from "./business-process-step-one";
import BusinessProcessTwo from "./business-process-step-two";
import BusinessProcessThree from "./business-process-step-three";
import BusinessProcessFour from "./business-process-step-four";

const useStyles = makeStyles(() => ({
  mainBoxView: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
    borderRadius: "7.2px",
    width: "97%",
    background: "#FFFFFF",
  },
  boxView: {
    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "space-between",
    borderRadius: "7.2px",
    width: "90%",
    minWidth: "30%",
  },
  titleText: {
    color: BLACK,
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 1,
  },
}));

function BusinessProcess({ goToNext, stepNumberBusiness }: any) {
  const { mainBoxView } = useStyles();
  const [stepNumber, setStepNumber] = React.useState(Number(stepNumberBusiness));

  const nextStep = (stepName: string, isPreStepClicked: boolean) => {
    if (stepNumber === 1) {
      if(isPreStepClicked) {
        setStepNumber(5)
        goToNext("controller_setup", true)
      } else {
        setStepNumber(2)
        goToNext(2)
      }
    } else if (stepNumber === 2) {
      if(isPreStepClicked) {
        setStepNumber(1)
        goToNext(1)
      } else {
        setStepNumber(3)
        goToNext(3)
      }
    } else if (stepNumber === 3) {
      if(isPreStepClicked) {
        setStepNumber(2)
        goToNext(2)
      } else {
        setStepNumber(4)
        goToNext(4)
      }
    } else if (stepNumber === 4) {
      if(isPreStepClicked) {
        setStepNumber(3)
        goToNext(3)
      } else {
        goToNext(stepName)
      }
    }
  }

  return (
    <Box className={mainBoxView}>
      {stepNumber === 1 && 
        <BusinessProcessOne
          goToNext={nextStep}
        />
      }
      {stepNumber === 2 && 
        <BusinessProcessTwo
          goToNext={nextStep}
        />
      }
      {stepNumber === 3 && 
        <BusinessProcessThree
          goToNext={nextStep}
        />
      }
      {stepNumber === 4 && 
        <BusinessProcessFour
          goToNext={nextStep}
        />
      }
      <StepFooter
        text={"Do you need assistance? "}
        link={"Click here to access Tutorials"}
      />
    </Box>
  );
}
export default BusinessProcess;
