// src/Switch.js
import React, { useState } from 'react';
import './switch-button.css';

// const CustomSwitch = ( isOn: any, handleToggle: any ) => {
const CustomSwitch = (props: any) => {
  return (
    <div className={`switch ${props.isOn ? 'on' : 'off'}`} onClick={props.handleToggle}>
      <div className="toggle" />
    </div>
  );
};

export default CustomSwitch;
