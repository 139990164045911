import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "components/custom-button/custom-button";
import { BLACK, BLUE, DARK_GRAY } from "utils/colors";
import { SUBHEADER1 } from "utils/fonts";
import UserStatusBlock from "./user-status-block";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import HomeApi from "services/HomeAPi";

const UserStatusStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "row",
        boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
        justifyContent: "space-between",
        alignItems: "flex-start",
        borderRadius: "7.2px",
        marginLeft: "10px",
        marginTop: "20px",
        width: '97%',
        background: "#FFFFFF",
    },

}))
function UserStatus(props: any) {
    const { boxView } = UserStatusStyle();
    const { t } = useTranslation()
    const [countData, setCountData] = React.useState({
        hd: 0,
        gu: 0,
        admin: 0,
        controller: 0
    })

    const getUserId = () => {
        let userId 
        const loginUserDetails = localStorage.getItem("user_Details")
        if (loginUserDetails){
          const details = JSON.parse(loginUserDetails)
          userId = details.id
        }
        return userId
    }

    const getPersonCountByRole = async() => {
        const response = await HomeApi.getPersonCountByRole(getUserId());
        if (response.status === true) {
            setCountData({
                hd: response.data[0]?.hd,
                gu: response.data[0]?.gu,
                admin: response.data[0]?.admin,
                controller: response.data[0]?.controller
            })
        }
    }
    useEffect(() => {
        getPersonCountByRole()
    },[])
    return (
        <Box className={boxView}>
            <Box sx={{
                display: "flex",
                flexDirection: "column", justifyContent: "flex-start",
                width: "30%",
                alignItems: "flex-start",
            }}>
                <label style={{
                    fontStyle: "normal",
                    fontWeight: 'bold',
                    fontSize: 19, marginLeft: "10px",
                    textAlign: "left",
                }}>{t("YC_YOUR_USER_STATUS")}</label>
                <label style={{ marginLeft: "10px", textAlign: "left" }} >{t("YC_NUMBER_OF_USER_AND_USER_TYPE")}</label>
            </Box>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center"
            }}>
                <UserStatusBlock name={t("YC_CONTROLLER")} allocated="1" value={countData.controller}></UserStatusBlock>
                <UserStatusBlock name={t("YC_ADMINISTRATOR")} allocated="1" value={countData.admin}></UserStatusBlock>
                <UserStatusBlock name={t("YC_HEAD_OF_DEPARTMENT")} allocated="2" value={countData.hd}></UserStatusBlock>
                <UserStatusBlock name={t("YC_GENERAL_USER")} allocated="4" value={countData.gu}></UserStatusBlock>
                <Box sx={{ aligself: "center", justifyContent: "center", alignContent: "center", alignSelf: "center", ml: "20px" }}>
                    <CustomButton
                        disabled={true}
                        placeHolder={t("YC_NEED_MORE_USERS")}
                        textTransform="none"
                        background={DARK_GRAY}
                        height="45px"
                        alignItems="center"
                        marginRight="10px"
                        marginLeft="20px"
                        width="auto"
                        borderRadius="4px"
                        // onClick={() => validateEmptyData()}
                        buttonStyle={{
                            SUBHEADER1
                        }}>
                    </CustomButton>
                </Box>
            </Box>
        </Box>
    );
}

export default UserStatus;
