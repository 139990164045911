import { inputLabelClasses } from "@mui/material";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { BLACK, WHITE, YELLOW } from "utils/colors";

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: WHITE,
  },
}));

export default function TextInputField(props: any) {
  const { header } = useStyles();
  return (
    <Box
      style={{
        margin: 10,
        boxShadow: props.isShadow ? "2px 4px 8px rgba(0, 0, 0, 0.07)" : "",
        background: "transparent",
        borderRadius: 4,
        ...(props.fieldViewStyle ?? null),
      }}
      sx={{ justifyContent: "center", display: "flex" }}
      className={header}
    >
      <TextField
        style={{
          width: props.width ?? "100%",
          ...(props?.textFieldStyle ?? null),
        }}
        InputLabelProps={props.shrink ? { shrink: false } : {}}
        disabled={props.isDisabled ?? false}
        required={props.isRequired ?? false}
        error={props.isError ?? false}
        multiline={props.isMultiLine ?? false}
        rows={props.rows ?? 1}
        id={props.id ?? "outlined-basic"}
        label={props.value && props.shrink ? "" : props.label ?? ""}
        variant={props.variant ?? "outlined"}
        type={props.type}
        defaultValue={props.defaultValue}
        helperText={props.helperText ?? ""}
        size={props.size ?? "small"}
        InputProps={{
          readOnly: props.isReadOnly ?? false,
          sx: {
            background: WHITE,
          },
        }}
        value={props.value}
        onChange={props.onChange}
        onInput={() => {
          if (props.onInput) {
            props.onInput();
          }
        }}
        // autoFocus={true}
      />
    </Box>
  );
}
