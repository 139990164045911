import { makeStyles } from "@mui/styles";
import { Box, Divider, Link, Typography } from "@mui/material";
import CheckBox from "components/check-box/check-box";
import { BLACK, ERROR, GREY, WHITE } from "utils/colors";
import { HEADER2 } from "utils/fonts";
import React, { useEffect } from "react";
import CustomButton from "components/custom-button/custom-button";
import TextInputField from "components/text-field/text-field";
import SlideButton from "components/slide-button/slide-button";
import ReCAPTCHA from "react-google-recaptcha";
import {
  YC_ACTIVATE_MY_ACCOUNT,
  YC_INVALID_OTP,
  YC_OTP,
  YC_PP,
  YC_RESEND_OTP_NOW_EX,
  YC_SIGN_UP_TEXT_ONE,
  YC_TERMS_POLICY_TEXT,
  YC_TICK_CONTINUE,
  YC_TOC,
} from "utils/strings";
import OtpInput from "react-otp-input";
import PasswordStrength from "components/password-field/password-strength-indicator";
import PasswordField from "components/password-field/password-field";
import { getStrength, navigateTo } from "utils/helper";
import HomeApi from "services/HomeAPi";
import Notification from "views/Notifications/Notification";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(() => ({
  profileView: {
    display: "flex",
    position: "relative",
    background: GREY,
    width: 73,
    height: 73,
    marginBottom: '-3%',
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: '1%',
    marginLeft: '4%',
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    background: WHITE,
    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: "3px",
    marginLeft: '6%',
    marginRight: '5%',
    alignItems: "center",
  },
  subTitleText: {
    paddingTop: 20,
    marginLeft: 20,
    marginRight: 20,
    textAlign: "left",
    fontSize: 16,
  },
  subTitle: {
    width: "70%",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "28px",
    textAlign: "left",
    color: BLACK,
  },
  dividerStyleVerticle: {
    borderColor: GREY,
    borderWidth: 0.5,
    opacity: 1,
  },
  otpViewTitle: {
    fontSize: 16,
    fontWeight: "500",
    color: "#1F2328",
    lineHeight: "24px",
  },
  privacyPolicyTextView: {
    display: "flex",
    marginTop: 5,
    justifyContent: "center",
    alignItems: "center",
  },
}));

const UserInvitation = (props: any) => {
  const steps = ["information", "verification", "password"];
  const [invitationStep, setInvitationStep] = React.useState(steps[0]);
  const [inputData, setInputData] = React.useState({
    firstName: props.firstName,
    lastName: props.lastName,
    businessName: "",
    email: props.email,
    mobilePhone: props.mobilePhone,
  });
  const [isValidData, setIsValidData] = React.useState(true);
  const [isDisableSendBtn, setIsDisableSendBtn] = React.useState(true);
  const [isShowRecaptcha, setIsShowRecaptcha] = React.useState(false);
  const [sliderAction, setSliderAction] = React.useState(false);
  const [OTP, setOTP] = React.useState("");
  const [isDisableActiveMyAccount, setIsDisableActiveMyAccount] = React.useState(true)
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [passwordStrength, setPasswordStrength] = React.useState(10);
  const [isPassword, setIsPassword] = React.useState(false);

  const [invalidOtp, setInvalidOtp] = React.useState(false);
  const [isOtpVerified, setOtpVerified] = React.useState(false);
  
  const navigate = useNavigate()

  const {
    profileView,
    rightContainer,
    subTitleText,
    subTitle,
    dividerStyleVerticle,
    otpViewTitle,
    privacyPolicyTextView,
  } = useStyles();

  const getTitle = () => {
    if (invitationStep === "information") {
      return "Y-Click User Invitation";
    } else if (invitationStep === "verification") {
      return "Verification";
    } else if (invitationStep === "password") {
      return "Create Password";
    } else {
      return "Invitation";
    }
  };

  const handleContinue = () => {
    invitationStepAction()
    // if (currentStep < steps.length - 1) {
    //   setInvitationStep(steps[currentStep + 1]);
    //   invitationStepAction()
    // } else {
    //   navigateTo("/dashboard");
    // }
  };

  const makeNextStep = () => {
    const currentStep = steps.indexOf(invitationStep);
    setInvitationStep(steps[currentStep + 1]);
  }

  const invitationStepAction = () => {
     if(invitationStep === "information"){
       updateInviteUserDetails()
     } else if(invitationStep === "verification"){
       setIsValidData(true)
       setSliderAction(false)
       verifyOtp()
     } else if(invitationStep === "password"){
       createPassword()
     }
  }
  
  const updateInviteUserDetails = async () => {
    const payload = {
      fname: inputData.firstName,
      lname: inputData.lastName,
      mobile: inputData.mobilePhone,
      business_name: inputData.businessName,
      email: inputData.email
    }

    const response = await HomeApi.updateInviteUserDetails(payload);
    if (response.status === true) {
      Notification.notifySuccess(response.message)
      makeNextStep()
      setIsValidData(true)

    } else {
      Notification.notifyError(response.message)
    }
  }

  const verifyOtp = () => {
    let verifyOTPData = { email:inputData.email, "otp": OTP };
    HomeApi.Verifyotp(verifyOTPData, '').then(res => {
      if (res.status === 200) {
        makeNextStep()
        setInvalidOtp(false);
        setOtpVerified(true);
      } else {
        throw res.message;
      }
    }).catch(err => {
      setInvalidOtp(true);
      setOtpVerified(false);
      Notification.notifyError(YC_INVALID_OTP);
    })
  }

  const createPassword = async () => {
    const payload = {
      email: inputData.email,
      password: password,
      cpassword: confirmPassword
    };
    const response = await HomeApi.createPassword(payload);
    if (response.status === true) {
      HomeApi.setAccessToken(response.access_token)
      Notification.notifySuccess(response.message)
      if (response.data.role === "controller"){
        navigate("/dashboard")
      } else {
        navigate("/incomplete-dashboard")
      }
    } else {
      Notification.notifyError(response.message)
    }
  }

  const isValid = () => {
    if (
      (inputData.firstName === "" || inputData.firstName === undefined) ||
      (inputData.lastName === "" || inputData.lastName === undefined) ||
      (inputData.email === "" || inputData.email === undefined) ||
      (inputData.mobilePhone === "" || inputData.mobilePhone === undefined)      
    ) {
       setIsValidData(true);
    } else {
      sliderAction ? setIsValidData(false) : setIsValidData(true)
    }
  };

  const isCreatePasswordValid = () => {
    if (password !== "" && confirmPassword !== "" && password === confirmPassword && sliderAction)  {
      setIsDisableSendBtn(false) 
    } else {
      setIsDisableSendBtn(true)
    }
  };

  const slideBtnAction = (isOn: boolean) => {
    setIsShowRecaptcha(isOn);
    setSliderAction(isOn);
  };

  const resendOTP = async () => {
    const payload = {
      mobilePhone: inputData.mobilePhone, 
      email: inputData.email, 
      first_name : inputData.firstName, 
      last_name : inputData.lastName 
    }
     
    const response = await HomeApi.getOTP(payload, '');
    if (response.status === true) {
      Notification.notifySuccess(response.msg)
      // navigate("/signup");
    } else {
      Notification.notifyError(response.msg)
    }
    // HomeApi.getOTP(payload, '').then(res => {
    //   const data = res.data;
    //   if (data.data.body) {
    //     setIsStepTwo(true);
    //   }
    // }).catch(err => { });

  };

  useEffect(() => {
    isValid();
  }, [inputData.firstName, inputData.lastName, inputData.email, inputData.mobilePhone, sliderAction, isShowRecaptcha]);

  useEffect(() => {
    isCreatePasswordValid();
  }, [sliderAction, password, confirmPassword]);

  // const [siteKey, setSiteKey] = React.useState("");
  // const getRecatchaKey = async () => {
  //   const response = await HomeApi.getRecaptcha();
  //   if (response.status === true) {
  //       setSiteKey(response.data.site_key)
  //   }
  // }

  // useEffect(() => {
  //   getRecatchaKey()
  // }, [])

  const renderTextInput = (key: String, label: String, value: String) => {
    return (
      <TextInputField
        isError={false}
        helperText={""}
        label={label}
        width={"100%"}
        fieldViewStyle={{ width: "100%" }}
        textFieldStyle={{
          marginRight:
            key !== "first_name" && key !== "last_name" ? "0.95%" : 0,
        }}
        value={value}
        onChange={(text: any) => {
          const trimStr = text.target.value.trimStart();
          if (key === "first_name") {
            setInputData((prevFields) => ({
              ...prevFields,
              firstName: trimStr,
            }));
          } else if (key === "last_name") {
            setInputData((prevFields) => ({
              ...prevFields,
              lastName: trimStr,
            }));
          } else if (key === "business_name") {
            setInputData((prevFields) => ({
              ...prevFields,
              businessName: trimStr,
            }));
          } else if (key === "email_address") {
            setInputData((prevFields) => ({
              ...prevFields,
              email: trimStr,
            }));
          } else if (key === "mobile_phone") {
            setInputData((prevFields) => ({
              ...prevFields,
              mobilePhone: trimStr,
            }));
          }
          isValid();
        }}
      />
    );
  };

  const renderInformationStepView = () => {
    return (
      <Box sx={{ p: 3 }}>
        <Typography className={subTitleText}>
          A person within your [CommunityName] have set up a user access to
          Y-Click, and this is an invitation for you to set your login
          credentials for this user account.
        </Typography>
        <Typography className={subTitleText}>
          You can update your user details once you have access to your account.
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            {renderTextInput(
              "first_name",
              "[UserFirstName]",
              inputData.firstName
            )}
            {renderTextInput("last_name", "[UserLastName]", inputData.lastName)}
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            {renderTextInput(
              "business_name",
              "[BusinessName]",
              inputData.businessName
            )}
            {renderTextInput(
              "email_address",
              "[UserEmailAddress]",
              inputData.email
            )}
            {renderTextInput(
              "mobile_phone",
              "[UserMobilePhoneNumber]",
              inputData.mobilePhone
            )}
          </Box>
          <SlideButton
            backgroundWidth={"67%"}
            boxWidth={"55%"}
            buttonWidth={"80%"}
            slideOnClick={slideBtnAction}
          />
          <Box
            sx={{
              mt: 2,
              mb: 5,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ReCAPTCHA
              sitekey= {props.siteKey} //"6Ley9ZgjAAAAAGGGJDAAd4jb1R-d0xjyqxS0ZTe6"
              onChange={() => {}}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const renderVerificationStepView = () => {
    const topMessage = YC_SIGN_UP_TEXT_ONE.replace(
      "[email - phone]",
      `[${inputData.email ?? ""} - ${inputData.mobilePhone ?? ""}]`
    );
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box className={subTitle} sx={{ mt: 4 }}>
          <Typography
            variant="subtitle1"
            sx={{ textAlign: "left" }}
            fontSize={16}
          >
            {topMessage}
          </Typography>
        </Box>
        <Divider
          orientation="vertical"
          variant="middle"
          sx={{ mr: 8, ml: 8, mt: 2, background: "rgba(0, 0, 0, 0.35)" }}
          flexItem
          className={dividerStyleVerticle}
        />
        <Typography sx={{ mt: 5 }} className={otpViewTitle}>
          {YC_OTP}
        </Typography>
        <OtpInput
          value={OTP}
          onChange={(value: string) => setOTP(value)}
          numInputs={4}
          separator={<span style={{width:2}}> {"   "} </span>}
          containerStyle={{
            marginTop: 30,
            justifyContent: "space-evenly",
            width: "50%",
          }}
          inputStyle={{
            width: 40,
            height: 40,
            background: "rgba(215, 215, 215, 0.01)",
            border: "1px solid #0F5CD0",
            boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.07)",
            borderRadius: 5,
          }}
        />
        <Divider
          orientation="vertical"
          variant="middle"
          sx={{ mr: 8, ml: 8, background: "rgba(0, 0, 0, 0.35)", mt: 3 }}
          flexItem
          className={dividerStyleVerticle}
        />
        <Box className={privacyPolicyTextView}>
          <Typography
            variant="subtitle1"
            sx={{
              textAlign: "center",
              width: "70%",
              lineHeight: "20px",
              mt: 5,
            }}
            fontSize={16}
          >
            {YC_TERMS_POLICY_TEXT}
            <br />
            <Link href="#">{YC_TOC}</Link>
            {" and "}
            <Link href="#">{YC_PP}</Link>
          </Typography>
        </Box>
        <Box
          sx={{
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          <CheckBox value="top" label={YC_TICK_CONTINUE} labelPlacement="end" 
            onChange={(value: any) => {
              setIsDisableActiveMyAccount(!value.target.checked);
            }}
          />
        </Box>
        <Box sx={{ mt: 5, width: "50%" }}>
          <CustomButton
            disabled={isDisableActiveMyAccount}
            placeHolder={YC_ACTIVATE_MY_ACCOUNT}
            textTransform="none"
            background={isDisableActiveMyAccount ? GREY : BLACK}
            height="40px"
            width={"100%"}
            borderRadius="7.2px"
            buttonStyle={{
              HEADER2,
            }}
            onClick={() => {
              handleContinue();
            }}
          />
        </Box>
        <Divider
          orientation="vertical"
          variant="middle"
          sx={{ mr: 8, ml: 8, background: "rgba(0, 0, 0, 0.35)", mt: 2.5 }}
          flexItem
          className={dividerStyleVerticle}
        />

        <Box sx={{ mt: 0.3, width: "50%" }}>
          <Typography
            sx={{ color: ERROR, mb: 1, mt: 5 }}
            variant="subtitle1"
            fontSize={16}
          >
            {"I did not receive my OTP"}
            <Typography
              sx={{ transform: "rotate(89.28deg)", marginLeft: "5px" }}
            >
              {" :-("}
            </Typography>
          </Typography>
          <CustomButton
            placeHolder={YC_RESEND_OTP_NOW_EX}
            textTransform="none"
            background={ERROR}
            height="40px"
            width={"100%"}
            borderRadius="7.2px"
            buttonStyle={{
              HEADER2,
              marginBottom: 30,
            }}
            onClick={() => {
              resendOTP();
            }}
          />
        </Box>
      </Box>
    );
  };

  const renderPasswordStepView = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box className={subTitle} sx={{ mt: 4 }}>
          <Typography
            variant="subtitle1"
            sx={{ textAlign: "left" }}
            fontSize={16}
          >
            Please enter your password. Password needs to have at least 8
            characters and contain at least one of the following: uppercase
            letters, lowercase letters, numbers, and symbols
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 1,
            display: "block",
            width:  { xs: "80%", sm: "50%", lg: "50%", xl: "50%" },
          }}
        >
          <PasswordField
            label={"Password"}
            value={password}
            fieldStyle={{ marginTop: 30 }}
            maxWidth={"100%"}
            width={"100%"}
            onChange={(text: any) => {
              setPasswordStrength(getStrength(text.target.value));
              setPassword(text.target.value);
              isCreatePasswordValid();
            }}
          />
          <PasswordStrength strength={passwordStrength} password={password} />
          <PasswordField
            label={"Confirm Password"}
            value={confirmPassword}
            fieldStyle={{ marginTop: 50 }}
            maxWidth={"100%"}
            width={"100%"}
            onChange={(text: any) => {
              setConfirmPassword(text.target.value);
              isCreatePasswordValid();
            }}
          />
        </Box>
        <Box sx={{ width: "70%", mt: 5, mb: 3 }}>
          <SlideButton
            backgroundWidth={"67%"}
            boxWidth={"55%"}
            buttonWidth={"80%"}
            slideOnClick={slideBtnAction}
          />
        </Box>
        <Box
          sx={{
            mt: 2,
            mb: 5,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReCAPTCHA
            sitekey={props.siteKey} //"6Ley9ZgjAAAAAGGGJDAAd4jb1R-d0xjyqxS0ZTe6"
            onChange={() => {}}
          />
        </Box>
      </Box>
    );
  };

  const getViewToRender = () => {
    if (invitationStep === "information") {
      return renderInformationStepView();
    } else if (invitationStep === "verification") {
      return renderVerificationStepView();
    } else if (invitationStep === "password") {
      return renderPasswordStepView();
    } else {
      return;
    }
  };

  return (
    <Box>
      <Box className={profileView}>Logo</Box>
      <Box className={rightContainer}>
        <Typography sx={{ mt: 4 }} variant="h6" fontSize={35} fontWeight="bold">
          {getTitle()}
        </Typography>
        <Box>{getViewToRender()}</Box>
        {invitationStep !== "verification" && (
          <CustomButton
            disabled={invitationStep === "information" ? isValidData : isDisableSendBtn}
            placeHolder={
              invitationStep === "information" ? "Verify Me" : "Send"
            }
            textTransform="none"
            background={ invitationStep === "information" ? isValidData ? GREY : BLACK : isDisableSendBtn ? GREY : BLACK}
            height="40px"
            borderRadius="7.2px"
            buttonStyle={{
              HEADER2,
              marginBottom: 30,
            }}
            onClick={() => {
              handleContinue();
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default UserInvitation;
