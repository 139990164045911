import { Accordion, AccordionDetails, AccordionSummary, Box, Button, IconButton, Modal, SvgIcon, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Marktick from "../../../../src/assets/marktickGreen.png";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { BLACK, WHITE, PINK, DARKGREY, YELLOW } from "utils/colors";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import { YC_ACCESS, YC_STATUS_SHORTCUTS, YC_TOOL_APPLICATION } from "utils/strings";
import { AccordionData } from "./AccordianData";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import BusinessProcessOne from "views/dashboard/business-setup/business-process-step-one";
import BusinessProcessTwo from "views/dashboard/business-setup/business-process-step-two";
import BusinessProcessThree from "views/dashboard/business-setup/business-process-step-three";
import BusinessProcessFour from "views/dashboard/business-setup/business-process-step-four";

const useStyles = makeStyles(() => ({
    boxView: {
        boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
        background: "#FFFFFF",
        minHeight: "266px",
        borderRadius: "8px",
    },
    titleText: {
        color: BLACK,
        fontSize: 20,
        fontWeight: "700",
        marginTop: 15,
        marginBottom: 17,
    },
    bodyText: {
        display: "flex",
        fontSize: 16,
        marginTop: 1,
        textAlign: "left",
        fontWeight: "700",
        lineHeight: "18px",
    },
    button: {
        minHeight: "29px",
        width: "20%",
        borderRadius: "8px",
        fontSize: 16,
        fontWeight: "700",
        '&:hover': {
            color: BLACK,
            backgroundColor: YELLOW,
        },
    }
}));

type DashboardStatusAccordian = {
    accordionData: AccordionData[]
}
function DashboardStatusAccordian(props: DashboardStatusAccordian) {
    const { boxView, button, titleText, bodyText } = useStyles();
    const navigate = useNavigate();

    const [isShowBusinessStepOneModal, setIsShowBusinessStepOneModal] = useState(false)
    const [isShowBusinessStepTwoModal, setIsShowBusinessStepTwoModal] = useState(false)
    const [isShowBusinessStepThreeModal, setIsShowBusinessStepThreeModal] = useState(false)
    const [isShowBusinessStepFourModal, setIsShowBusinessStepFourModal] = useState(false)
    
    const getBusinessStepList = (detailList: any, key: any) => {
        return(
            // {data.accordianList.map((detailList:any, key: any) => (
            <AccordionDetails key={key} className="accordianList">
                                <Typography sx={{"&:hover": {
                                    backgroundColor: "#4363EA",
                                    color: "#FFF !important",
                                    cursor: "pointer"
                                  }}}
                                  onClick={() => {
                                    // props.goToNext("business_setup")
                                    // navigate("/dashboard", {state:{isFromDashBoard:true}})
                                    setIsShowBusinessStepOneModal(true)
                                  }}
                                >
                                    <SettingsIcon />
                                    {detailList.businessStepOne}
                                </Typography>
                                <Typography sx={{"&:hover": {
                                    backgroundColor: "#4363EA",
                                    color: "#FFF !important",
                                    cursor: "pointer"
                                }}}
                                onClick={() => {
                                    setIsShowBusinessStepTwoModal(true)
                                  }}>
                                    <SettingsIcon />
                                    {detailList.businessStepTwo}
                                </Typography>
                                <Typography sx={{"&:hover": {
                                    backgroundColor: "#4363EA",
                                    color: "#FFF !important",
                                    cursor: "pointer"
                                }}}
                                onClick={() => {
                                    setIsShowBusinessStepThreeModal(true)
                                  }}>
                                    <SettingsIcon />
                                    {detailList.businessStepThree}
                                </Typography>
                                <Typography sx={{"&:hover": {
                                    backgroundColor: "#4363EA",
                                    color: "#FFF !important",
                                    cursor: "pointer"
                                }}}
                                onClick={() => {
                                    setIsShowBusinessStepFourModal(true)
                                  }}>
                                    <SettingsIcon />
                                    {detailList.businessStepFour}
                                </Typography>
                            </AccordionDetails>
        )
    // )}
    // )
    }

    const getBusinessBrandIdentity = (detailList: any, key: any) => {
        return(
            <AccordionDetails key={key} className="accordianList">
                                <Typography sx={{"&:hover": {
                                    backgroundColor: "#4363EA",
                                    color: "#FFF !important",
                                    cursor: "pointer"
                                }}}>
                                    <SettingsIcon />
                                    {detailList.businessLogo}
                                </Typography>
                                <Typography sx={{"&:hover": {
                                    backgroundColor: "#4363EA",
                                    color: "#FFF !important",
                                    cursor: "pointer"
                                }}}>
                                    <SettingsIcon />
                                    {detailList.businessColor}
                                </Typography>
                                <Typography sx={{"&:hover": {
                                    backgroundColor: "#4363EA",
                                    color: "#FFF !important",
                                    cursor: "pointer"
                                }}}>
                                    <SettingsIcon />
                                    {detailList.businessSlogan}
                                </Typography>
                            </AccordionDetails>
        )
    }

    return (
        <Box className={boxView + " col-12 col-lg-4 m-0 p-0  mt-lg-0 mt-2"}>
            <Typography className={titleText} >{YC_STATUS_SHORTCUTS}</Typography>
            <Box className='accordian-parent' >
                {props.accordionData.map((data, key) => (
                    <Accordion key={key} >
                        <AccordionSummary className="accordianHeadingParent"
                            expandIcon={<ExpandMoreIcon />}>
                            <Typography className="accordianHeading">{data.accordianTitle}</Typography>
                            <img src={Marktick} alt="Icon" />
                        </AccordionSummary>
                        {data.accordianTitle === "Business Set Up" ? getBusinessStepList(data.accordianList[0], key) : null}
                        {data.accordianTitle === "Brand Identity" ? getBusinessBrandIdentity(data.accordianList[0], key) : null}
                        {/* {data.accordianList.map((datalist, key) => (

                            <AccordionDetails key={key} className="accordianList">
                                <Typography sx={{"&:hover": {
                                    backgroundColor: "#4363EA",
                                    color: "#FFF !important",
                                    cursor: "pointer"
                                }}}>
                                    <SettingsIcon />
                                    {datalist.businessLogo}
                                </Typography>
                                <Typography sx={{"&:hover": {
                                    backgroundColor: "#4363EA",
                                    color: "#FFF !important",
                                    cursor: "pointer"
                                }}}>
                                    <SettingsIcon />
                                    {datalist.businessColor}
                                </Typography>
                                <Typography sx={{"&:hover": {
                                    backgroundColor: "#4363EA",
                                    color: "#FFF !important",
                                    cursor: "pointer"
                                }}}>
                                    <SettingsIcon />
                                    {datalist.businessSlogan}
                                </Typography>
                            </AccordionDetails>
                        ))} */}
                    </Accordion>
                ))}
            </Box>
            <Box sx={{
                minHeight: "47px",
                width: "95%",
                margin: "auto",
                marginTop: 3,
                marginBottom: 1,
                borderRadius: "8px",
                bgcolor: PINK,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
            }}
            >
                <IconButton>
                    <SvgIcon component={AddCircleRoundedIcon} sx={{ color: WHITE }} inheritViewBox />
                </IconButton>
                <Typography className={bodyText} sx={{ color: WHITE }} >{YC_TOOL_APPLICATION}</Typography>
                <Button variant="contained" className={button}>{YC_ACCESS}</Button>
            </Box>
            <Modal
              open={isShowBusinessStepOneModal}
              onClose={() => {setIsShowBusinessStepOneModal(false)}}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{
                width: "80%",
                marginLeft: "10%",
                background: WHITE,}}>
                <BusinessProcessOne 
                 isFromDashBoard={true}
                 setIsShowBusinessStepOneModal={setIsShowBusinessStepOneModal}
                />
              </Box>
            </Modal>
            <Modal
              open={isShowBusinessStepTwoModal}
              onClose={() => {setIsShowBusinessStepTwoModal(false)}}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{
                marginTop: "5%",
                width: "80%",
                marginLeft: "10%",
                background: WHITE,}}>
                    <BusinessProcessTwo 
                      isFromDashBoard={true}
                      setIsShowBusinessStepTwoModal={setIsShowBusinessStepTwoModal}
                    />
              </Box>
            </Modal>
            <Modal
              open={isShowBusinessStepThreeModal}
              onClose={() => {setIsShowBusinessStepThreeModal(false)}}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{
                marginTop: "5%",
                width: "80%",
                marginLeft: "10%",
                background: WHITE,}}>
                    <BusinessProcessThree 
                      isFromDashBoard={true}
                      setIsShowBusinessStepThreeModal={setIsShowBusinessStepThreeModal}
                    />
              </Box>
            </Modal>
            <Modal
              open={isShowBusinessStepFourModal}
              onClose={() => {setIsShowBusinessStepFourModal(false)}}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{
                marginTop: "5%",
                width: "80%",
                marginLeft: "10%",
                background: WHITE,}}>
                    <BusinessProcessFour 
                      isFromDashBoard={true}
                      setIsShowBusinessStepFourModal={setIsShowBusinessStepFourModal}
                    />
              </Box>
            </Modal>
        </Box>
    );
}
export default DashboardStatusAccordian;
