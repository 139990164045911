import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { GREEN } from 'utils/colors';
const ImageUploadDisplay = (props: any) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles: any) => {
      setUploadedFiles(acceptedFiles);
      props.onDrop(acceptedFiles)
    },
  });

 const truncateName =  (name: string) => {
    return name.length > props.maxLength ? name.substring(0, props.maxLength) + "..." : name;
}

//TO DO : Customize and Style this Drag and Drop to Upload box
  return (
    <div style={{
           width:props.width, 
           height: props.height
          }}
         {...getRootProps()}
    >
      <input {...getInputProps()} />
      {props.imageUrl !== "" && uploadedFiles.length === 0 ? 
        <img src={props.imageUrl} style={{marginLeft: props.isMarginLeftSet ? "30%" : "0%", marginTop:5, height:50, width:50}}/>
        : null
      }
      {props.imageUrl === "" && uploadedFiles.length === 0 ? 
        <p style={{marginTop: 15, color: GREEN, textAlign: 'center'}}>Select File</p> 
        : null
      }
      <ul style={{listStyleType: 'none'}}>
        {uploadedFiles.map((file: any) => (
          // <li key={file.name} style={{marginTop: 15, color: GREEN, width:'100%', overflow: 'hidden', textOverflow: 'ellipsis', 
          // textTransform: "uppercase", fontWeight: "700",
          // fontSize: 16,}}>
          //   {truncateName(file.name)}
          // </li>
          <img src={URL.createObjectURL(props.imageAdded[0])} style={{marginLeft:"0%", marginTop:5, height:50, width:50}}/>
        ))}
      </ul>
    </div>
  );
};
export default ImageUploadDisplay;