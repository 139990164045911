import { Box } from '@mui/material';
import HomepageFooter from 'components/homepage-footer/homepage-footer';
import { YC_FOOTER_COPYRIGHT } from 'utils/strings';
import HomepageHeader from 'components/homepage-header/homepage-header';

function ManagePage() {
    return (
      <div>
        <HomepageHeader />
        <Box component="main" sx={{ p: 1 }}>
          
        </Box>
        <HomepageFooter isLinkFooter={true} displayText={YC_FOOTER_COPYRIGHT} />
      </div>
    );
  }

export default ManagePage;
