import * as React from 'react';
import { makeStyles } from "@mui/styles";
import { Box, Button, Link, SvgIcon, Tab, Tabs, Typography } from '@mui/material';
import FilterFunail from "../../../assets/Filter_funail.svg";
import { BLACK, BLACK_2, BLUE, GREY, WHITE } from 'utils/colors';
import TextInputField from 'components/text-field/text-field';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useTranslation } from 'react-i18next';
import UserSettings from './userSetting';
import Preferences from './preferences';
import BrandSettings from './brand-settings';
import AdvancedSettings from './advanced-settings';

const useStyles = makeStyles(() => ({
  appText: {
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "23.3397px",
    lineHeight: "50px",
    color: "#FFFFFF",
    textAlign: "start",
  },
  inputBox: {
    float: "right",
    marginRight: "6px",
    marginLeft: "auto",
  }
}));
// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
//   className: string;
//   childClassName: string;
// }

// function TabPanel(props: TabPanelProps) {
//   const { children, value, index, className, childClassName } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       className={className}
//       aria-labelledby={`simple-tab-${index}`}
//     >
//       {value === index && (
//         <Box sx={{ p: 3, textAlign: "start" }}>
//           <Typography className={childClassName}>{children}</Typography>
//         </Box>
//       )}
//     </div>
//   );
// }

// function a11yProps(index: number) {
//   return {
//     id: `simple-tab-${index}`,
//     'aria-controls': `simple-tabpanel-${index}`,
//   };
// }

export default function AppUserSettings() {
  const [value, setValue] = React.useState(0);
  const { appText, inputBox } = useStyles();
  const { t } = useTranslation()
  const [isUserSettingClicked, setIsUserSettingClicked] = React.useState(true)
  const [isPrefernceClicked, setIsPrefernceClicked] = React.useState(false)
  const [isBrandIndenityClicked, setIsBrandIndenityClicked] = React.useState(false)
  const [isAdvancedSettingClicked, setIsAdvancedSettingClicked] = React.useState(false)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const appDetail = `${t("YC_DASHBOARD_APP_DETAILS")}`

  const renderButtonView = (key: string, name: string) => {
    return (
        <>
          <Button sx={{
                    marginLeft: "10px",
                    border: "1px solid #CCD0D4",
                    height: "40px", background: WHITE, color: BLACK, marginTop: 1, 
                    '&:hover': {
                        backgroundColor: BLACK,
                        color: WHITE,
                    }
           }}
           onClick={() => {
             if ( key === "user_settings") {
                 setIsUserSettingClicked(true)
                 setIsPrefernceClicked(false)
                 setIsBrandIndenityClicked(false)
                 setIsAdvancedSettingClicked(false)
             } else if ( key === "preferences") {
                setIsUserSettingClicked(false)
                setIsPrefernceClicked(true)
                setIsBrandIndenityClicked(false)
                setIsAdvancedSettingClicked(false)
             } else if ( key === "brand_identity") {
                setIsUserSettingClicked(false)
                setIsPrefernceClicked(false)
                setIsBrandIndenityClicked(true)
                setIsAdvancedSettingClicked(false)
             } else if ( key === "advanced_settings") {
                setIsUserSettingClicked(false)
                setIsPrefernceClicked(false)
                setIsBrandIndenityClicked(false)
                setIsAdvancedSettingClicked(true)
             }
           }}>{name}</Button>
        </>
    )
  }
  return (
    <Box>
      <Box sx={{
        minHeight: "50px",
        borderRadius: "0px",
        backgroundColor: "#000000",
        marginTop: "16px",
      }}
      >
        <Typography sx={{ ml: 2.5 }} className={appText}>
          {t("YC_MY_SETTINGS")}
        </Typography>
      </Box>
      <Box sx={{
        borderRadius: "0px",
        marginTop: "15px",
        textAlign: "start",
      }}
      >
        <Typography sx={{ ml: 2.5 }}>
         {t("YC_MY_SETTINGS_TEXT")}
        </Typography>
      </Box>
      <hr
        style={{
            color: BLACK,
            backgroundColor: BLACK,
            width: "100%",
            marginTop: 10,
            // marginLeft: "2.5%",
            height: 2
        }}
     />
      <Box sx={{ border: "1px solid #CCD0D4", mt: 1.5, borderColor: GREY, boxShadow: 2}}>
        <Box sx={{width: "95%"}} >
          <Box sx={{width: "100%"}}>
            <Box sx={{display: "flex", justifyContent: "flex-start", width: "100%"}}>
              {/* <Tab label={t("YC_USER_SETTINGS")} {...a11yProps(0)}/>
              <Tab label={t("YC_PREFERENCES")} {...a11yProps(1)} />
              <Tab label={t("YC_BRAND_IDENTITY")} />
              <Tab label={t("YC_ADVANCED_SETTINGS")} {...a11yProps(2)} /> */}
                <Button sx={{
                     marginLeft: "20px", //{xs: "-10%", sm: "-1%", lg: "-26%", xl: "-45%"},
                    border: "1px solid #CCD0D4",
                    height: "40px", 
                    background: isUserSettingClicked ? BLACK :  WHITE, 
                    color: isUserSettingClicked ? WHITE : BLACK, 
                    marginTop: 1, 
                    '&:hover': {
                        backgroundColor: BLACK,
                        color: WHITE,
                    }
                }}
                onClick={() => {
                        setIsUserSettingClicked(true)
                        setIsPrefernceClicked(false)
                        setIsBrandIndenityClicked(false)
                        setIsAdvancedSettingClicked(false)
                  }}>{t("YC_USER_SETTINGS")}</Button>
                <Button sx={{
                     marginLeft: "10px",
                    border: "1px solid #CCD0D4",
                    height: "40px", 
                    background: isPrefernceClicked ? BLACK :  WHITE, 
                    color: isPrefernceClicked ? WHITE : BLACK, 
                    marginTop: 1, 
                    '&:hover': {
                        backgroundColor: BLACK,
                        color: WHITE,
                    }
                }} onClick={() => {
                       setIsUserSettingClicked(false)
                       setIsPrefernceClicked(true)
                       setIsBrandIndenityClicked(false)
                       setIsAdvancedSettingClicked(false)
                  }}>{t("YC_PREFERENCES")}</Button>
                <Button sx={{
                     marginLeft: "10px",
                    border: "1px solid #CCD0D4",
                    height: "40px", 
                    background: isBrandIndenityClicked ? BLACK :  WHITE, 
                    color: isBrandIndenityClicked ? WHITE : BLACK, 
                    marginTop: 1, 
                    '&:hover': {
                        backgroundColor: BLACK,
                        color: WHITE,
                    }
                }} onClick={() => {
                       setIsUserSettingClicked(false)
                       setIsPrefernceClicked(false)
                       setIsBrandIndenityClicked(true)
                       setIsAdvancedSettingClicked(false)
                  }}>{t("YC_BRAND_IDENTITY")}</Button>
                <Button sx={{
                     marginLeft: "10px",
                    border: "1px solid #CCD0D4",
                    height: "40px", 
                    background: isAdvancedSettingClicked ? BLACK :  WHITE, 
                    color: isAdvancedSettingClicked ? WHITE : BLACK, 
                    marginTop: 1, 
                    '&:hover': {
                        backgroundColor: BLACK,
                        color: WHITE,
                    }
                }} onClick={() => {
                       setIsUserSettingClicked(false)
                       setIsPrefernceClicked(false)
                       setIsBrandIndenityClicked(false)
                       setIsAdvancedSettingClicked(true)
                  }}>{t("YC_ADVANCED_SETTINGS")}</Button>
                {/* {renderButtonView("user_settings", t("YC_USER_SETTINGS"))}
                {renderButtonView("preferences", t("YC_PREFERENCES"))}
                {renderButtonView("brand_identity", t("YC_BRAND_IDENTITY"))}
                {renderButtonView("advanced_settings", t("YC_ADVANCED_SETTINGS"))} */}
            </Box>
            <hr
                style={{
                    color: BLACK,
                    backgroundColor: BLACK_2,
                    width: "100%",
                    marginTop: "-2px",
                    marginLeft: "10px",
                    height: 2
                }}
            />
          </Box>
        </Box>
        {isUserSettingClicked === true && (<UserSettings/>)}
        {isPrefernceClicked === true && (<Preferences/>)}
        {isBrandIndenityClicked === true && (<BrandSettings/>)}
        {isAdvancedSettingClicked === true && (<AdvancedSettings/>)}
      </Box>
    </Box>
  );
}

const all = [
  "Business Directory",
  "Chat and  Email",
  "Quotation and Tender",
  "e-Market",
  "Customer Relation Management",
  "Accounting",
  "Payment Solution",
  "Human Resources",
  "e-Learning",
  "Appointments",
  "Advertising",
  "Marketing",
  "Search Engine Optimisation",
  "Social Media Marketing",
  "Project Manager",
  "Kirol",
  "Help Desk",
  "Events",
]
const applications = ["Working progress "];
const tabs = [
  { "element": all, type: "link" },
  { "element": applications, type: "other" },
  { "element": applications, type: "other" },
  { "element": applications, type: "other" },
]