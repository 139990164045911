import { Box, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomButton from "components/custom-button/custom-button";
import TextInputField from "components/text-field/text-field";
import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import SlideButton from "components/slide-button/slide-button";
import { BLACK, GREY, LINK, WHITE } from "utils/colors";
import { HEADER2 } from "utils/fonts";
// import {
//   YC_EMAIL_VALID,
//   YC_FORGOT_PASSWORD,
//   YC_FP_TEXT_ONE,
//   YC_FP_TEXT_TWO,
//   YC_HT_EMAIL_ADDRESS,
//   YC_LABEL_EMAIL_PHONE,
//   YC_SEND,
// } from "utils/strings";
import HomeApi from "services/HomeAPi";
import Notification from "views/Notifications/Notification";
import { validateEmail } from "utils/helper";
import { useTranslation } from "react-i18next";

const UseStyles = makeStyles(() => ({
  rightContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    background: WHITE,
    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)",
    borderRadius: "8px",
    // marginRight: windowHeight * 0.065,
    width: "100%",
    height: "100%",
    alignItems: "center",
    overflowY: "scroll",
  },
  titleText: {
    display: "flex",
    // height: windowHeight * 0.05,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    fontWeight: 400,
    lineHeight: "48px",
    fontSize: 24,
    color: BLACK,
  },
  subTitleText: {
    fontWeight: 400,
    lineHeight: "24px",
    fontSize: 16,
    color: BLACK,
  },
  inputView: {
    marginTop: 15,
    width: "90%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));
// const windowHeight = window.innerHeight;

function ForgotPasswordModelView(props: any) {
  const { rightContainer, titleText, inputView, subTitleText } = UseStyles();
  const [email, setemail] = React.useState("");
  const [isValidData, setIsValidData] = React.useState(true);
  const [sliderAction, setSliderAction] = React.useState(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState({
    email: false,
  });

  const { t } = useTranslation()

  const isShowError = () => {
    if (email && showErrorMessage.email) {
      return true;
    }
    return false;
  };

  const isShowErrorMessage = () => {
    if (email && showErrorMessage.email) {
      return t("YC_EMAIL_VALID");
    }
    return "";
  };
  const validateEmailId = (value: string) => {
    const validEmail = validateEmail(value);
    setShowErrorMessage((prevFields) => ({
      ...prevFields,
      email: !validEmail,
    }));
    return validEmail;
  };

  const slideBtnAction = (isOn: boolean) => {
    setSliderAction(isOn);
  };

  function validateEmptyData() {
    if (showErrorMessage.email || !sliderAction) {
      setIsValidData(false);
      return false;
    } else {
      setIsValidData(true);
      return true;
    }
  }

  useEffect(() => {
    validateEmptyData();
  }, [email, sliderAction]);

  function forgotPasswordData(header: any) {
    // debugger;
    HomeApi.forgotPassword({ email, reset_password_url: `https://y-click.io/reset-password/${email}` }, header).then(res => {
      if (res.status === 200 && !res.data.status) {
        Notification.notifySuccess(res.data.msg);
      } else {
        throw res.data.message ? res.data.message : "Something went wrong";
      }
    }).catch(err => {

      Notification.notifyError(err);
    })
  }

  return (
    <Box className={rightContainer}>
      <Typography className={titleText}>{`${t("YC_FORGOT_PASSWORD")}?`}</Typography>
      <Box sx={{ m: 5, mr: 5, ml: 5, width: "85%" }}>
        <Typography textAlign={"center"} className={subTitleText}>
          {t("YC_FP_TEXT_ONE")}
        </Typography>
        <Typography
          sx={{ mt: 4 }}
          textAlign={"center"}
          className={subTitleText}
        >
          {t("YC_FP_TEXT_TWO")}
        </Typography>
      </Box>
      <Box className={inputView}>
        <TextInputField
          width={"80%"}
          isError={isShowError()}
          // helperText={isValidData ? "" : YC_HT_EMAIL_ADDRESS}
          helperText={isShowErrorMessage()}
          label={t("YC_LABEL_EMAIL_PHONE")}
          background={WHITE}
          value={email}
          onChange={(text: any) => {
            setemail(text.target.value);
            validateEmailId(text.target.value);
          }}
        />
        <Box sx={{ mt: 6 }}>
          <SlideButton
            backgroundWidth={"67%"}
            boxWidth={"55%"}
            buttonWidth={"80%"}
            slideOnClick={slideBtnAction}
          />
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 9, mb: 9 }}>
          <CustomButton
            disabled={!isValidData}
            placeHolder={t("YC_SEND")}
            textTransform="none"
            background={isValidData ? BLACK : GREY}
            height="40px"
            width={"40%"}
            borderRadius="7.2px"
            onClick={() => forgotPasswordData({})}
            buttonStyle={{
              HEADER2,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

function ForgotPassword(props: any) {
  const [isShowModal, setIsShowModal] = React.useState(false);
  const { t } = useTranslation()
  
  function handleOpen() {
    setIsShowModal(true);
  }

  function handleClose() {
    setIsShowModal(false);
  }

  return (
    <div>
      <Button
        sx={{
          mt: 3,
          textTransform: "none",
          "&:hover": {
            backgroundColor: "#fff",
          },
        }}
        variant="text"
        onClick={handleOpen}
      >
        <Link sx={{ mx: 1, color: LINK }} variant="h6" fontSize={16}>
          {t("YC_FORGOT_PASSWORD")}
        </Link>
      </Button>
      <Modal
        open={isShowModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{ForgotPasswordModelView(props)}</Box>
      </Modal>
    </div>
  );
}

export default ForgotPassword;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 700,
  width: "100%",
  height: "90%",
  maxWidth: 700,
  minWidth: 450,
  display: "flex",
  p: 4,
};
