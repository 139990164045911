import { useState  } from "react";
import { Document, Page } from "react-pdf";
import deliverypolicy from "../../../assets/deliverypolicy.pdf";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

const CustomStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        borderRadius: "7.2px",
        marginTop: "10px",
        marginLeft: "20px",
        marginBottom: "10px",
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "80%",
        overflowY: 'auto',
        OverflowX: "none",
        maxWidth: 700,
        minWidth: 450,
        background: "white",
    }

}))

function PdfView() {
    const { boxView } = CustomStyle()
    const [numPages, setNumPages] = useState()
    const [pageNumber, setPageNumber] = useState(1)

    function onDocumentLoadSuccess(numPages : any) {
      setNumPages(numPages.numPages)
    }

    return(
        <Box className={boxView}>
             <Box sx={{
                marginLeft: "5%",
                width: "90%"
             }}>
                <p>
                    Page {pageNumber} of {numPages}
                </p>
                <Document file={deliverypolicy} onLoadSuccess={onDocumentLoadSuccess}>
                {Array.apply(null, Array(numPages))
                .map((x, i) => i + 1)
                .map((page: any) => {
                    return(
                        <Page 
                            pageNumber={page} 
                            renderTextLayer={false} 
                            renderAnnotationLayer={false}
                        />
                    )
                })}
                </Document>
             </Box>
        </Box>
    )
}

export default PdfView