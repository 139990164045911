import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { BLACK, GREEN, LIGHT_RED, RED } from "utils/colors";
import { YC_BUSINESS, YC_NO, YC_PUBLIC, YC_SELECT, YC_YES } from "utils/strings";

const useStyles = makeStyles(() => ({
  boxView: {
    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "space-between",
    borderRadius: "8px",
    width: "90%",
    minWidth: "30%",
  },
  titleStyle: {
    padding: 10,
    fontWeight: "700",
    fontSize: 16
  },
  textStyle: {
    fontWeight: "700",
    fontSize: 16,
    textTransform: "uppercase",
  }
}));

function CustomTitleSelect(props: any) {
  const { boxView, titleStyle, textStyle } = useStyles();

  return (
    <Box
      className={boxView}
      sx={{
        display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
        flexDirection: "row",
        border: 1,
        background: "#F9F9FA",
      }}
      style={{...props.boxStyle ?? null}}
    >
      <Box className={titleStyle} style={{...props.titleStyle ?? null}}>{props.title}</Box>
      <FormControl sx={{ minWidth: 120, borderRadius: 2 }}>
        {
          props.canSelect
            ?
            props.displayNA ? 
            <FormControl
            style={{background: LIGHT_RED,fontWeight: "bold", height:"66px", justifyContent:"center", alignItems:"center",
             borderTopLeftRadius:10, borderBottomLeftRadius:10, borderRadius:5}}
            >
              <Box
              style={{height:"40%"}}
              >
                N/A
              </Box>
            </FormControl>
            :
              <Select
                className={textStyle}
                sx={{
                  color: props.value !== "" ?
                    props.value === YC_YES || props.value === YC_PUBLIC || props.isFromControllerStepOne === true || props.keepTextGreen === true
                      ? GREEN
                      : props.value === YC_NO
                      ? RED
                      : "#00111"
                      : "#00111",
                }}
                style={{...props.selectStyle ?? null}}
                value={props.value}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                onChange={props.onChange}
                placeholder={YC_SELECT}
                renderValue={(selected) => {
                  if (selected === null || selected === "") {
                    return <>{props.selectPlaceholder ?? YC_SELECT}</>;
                  }
                  return selected;
                }}
              >
                {props.items.map((item: any) => {
                  return (
                    <MenuItem
                      key={`menu_${item}`}
                      sx={{
                        color:
                          item === YC_YES
                            ? GREEN
                            : item === YC_NO
                            ? RED
                            : BLACK,
                        fontWeight: "700",
                        fontSize: 16,
                      }}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            :
              <Box
                className={textStyle}
                sx={{
                  color:
                    props.value === YC_YES
                      ? GREEN
                      : props.value === YC_NO
                      ? RED
                      : "#00111",
                }}
                style={{...props.selectStyle ?? null}}
              >
                {props.value}
              </Box>
        }
      </FormControl>
    </Box>
  );
}

export default CustomTitleSelect;
