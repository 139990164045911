import { Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";

const useStyles = makeStyles(() => ({
  dividerStyle: {
    opacity: 1,
    borderRadius: 10,
  },
}));

function PasswordStrength(props: any) {
  const { dividerStyle } = useStyles();
  const bgColor = props.strength === 100 ? '#03a82f' : props.strength >= 80 ? props.fromForgotPassword ? '#C80C80': '#996c03' : "#CA1F1F";
  return (
    <Box sx={{display: { xs: "block", sm: "block", lg: "flex", xl: "flex" }, flexDirection: 'row', alignItems: 'center'}}>
      <Box sx={{ m: 2, border: 1, borderRadius: 2, width: "25%" }}>
        <Divider
          orientation="horizontal"
          flexItem
          className={dividerStyle}
          sx={{ display: "flex", width: `${props.strength}%`, height: "10px" }}
          style={{ background: bgColor}}
        />
      </Box>
      <Typography sx={{ color: bgColor, fontSize: 12, fontStyle: "italic", fontWeight: "400"}}>
        {props.strength === 100 ? 'Strong Password' : props.strength >= 80 ? props.fromForgotPassword ? 'Medium Password' : 'Good Password' : 'Weak Password'}
      </Typography>
    </Box>
  );
}

export default PasswordStrength;
