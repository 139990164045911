import { Box, FormControl, InputLabel, MenuItem, Modal, OutlinedInput, Select } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { makeStyles } from "@mui/styles";
import CustomButton from "components/custom-button/custom-button";
import CustomizedDialogs from "components/customized-dialog/customized-dialog";
import React, { useEffect } from "react";
import { BLACK, BLUE, DARK_GRAY, GREEN, GREY, RED, WHITE } from "utils/colors";
import { SUBHEADER1 } from "utils/fonts";
import AddUserDialog from "./add-user-dialog";
import EditUserDialog from "./edit-user-dialog";
import ManageRole from "./manage-role-capacity";
import UserStatus from "./user-status";
import UserNameTable from "./username-table";
import { useTranslation } from "react-i18next";
import HomeApi from "services/HomeAPi";
import { validateEmail } from "utils/helper";

const userAccessStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "column",
        boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        marginLeft: "10px",
        borderRadius: "7.2px",
        width: '60%',
        background: "#FFFFFF",
    },
    userBox: {
        boxsizing: "border-box",
        position: "absolute",
        width: "400px",
        height: "120px",
        left: "275px",
        top: "140px",
        background: "#FFFFFF",
        border: "1px solid #D7D7D7",
        boxshadow: "2px 4px 8px rgba(0, 0, 0, 0.12)",
        borderradius: "6px"
    }
}))
function UserAccess(props: any) {
    const { boxView } = userAccessStyle();
    const [controllerOfAccount, setControllerOfAccount] = React.useState("");
    const [isShowModal, setIsShowModal] = React.useState(false);
    const [isEditDialog, setIsEditDialog] = React.useState(false);
    const [searchUser, setSearchUser] = React.useState("");

    const { t } = useTranslation()

    const [bulkList, setBulkList] = React.useState([t("YC_REMOVE_SEVERAL_USERS"), t("YC_RESET_PASSWORD")])
    const [bulkAction, setBulkAction] = React.useState("");

    const [roleList, setRoleList] = React.useState([t("YC_ADMIN"), t("YC_HEAD_OF_DEPARTMENT"), t("YC_EDITOR_IN_CHIEF"), t("YC_EDITORIAL"), t("YC_SALES_MANAGER"), t("YC_SALES_REP")])
    const [userRole, setUserRole] = React.useState("");
    const [userRoleId, setUserRoleId] = React.useState(0);
    const [roleNameList, setRoleNameList] = React.useState([])
    const [applicationList, setApplicationList] = React.useState([])
    const [selectedApp, setSelectedApp] = React.useState("");
    const [applicationNameList, setApplicationNameList] = React.useState([])
    const [isValidEmail, setIsValidEmail] = React.useState({value: true, error: ""})
    const [userList, setUserList] = React.useState([])
    const [isNewUserAdded, setIsNewUserAdded] = React.useState(false);
    const [isUserAvailable, setIsUserAvailable] = React.useState(false);
    const [userForEdit, setUserForEdit] = React.useState()
    const [isUserEdited, setIsUserEdited] = React.useState(false);

    // For hide and unhide field as per Role
    const [resourceCodeList, setResourceCodeList] = React.useState([])
    const [resourceDataList, setResourceDataList] = React.useState([])

    const isResourseAvailable = (resourseCode: string) => {
        let result
        if (resourceCodeList !== null) {
          result = resourceCodeList.find((resourse: any) => resourse.includes(resourseCode));
        }
        return result === resourseCode ? true : false
    }

    const getLoginUserData = () => {
        const loginUserDetails = localStorage.getItem("user_Details")
        let loginUserData
        if (loginUserDetails) {
            const details = JSON.parse(loginUserDetails)
            loginUserData = details
        }
        return loginUserData
    }


    React.useEffect(() => {
        getResourseManagementData()
      },[])
    
    const getResourseManagementData = async() => {
    const response = await HomeApi.getResourseManagementData("useraccess")
    if (response.status === true) {
        setResourceDataList(response.data)
        let tempArray: any = [];
        response.data.map((resourse: any) => {
        tempArray.push(resourse.code)
        });
        setResourceCodeList(tempArray)
    }
    }

    const handleChange = (event: SelectChangeEvent) => {
        setControllerOfAccount(event.target.value);
    };

    function handleOpen() {
        setIsShowModal(true);
    }

    function handleClose() {
        setIsShowModal(false);
    }
    function handlEditOpen() {
        setIsEditDialog(true);
        setIsUserEdited(false)
    }

    function handleEditClose() {
        setIsEditDialog(false);
        setIsUserEdited(false)
    }

    const truncateText = (text: string) => {
        return text.length > 11 ? text.substring(0, 11) + "..." : text;
    }

    useEffect(() => {
        getRoleList()
        getApplicationList()
    }, [])
    
    const getRoleList = async () => {
        let userId;
        const loginUserDetails = localStorage.getItem("user_Details")
        if (loginUserDetails){
        const details = JSON.parse(loginUserDetails)
        userId = details.id
        }
        const response = await HomeApi.getRole(userId);
        if (response.status === true) {
            setRoleList(response.data)
            let tempArray: any = [];
            response.data.map((role: any) => {
            tempArray.push(role.doc)
            });
            setRoleNameList(tempArray)
        }
    }
    
    const getApplicationList = async () => {
        const response = await HomeApi.getApplicationList();
        if (response.status === true) {
            setApplicationList(response.data)
            let tempArray: any = [];
            response.data.map((app: any) => {
            tempArray.push(app.application_name)
            });
            setApplicationNameList(tempArray)
        }
    }

    const getUserSearchByEmail = async (userEmail: string) => {
        const response = await HomeApi.getUserSearchByEmail(userEmail);
        if (response.status === true) {
            setUserList(response.data)
            setIsUserAvailable(true)
        }
    }

    const getUserList = async () => {
        let userId;
        const loginUserDetails = localStorage.getItem("user_Details")
        if (loginUserDetails){
        const details = JSON.parse(loginUserDetails)
        userId = details.id
        }
        const response = await HomeApi.getUserList(userId);
        if (response.status === true) {
            setUserList(response.data)
            setIsUserAvailable(true)
        }
    }

    useEffect(() => {
        if(searchUser !== "" && isValidEmail.value) {
            getUserSearchByEmail(searchUser)
        } 
    }, [searchUser])

    useEffect(() => {
        if(isNewUserAdded || isUserEdited) {
            getUserList()
        } 
    }, [isNewUserAdded, isUserEdited])

    const dummyView = () => {
        return <Box sx={{ display: "flex", minWidth: 80, height: 18 }} />;
    };

    const textFieldView = (key: string, label: string = "", value: string, placeHolder: string) => {
        return (
            <Box>
                <FormControl
                    sx={{
                        width: "100%",
                        boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.07)",
                        background: WHITE,
                    }}
                    variant="outlined"
                >
                    <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
                    <OutlinedInput
                        // endAdornment={
                        //     value?.length === 0 ? (<InputAdornment position="end">
                        //         {renderIcon(ErrorExclamation)}
                        //     </InputAdornment>) :
                        //         <InputAdornment position="end">
                        //             {renderIcon(SuccessIcon)}
                        //         </InputAdornmen>
                        // }
                        sx={{ height: "30px" }}
                        label={label}
                        value={value}
                        placeholder={placeHolder}
                        onChange={(text) => {
                            const trimStr = text.target.value.trimStart();
                            if (key === "search_user") {
                                const trimStr = text.target.value.trimStart();
                                const validEmail = validateEmail(trimStr);
                                setIsValidEmail({value: validEmail, error: !validEmail ? t("YC_EMAIL_VALID") : ""})
                                setSearchUser(trimStr)
                                if (text.target.value === ""){
                                    getUserList()
                                }
                            }
                        }}
                    />
                </FormControl>
            </Box>
        );
    };

    return (
        <Box sx={{
            display: "table-row",
        }}>
            <Box sx={{
                display: "flex",
                flexDirection: {xs: "column", sm: "column", lg: "row", xl: "row"}
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginLeft: "10px",
                    borderRadius: "7.2px",
                    width: {xs: "100%", sm: "100%", lg: "65%", xl: "60%"},
                    background: "#FFFFFF",
                    mb: {xs: 5, sm: 5, lg: 1, xl: 0.5},
                }}>
                    <Box sx={{ ml: 3, mt: 2, mb: 4, display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        {isResourseAvailable("ua_add_new") && (
                            <CustomButton
                            placeHolder={t("YC_ADD_NEW")}
                            textTransform="none"
                            background={BLUE}
                            height="30px"
                            marginRight="10px"
                            width="auto"
                            borderRadius="4px"
                            onClick={handleOpen}
                            buttonStyle={{
                                SUBHEADER1
                            }}>
                        </CustomButton>
                        )}

                        <Modal
                            open={isShowModal}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <div>
                                <AddUserDialog 
                                  setIsShowModal={setIsShowModal}
                                  roleList={roleList}
                                  roleNameList={roleNameList}
                                  setIsNewUserAdded={setIsNewUserAdded} />
                            </div>
                        </Modal>

                        {isResourseAvailable("ua_edit_user") && (
                            <CustomButton
                            placeHolder={t("YC_EDIT_USER")}
                            textTransform="none"
                            background={BLUE}
                            height="30px"
                            marginRight="10px"
                            width="auto"
                            borderRadius="4px"
                            onClick={handlEditOpen}
                            buttonStyle={{
                                SUBHEADER1
                            }}
                        />
                        )}

                        <Modal
                            open={isEditDialog}
                            onClose={handleEditClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <div>
                                <EditUserDialog 
                                  userForEdit={userForEdit}
                                  setIsUserEdited={setIsUserEdited}
                                />
                            </div>
                        </Modal>
                        {isResourseAvailable("ua_view_user") && (
                            <CustomButton
                                placeHolder={t("YC_VIEW_USER")}
                                textTransform="none"
                                background={BLUE}
                                height="30px"
                                width="auto"
                                borderRadius="4px"
                                marginRight="20px"
                                // onClick={() => validateEmptyData()}
                                buttonStyle={{
                                    SUBHEADER1
                                }}
                            />
                        )}
                        {dummyView()}
                        <Box sx={{
                            display: "flex",
                            flexDirection: "row", mr: 2
                        }}>
                            <label style={{ marginRight: "10px" }}>{t("YC_SEARCH_USERS")}:</label>
                            {/* <input width={"50%"}></input> */}
                            {textFieldView(
                                "search_user",
                                "",
                                searchUser,
                                t("YC_SEARCH_BY_EMAIL")
                            )}
                        </Box>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row", mr: 2,
                        mb: {xs: 5, sm: 5, lg: 1, xl: 0.5},
                    }}>
                        {isResourseAvailable("ua_select_bulk_action") && (
                            <Box sx={{ ml: 3, mr: 1, display: "flex", flexDirection: "row" }}>
                            <CustomButton placeHolder={t("YC_APPLY")}
                                textTransform="none"
                                background={DARK_GRAY}
                                height="30px"
                                width="auto"
                                borderRadius="4px"
                                marginRight="10px"
                                // onClick={() => validateEmptyData()}
                                buttonStyle={{
                                    SUBHEADER1
                                }}></CustomButton>
                            <Select
                                sx={{ height: 30 }}
                                value={bulkAction}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                onChange={(event: any) => {
                                    setBulkAction(truncateText(event.target.value))
                                }}
                                placeholder={t("YC_SELECT_BULK_ACTION")}
                                size="small"
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <>{t("YC_SELECT_BULK_ACTION")}</>;
                                    }
                                    return selected;
                                }}
                            >
                                {bulkList.map((item: any) => {
                                    return (
                                        <MenuItem
                                            value={item}
                                        >
                                            {item}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Box>
                        )

                        }
                        {
                            isResourseAvailable("ua_set_user_role") && (
                                <Box sx={{ display: "flex", flexDirection: "row" }}>
                            <CustomButton placeHolder={t("YC_GRANT")}
                                textTransform="none"
                                background={DARK_GRAY}
                                height="30px"
                                width="auto"
                                borderRadius="4px"
                                marginRight="10px"
                                // onClick={() => validateEmptyData()}
                                buttonStyle={{
                                    SUBHEADER1
                                }}></CustomButton>
                            <Select
                                sx={{ height: 30 }}
                                value={userRole}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                onChange={(event: any) => {
                                    const selectedRole: any = roleNameList.filter((role: any) => (role === event.target.value))
                                    roleList.map((roleData: any) => {
                                        if (roleData.doc === selectedRole[0]) {
                                            setUserRole(roleData.doc)
                                            setUserRoleId(roleData.id)
                                        }
                                    })
                                }}
                                placeholder={t("YC_SET_USER_ROLE")}
                                size="small"
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <>{t("YC_SET_USER_ROLE")}</>;
                                    }
                                    return selected;
                                }}
                            >
                                {roleNameList.map((item: any) => {
                                    return (
                                        <MenuItem
                                            value={item}
                                        >
                                            {item}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Box>
                            )
                        }
                        {
                            isResourseAvailable("ua_give_access_to") && (
                                <Box sx={{ ml: 1, display: "flex", flexDirection: "row" }}>
                            <CustomButton placeHolder={t("YC_ADD")}
                                textTransform="none"
                                background={DARK_GRAY}
                                height="30px"
                                width="auto"
                                borderRadius="4px"
                                marginRight="10px"
                                // onClick={() => validateEmptyData()}
                                buttonStyle={{
                                    SUBHEADER1
                                }}></CustomButton>
                            <Select
                                sx={{
                                    color:
                                        controllerOfAccount === "Yes"
                                            ? GREEN
                                            : controllerOfAccount === "No"
                                                ? RED
                                                : "#00111",
                                    height: "30px"
                                }}
                                value={selectedApp}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                onChange={(event: any) => {
                                    const selectedApp: any = applicationNameList.filter((app: any) => (app === event.target.value))
                                    applicationList.map((appData: any) => {
                                        if (appData.application_name === selectedApp[0]) {
                                            setSelectedApp(appData.application_name)
                                        }
                                    })
                                }}
                                placeholder={t("YC_GIVE_ACCESS_TO")}
                                size="small"
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <>{t("YC_GIVE_ACCESS_TO")}</>;
                                    }
                                    return selected;
                                }}
                            >
                                {applicationNameList.map((item: any) => {
                                    return (
                                        <MenuItem
                                            value={item}
                                        >
                                            {item}
                                        </MenuItem>
                                    );
                                })}
                            </Select>

                        </Box>
                            )
                        }
                    </Box>

                </Box>
                <ManageRole sx={{ ml: 2 }}
                    key={isUserAvailable}
                    isUserAvailable={isUserAvailable}
                    roleList={roleList}
                    roleNameList={roleNameList}
                    resourceCodeList={resourceCodeList}
                    resourceDataList={resourceDataList}
                />
            </Box>
            <UserStatus></UserStatus>
            <UserNameTable
              key={[userList, isUserEdited, resourceCodeList]}
              userList={userList}
              setIsUserAvailable={setIsUserAvailable}
              setUserForEdit={setUserForEdit}
              applicationList={applicationList}
              roleList={roleList}
              resourceCodeList={resourceCodeList}
              resourceDataList={resourceDataList}
            />
        </Box>
    );
}

export default UserAccess;
