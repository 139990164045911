const truncate = (str: string, num: number) => {
  if (str.length > num) {
    return str.slice(0, num) + "...";
  } else {
    return str;
  }
};
const navigateTo = (route: any) => {
  window.location.href = route;
};
const genStr = () => {
  return (Math.random() + 1).toString(36).substring(2);
};

function hasLowerCase(str: any) {
  if (/[a-z]/.test(str)) {
    return true;
  } else {
    return false;
  }
}

function hasUpperCase(str: any) {
  if (/[A-Z]/.test(str)) {
    return true;
  } else {
    return false;
  }
}

function hasNumericCase(str: any) {
  if (/\d/.test(str)) {
    return true;
  } else {
    return false;
  }
}

function hasSpecialCase(str: any) {
  if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str)) {
    return true;
  } else {
    return false;
  }
}

const validateEmail = (email: string) => {
  const expression =
    /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  return expression.test(String(email).toLowerCase());
};

const validateMobilePhone = (str: any) => {
  return /^\d{2}\s\d{2}\s\d{2}\s\d{2}$/.test(str);
};

const validatePassword = (password: string): boolean => {
  return /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\d\s:])(?!.*\s).{8,}$/.test(password);
};

const validateFaxNumber = (str: any) => {
  return /^\d{3}\s\d{2}\s\d{2}$/.test(str);
};

function getStrength(value: any) {
  if (
    hasLowerCase(value) &&
    hasUpperCase(value) &&
    hasSpecialCase(value) &&
    hasNumericCase(value) &&
    value.length > 7
  ) {
    return 100;
  } else if (
    hasLowerCase(value) &&
    hasUpperCase(value) &&
    hasSpecialCase(value) &&
    value.length > 7
  ) {
    return 80;
  } else if (
    hasLowerCase(value) &&
    hasUpperCase(value) &&
    hasNumericCase(value) &&
    value.length > 7
  ) {
    return 80;
  } else if (hasUpperCase(value) && hasNumericCase(value)) {
    return 60;
  } else if (hasUpperCase(value) && hasSpecialCase(value)) {
    return 60;
  } else if (hasLowerCase(value) && hasNumericCase(value)) {
    return 60;
  } else if (hasLowerCase(value) && hasSpecialCase(value)) {
    return 60;
  } else if (hasSpecialCase(value) && hasNumericCase(value)) {
    return 60;
  } else if (hasLowerCase(value) && hasUpperCase(value)) {
    return 50;
  } else {
    return 40;
  }
}

export {
  truncate,
  navigateTo,
  genStr,
  getStrength,
  validateEmail,
  validateMobilePhone,
  validatePassword,
  validateFaxNumber
};
