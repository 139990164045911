import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import SignInPage from "views/signin/signinpage";
import { Modal } from "@mui/material";
import { BLACK, WHITE, YELLOW } from "utils/colors";
import { HEADER1, HEADER2, SUBHEADER1 } from "utils/fonts";
import CustomButton from "components/custom-button/custom-button";
// import {
//   YC_CONTROL,
//   YC_GET_STARTED_EX,
//   YC_LOGIN,
//   YC_MANAGE,
//   YC_PARTNERS,
//   YC_SERVICE,
//   YC_TITLE,
//   YC_WHY,
// } from "utils/strings";
import YCLICKLOGO from "../../assets/yclick-logo.png";
import { useTranslation } from "react-i18next";
import { LANGUAGES } from "../../constants/index";

// const paperStyle = {
//   padding: "20px 30px",
//   color: "#000",
//   background: "red",
// };

const useStyles = makeStyles(() => ({
  header: {
    paddingRight: "60px",
    paddingLeft: "60px",
    "@media (max-width: 1100px)": {
      paddingLeft: 0,
      paddingRight: 0,
    },
    height: 80,
    boxShadow: "0px 1.3px 0px #000",
    justifyContent: "center",
  },
  logo: {
    fontWeight: "bold",
    fontStyle: "normal",
    fontFamily: "sans-serif",
    fontSize: 50,
    textAlign: "left",
    marginTop: 5,
    cursor: "pointer",
  },
  menuButton: {
    fontWeight: "bold",
    fontSize: 19,
    height: 29,
    marginRight: 20,
    color: WHITE,
    marginTop: 8.5,
    textTransform: "capitalize",
    cursor: "pointer",
    fontStyle: "normal",
    "@media (max-width: 950px)": {
      marginRight: 3,
    },
    "&:hover": {
      color: BLACK,
      backgroundColor: YELLOW,
    },
  },
  button: {
    fontWeight: "bold",
    fontSize: 19,
    height: 29,
    color: WHITE,
    marginTop: 5,
    textTransform: "capitalize",
    fontStyle: "normal",
    alignSelf: "center",
    marginLeft: 60,
    "&:hover": {
      color: BLACK,
      backgroundColor: YELLOW,
    },
  },
  loginBlock: {
    "@media (max-width: 800px)": {
      display: "none",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  dividerStyle: {
    borderColor: WHITE,
    marginRight: 5,
    borderWidth: 1,
    opacity: 1,
    height: 30,
  },
  rightM: { marginRight: 60 },
}));

interface Props {
  window?: () => Window;
  isFrom?: String;
}

const drawerWidth = 240;


export default function HomepageHeader(props: Props) {
  const { header, logo, dividerStyle, menuButton, button, loginBlock, rightM } =
    useStyles();
  // const classes = useStylesNew();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isShowModal, setIsShowModal] = React.useState(false);

  const { i18n, t } = useTranslation();
  const navItems = [t("YC_CONTROL"), t("YC_SERVICE"), t("YC_PARTNERS"), t("YC_WHY")];
  
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  function handleOpen() {
    localStorage.clear();
    setIsShowModal(true);
  }

  function handleClose() {
    setIsShowModal(false);
  }

  const onChangeLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box>
        <img
          src={YCLICKLOGO}
          height={55}
          style={{ marginTop: "4px", cursor: "pointer" }}
          alt={"y click logo"}
        />
      </Box>
      {/* <Typography variant="h6" sx={{ my: 2 }} className={logo}>
        {YC_TITLE}
      </Typography> */}
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }} onClick={() => {
              if (item === t("YC_CONTROL")) {
                navigate("/control");
              } else if (item === t("YC_MANAGE")) {
                navigate("/manage");
              } else if (item === t("YC_SERVICE")) {
                navigate("/service");
              } else if (item === t("YC_PARTNERS")) {
                navigate("/partners");
              } else if (item === t("YC_WHY")) {
                navigate("/why");
              }
            }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        component="nav"
        className={header}
        sx={{
          backgroundColor:
            props.isFrom === "signup"
              ? "rgba(52, 52, 52, 1)"
              : "rgba(52, 52, 52, 0.8)",
        }}
      >
        <Toolbar sx={{ display: "flex" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: "flex" }} onClick={() => navigate("/")}>
            <img
              src={YCLICKLOGO}
              style={{ cursor: "pointer" }}
              height={55}
              alt={"y click logo"}
            />
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "flex", justifyContent: "flex-end" },
              flexGrow: 1,
            }}
            className={rightM}
          >
            {navItems.map((item) => (
              <Box key={item} sx={{ display: "flex", flexDirection: "row" }}>
                <Button
                  className={menuButton}
                  onClick={() => {
                    if (item === t("YC_CONTROL")) {
                      navigate("/control");
                    } else if (item === t("YC_MANAGE")) {
                      navigate("/manage");
                    } else if (item === t("YC_SERVICE")) {
                      navigate("/service");
                    } else if (item === t("YC_PARTNERS")) {
                      navigate("/partners");
                    } else if (item === t("YC_WHY")) {
                      navigate("/why");
                    }
                  }}
                  style={HEADER2}
                >
                  {item}
                </Button>
                {/* {item !== YC_WHY &&  */}
                <Divider
                  orientation="vertical"
                  variant="middle"
                  className={dividerStyle}
                />
                {/* } */}
              </Box>
            ))}
          </Box>
          <Box
            sx={{ display: { xs: "none", sm: "flex", width: "30%" } }}
            className={loginBlock}
          >
            <CustomButton
              placeHolder={t("YC_GET_STARTED_EX")}
              textTransform="none"
              background={YELLOW}
              titleColor={BLACK}
              height={50}
              width={"70%"}
              borderRadius={7.2}
              onClick={() => {
                navigate("/signup");
              }}
              hoverColor={WHITE}
              buttonStyle={{ backgroundColor: YELLOW, fontSize: 17 }}
            />
            <Button className={button} onClick={handleOpen}>
              {t("YC_LOGIN")}
            </Button>
            <Modal
              open={isShowModal}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div>
                <SignInPage />
              </div>
            </Modal>
          </Box>
          <select defaultValue={i18n.language} onChange={onChangeLang}>
        {LANGUAGES.map(({ code, label }) => (
          <option key={code} value={code}>
            {label}
          </option>
        ))}
      </select>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          anchor="left"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          // style={paperStyle}
          // className={classes.paperAnchorLeft}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
