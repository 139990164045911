import * as React from "react";
import { Box, CssBaseline, } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { YC_FOOTER_COPYRIGHT } from "utils/strings";
import HomepageFooter from "components/homepage-footer/homepage-footer";
import DashboardPageHeader from "components/dashboard-header/dashboard-header";
import SideNav from "components/reusable-sideNav/SideNav";
import { useLocation } from "react-router-dom";
import ApplicationBasicSetup from "./application-basic-setup";

const UseStyles = makeStyles(() => ({
  loginContainBlock: {
    flex: 1.6,
    "@media (max-width: 100px)": {
      display: "none",
    },
  },
}));

const drawerWidth = 250;

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

function ApplicationBasicSetupDash(props: Props) {
  const { loginContainBlock } = UseStyles();
  const {state} = useLocation();
  const { planData } = state

  return (
    <div style={{ height: "100%", flex: 1 }}>
      <Box
        sx={{
          marginBottom: "40px",
          height: "100%",
          flex: 1,
        }}
      >
        <CssBaseline />
        <DashboardPageHeader />

        <Box sx={{ display: "flex", flexDirection: "row" }}>

          <SideNav />

          <Box
            sx={{
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              marginTop: 8,
            }}
          >
            < ApplicationBasicSetup 
              planData = {planData}
             />
          </Box>
        </Box>
      </Box>
      <HomepageFooter isLinkFooter={true} displayText={YC_FOOTER_COPYRIGHT} />
    </div>
  );
}

export default ApplicationBasicSetupDash;
