import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { BLACK, WHITE, YELLOW } from "utils/colors";
import { makeStyles } from "@mui/styles";
import { countries } from "utils/countries";
import { useTranslation } from "react-i18next";

const UseStyles = makeStyles(() => ({
  root: {
    '& label.Mui-focused': {
      color: BLACK,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: YELLOW,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: WHITE,
      },
      '&:hover fieldset': {
        borderColor: BLACK,
      },
      '&.Mui-focused fieldset': {
        borderColor: YELLOW,
      },
    },
  },
}));

export default function CountrySelect(props: any) {
  const { root } = UseStyles();
  const {t} = useTranslation()
  return (
    <Autocomplete
      id="country-select-demo"
      sx={{
        width: props.width ?? "100%",
        // height: props.height ?? 55,
        background: WHITE,
        borderRadius: 1,
        borderWidth: 1,
      }}
      style={{...props.fieldStyle ?? null}}
      options={countries}
      // autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label} ({option.code}) +{option.phone}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          placeholder={t("YC_SELECT_COUNTRY_TERRITORY")}
          variant="outlined"
          hiddenLabel
          className={root}
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
          // sx={{background: WHITE}}
        />
      )}
    />
  );
}
