import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { BLACK } from "utils/colors";
import StepFooter from "../dashboard-reusables/step-footer";
import ControllerProcessFive from "./controller-process-step-five";
import ControllerProcessFour from "./controller-process-step-four";
import ControllerProcessOne from "./controller-process-step-one";
import ControllerProcessThree from "./controller-process-step-three";
import ControllerProcessTwo from "./controller-process-step-two";
import HomeApi from "services/HomeAPi";
import Notification from "views/Notifications/Notification";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  mainBoxView: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
    // justifyContent: "space-between",
    // alignItems: "center",
    // alignContent: "space-between",
    borderRadius: "7.2px",
    width: "97%",
    background: "#FFFFFF",
  },
  boxView: {
    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "space-between",
    borderRadius: "7.2px",
    width: "90%",
    minWidth: "30%",
  },
  titleText: {
    color: BLACK,
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 1,
  },
}));

function ControllerProcess({ goToNext, stepNumberController }: any) {
  const { mainBoxView } = useStyles();
  const { t } = useTranslation()
  const [stepNumber, setStepNumber] = React.useState(Number(stepNumberController));
  const [isUserController, setIsUserController] = React.useState("");
  const [isAnotherUser, setAnotherUser] = React.useState("")
  const [isAdditionalUser, setAdditionalUser] = React.useState("")
  const [additionalUserCount, setAdditionalUserCount] = React.useState(0);

  const nextStep = (isController: string, isAnotherUser: string, isAdditionalUser: string, isPreStepClicked: boolean) => {
    if (stepNumber === 1) {
      setIsUserController(isController)
      setStepNumber(2)
      goToNext(2)
    } else if (stepNumber === 2) {
      // if(additionalUserCount !== 0){
      //   Notification.notifyError(t("YC_EXCEED_ADDITIONAL_USER"))
      //   setStepNumber(5)
      //   goToNext(5)
      // } else {
      //   setStepNumber(3)
      //   goToNext(3)
      // }
      if (isPreStepClicked){
        setStepNumber(1)
        goToNext(1)
      } else {
        if(additionalUserCount >= 2){
        // Notification.notifyError(t("YC_EXCEED_ADDITIONAL_USER"))
        setStepNumber(5)
        goToNext(5)
      } else {
        setStepNumber(3)
        goToNext(3)
      }
        // setStepNumber(3)
        // goToNext(3)
      }
    } else if (stepNumber === 3) {
      if (isController === "business_setup"){
        goToNext(isController)
      } else {
        // if(additionalUserCount === 2){
        //   Notification.notifyError(t("YC_EXCEED_ADDITIONAL_USER"))
        //   setStepNumber(5)
        //   goToNext(5)
        // } else {
        //   setIsUserController(isController)
        //   setAnotherUser(isAnotherUser)
        //   setAdditionalUser(isAdditionalUser)
        //   setStepNumber(4)
        //   localStorage.setItem("additionalUserCountInStep3",`${isAdditionalUser}`)
        //   goToNext(4)
        // }
        if (isPreStepClicked){
          setStepNumber(2)
          goToNext(2)
        } else {
          if (Number(isController) === 5) {
            setStepNumber(5)
          } else {
            setIsUserController(isController)
            setAnotherUser(isAnotherUser)
            setAdditionalUser(isAdditionalUser)
            setStepNumber(4)
            localStorage.setItem("additionalUserCountInStep3",`${isAdditionalUser}`)
            goToNext(4)
          }
          
        }
        
      }
    } else if (stepNumber === 4) {
      if (isPreStepClicked){
        setStepNumber(3)
        goToNext(3)
      } else {
        setStepNumber(5)
      }
    } else if (stepNumber === 5) {
      if (isPreStepClicked){
        setStepNumber(3)
        goToNext(3)
      } else {
        if (isController === "business_setup"){
          goToNext(isController)
        }
      }
    }
  }
  
  useEffect(() => {
    setStepNumber(Number(stepNumberController))
  }, [stepNumberController])

  return (
    <Box className={mainBoxView}>
      {stepNumber === 1 && 
        <ControllerProcessOne
          goToNext={nextStep}
        />
      }
      {stepNumber === 2 && 
        <ControllerProcessTwo
          goToNext={nextStep}
          isUserController={isUserController}
          setStepNumber={setStepNumber}
          setAdditionalUserCount={setAdditionalUserCount}
          setIsUserController={setIsUserController}
        />
      }
      {stepNumber === 3 && 
        <ControllerProcessThree
          goToNext={nextStep}
          isUserController={isUserController}
          additionalUserCount={additionalUserCount}
        />
      }
      {stepNumber === 4 && 
        <ControllerProcessFour
          goToNext={nextStep}
          isUserController={isUserController}
          isAnotherUser={isAnotherUser}
          isAdditionalUser={isAdditionalUser}
          setStepNumber={setStepNumber}
          additionalUserCount={additionalUserCount}
        />
      }
      {stepNumber === 5 && 
        <ControllerProcessFive
          goToNext={nextStep}
          isUserController={isUserController}
          setStepNumber={setStepNumber}
        />
      }
      <StepFooter
        text={"Do you need assistance? "}
        link={"Click here to access Tutorials"}
      />
    </Box>
  );
}
export default ControllerProcess;
