import { Avatar, Box, Divider, InputLabel, Modal, SelectChangeEvent, Stack, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckBox from "components/check-box/check-box";
import CustomButton from "components/custom-button/custom-button";
import FileUpload from "components/file-uploader/image-uploader";
import PasswordField from "components/password-field/password-field";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import HomeApi from "services/HomeAPi";
import { BLACK, BLUE, DARKGREY, DARK_GRAY, GREY, RED, WHITE, YELLOW } from "utils/colors";
import { SUBHEADER1, SUBHEADERBOLD } from "utils/fonts";
import { validateEmail } from "utils/helper";
import Notification from "views/Notifications/Notification";

const EditUserDialogStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        borderRadius: "7.2px",
        marginTop: "10px",
        marginLeft: "20px",
        marginBottom: "10px",
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "90%",
        overflowY: 'auto',
        OverflowX: "none",
        maxWidth: 700,
        minWidth: 450,
        background: "white",
    },
    dividerStyleVerticle: {
        borderColor: GREY,
        borderWidth: 0.5,
        opacity: 1,
    },
    otpViewTitle: {
        fontSize: 16,
        fontWeight: "500",
        color: "#1F2328",
        lineHeight: "24px",
    },
    titleText: {
        display: "flex",
        marginTop: 20,
        marginBottom: 20,
        justifyContent: "center",
        alignItems: "center",
    },
    subTitle: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "28px",
        textAlign: "left",
        color: BLACK,
    }

}))
function EditUserDialog(props: any) {
    const { boxView, dividerStyleVerticle, otpViewTitle, titleText, subTitle } = EditUserDialogStyle();
    const { t } = useTranslation()

    const [controllerOfAccount, setControllerOfAccount] = useState("");
    const [profilePhoto, setProfilePhoto] = useState<any | null>();
    const [profileToUpload, setProfileToUpload] = useState("")
    const [isDisablePersonalOptionData, setIsDisablePersonalOptionData] = React.useState(true);
    const [isDisableContactInfo, setIsDisableContactInfo] = React.useState(true);
    const [isValidEmail, setIsValidEmail] = React.useState({value: true, error: ""})
    const [OTP, setOTP] = React.useState("");
    const [isOTPEmail, setOTPEmail] = React.useState(false);
    const [invalidOtp, setInvalidOtp] = React.useState(false);
    const [isOtpVerified, setOtpVerified] = React.useState(false);
    const [isShowModal, setIsShowModal] = React.useState(false);
    const [inputData, setInputData] = React.useState({
        userName: "",
        firstName: props.userForEdit?.name ? props.userForEdit.name : "",
        lastName: props.userForEdit?.lname ? props.userForEdit.lname : "",
        displayNameAs: "",
        description: props.userForEdit?.discription ? props.userForEdit.discription : ""
    });

    const [contactData, setContactData] = React.useState({
        displayMobileNo: props.userForEdit?.mobile ? props.userForEdit.mobile : "",
        mobile: "",
        code: "",
        email: props.userForEdit?.email ? props.userForEdit.email : "",
    });
    
    const [isPasswordNotMatch, setIsPasswordNotMatch] = React.useState(false)
    const [isDisableForAccountManage, setIsDisableForAccountManage] = React.useState(true)
    const [accountManageData, setAccountManageData] = React.useState({
        displayMobileNo: "",
        mobile: "",
        code: "",
        email: "",
        newPassword: "",
        retypePassword: ""
    });

    // const [accountManageData, setAccountManageData] = React.useState({
    //     userName: "",
    //     firstName: "",
    //     lastName: "",
    //     displayNameAs: "",
    //     description: ""
    // });
    
    const [isHover, setIsHover] = React.useState(false);

    const handleMouseEnter = () => {
        setIsHover(true);
    };
    
    const handleMouseLeave = () => {
        setIsHover(false);
    };

    function handleOpen() {
        setIsShowModal(true);
    }

    function handleClose() {
        setIsShowModal(false);
    }

    const handleChange = (event: SelectChangeEvent) => {
        setControllerOfAccount(event.target.value);
    }

    const getUserId = () => {
        let userId = props.userForEdit?.id;
        // const loginUserDetails = localStorage.getItem("user_Details")
        // if (loginUserDetails){
        //   const details = JSON.parse(loginUserDetails)
        //   userId = details.id
        // }
        return userId
    }

    const getUserProfilePhoto = async() => {
        const response = await HomeApi.getUserProfilePhoto(getUserId());
        if (response.photo_url !== "") {
            setProfilePhoto(response.photo_url);
        }
    }

    useEffect(() => {
        getUserProfilePhoto()
        props.setIsUserEdited(false)
    }, [])

    const isValid = () => {
        if (inputData.firstName !== "" && inputData.lastName !== "" ) {
            setIsDisablePersonalOptionData(false);
        } else {
            setIsDisablePersonalOptionData(true);
        }

        if (contactData.email !== "" && isValidEmail.value ) { // isValidEmail.value === true && contactData.mobile !== ""
            setIsDisableContactInfo(false);
        } else {
            setIsDisableContactInfo(true);
        }

      };

    function setProfilePhotoData(data: any) {
        setProfileToUpload(data.target.files[0])
        setProfilePhoto(URL.createObjectURL(data.target.files[0]));
    }

    useEffect(() => {
        isValid()
    }, [inputData.firstName, inputData.lastName, isValidEmail.value, contactData.mobile])

    const isCreatePasswordValid = () => {
        if (
          accountManageData.newPassword !== "" &&
          accountManageData.retypePassword !== "" &&
          accountManageData.newPassword !== accountManageData.retypePassword
        ) {
          setIsPasswordNotMatch(true);
        } else {
          setIsPasswordNotMatch(false);
        }
      };

      const isValidForAccountManage = () => {
        if (accountManageData.newPassword !== "" && accountManageData.retypePassword !== "" && (accountManageData.newPassword === accountManageData.retypePassword) && accountManageData.email ) {
          setIsDisableForAccountManage(false);
        } else {
            setIsDisableForAccountManage(true);
        }
      };
    
      useEffect(() => {
        isCreatePasswordValid()
        isValidForAccountManage()
      }, [accountManageData.newPassword, accountManageData.retypePassword, accountManageData.email])

    const uploadPhoto = async () => {
        let formData = new FormData();    //formdata object  //append the values with key, value pair
        formData.append('profile_photo', profileToUpload)
        
        const config = {     
            headers: { 'content-type': 'multipart/form-data' }
        }
        
        const response = await HomeApi.uploadUserProfilePhoto(formData, config, getUserId());
        if (response.status === true) {
            Notification.notifySuccess(response.message)
            props.setIsUserEdited(true)
        } else {
            Notification.notifyError(response.message)
        }
    };

    const uploadPersonalOptionData = async () => {

        const payload = {
            fname: inputData.firstName,
            lname: inputData.lastName,
            user_description: inputData.description
        };

        const response = await HomeApi.UploadPersonalOption(payload, getUserId());

        if (response.status === true) {
            Notification.notifySuccess(response.message)
            props.setIsUserEdited(true)
        } else {
            Notification.notifyError(response.message)
        }

    };

    const uploadContactInfo = async () => {
        const payload = {
            // mobile: contactData.mobile,
            email: contactData.email
        };

        const response = await HomeApi.UploadContactInfo(payload, getUserId());

        if (response.status === true) {
            Notification.notifySuccess(response.message)
            props.setIsUserEdited(true)
            clearContactInfo()
        } else {
            Notification.notifyError(response.message)
        }

    };

    const sendOtpPassword = async () => {
        const payload = {
            email: accountManageData.email
        };

        const response = await HomeApi.sendOtpPassword(payload);

        if (response.status === true) {
            Notification.notifySuccess(response.message)
            handleOpen()
        } else {
            Notification.notifyError(response.message)
        }

    };

    const verifyOtp = async(value: string, header: any) => {
        const payload = {
            email: accountManageData.email,
            otp: value,
            password: accountManageData.newPassword
        };

        const response = await HomeApi.verifyOTPPassword(payload);
        if (response.status === 200) {
            Notification.notifySuccess(response.message)
            setInvalidOtp(false);
            setOtpVerified(true);
            setOTP("")
            handleClose()
        } else {
            Notification.notifyError(response.message)
            setInvalidOtp(true);
            setOtpVerified(false);
        }
      }

    const emptyData = () => {
        setInputData({
            userName: "",
            firstName: props.userForEdit?.name ? props.userForEdit.name : "",
            lastName: props.userForEdit?.lname ? props.userForEdit.lname : "",
            displayNameAs: "",
            description: props.userForEdit?.discription ? props.userForEdit.discription : ""
        })
    }

    const clearContactInfo = () => {
       setContactData({
            displayMobileNo: props.userForEdit?.mobile ? props.userForEdit.mobile : "",
            mobile: "",
            code: "",
            email: props.userForEdit?.email ? props.userForEdit.email : "",
       })
       setIsDisableContactInfo(true);
    }

    const clearAccountManageData = () => {
        setAccountManageData({
            displayMobileNo: "",
            mobile: "",
            code: "",
            email: "",
            newPassword: "",
            retypePassword: ""
        })
     }

    const renderVerificationOTPView = () => {
        const topMessage = t("YC_SIGN_UP_TEXT_ONE").replace(
            "[email - phone]",
            `${isOTPEmail
              ? `[${accountManageData.email}]`
              : isOTPEmail
                ? `[${accountManageData.email}]`
                : `[${accountManageData.mobile}]`
            }`
          );
      return(
        <Box sx={{ 
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "7.2px",
            marginTop: "10px",
            marginLeft: "20px",
            marginBottom: "10px",
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "100%",
            height: {xs: "20%", sm:"40%", lg: "40%", xl: "40%" },
            overflowY: 'auto',
            OverflowX: "none",
            maxWidth: 600,
            minWidth: 450,
            background: "white",
        }}>
            <Box className={titleText}>
          <Typography variant="h6" fontSize={24}>
            {t("YC_VERIFICATION")}
          </Typography>
        </Box>
        <Box className={subTitle}>
          <Typography
            variant="subtitle1"
            sx={{ ml: 5, mr: 5, textAlign: "left" }}
            fontSize={16}
          >
            {topMessage}
          </Typography>
        </Box>
           <Divider
          orientation="vertical"
          variant="middle"
          sx={{ mr: 8, ml: 8, mt: 2, background: "white" }}
          flexItem
          className={dividerStyleVerticle}
        />
        <Typography sx={{ mt: 1 }} className={otpViewTitle}>
          {t("YC_OTP")}
        </Typography>
        <OtpInput
          value={OTP}
          isDisabled={isOtpVerified}
          onChange={(value: string, header: any) => {
            setOTP(value)
            if (value.length === 4) {
              verifyOtp(value, header)
            }
          }}
          numInputs={4}
          separator={<span> {"   "} </span>}
          containerStyle={{
            marginTop: 10,
            justifyContent: "space-evenly",
            width: "50%",
          }}
          inputStyle={{
            width: 40,
            height: 40,
            background: "rgba(215, 215, 215, 0.01)",
            border: "1px solid #0F5CD0",
            boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.07)",
            borderRadius: 5,
          }}
        />
        {invalidOtp &&
          <Typography color={RED}>{t("YC_INVALID_OTP")}</Typography>
        }
        <Divider
          orientation="vertical"
          variant="middle"
          sx={{ mr: 8, ml: 8, background: "rgba(0, 0, 0, 0.35)", mt: 3 }}
          flexItem
          className={dividerStyleVerticle}
        />
        </Box>
      )
    }

    return (
        <Box className={boxView}>
            <Typography style={{ fontStyle: "normal", fontWeight: 'bold', fontSize: 17, marginLeft: "30px", marginBottom: "3%", marginTop: "7%" }} gutterBottom>{t("YC_PROFILE")}</Typography>
            <Box sx={{
                borderRadius: "7.2px", margin: 2, padding: 2,
                boxShadow: 2,
                border: 2,
                display: "flex",
                flexDirection: "row",
                marginTop: -1,
                justifyContent: "space-between",
                borderColor: 'primary.light',
            }}>
                {/* Profile name and photo */}
                <Box sx={{
                    display: "block",
                    flexDirection: "column",
                    paddingBottom: "10px",
                    marginRight: 3
                }}>
                    <Typography style={{ fontStyle: "normal", fontWeight: 'bold', fontSize: 14 }} gutterBottom>{t("YC_PROFILE_PHOTO")}</Typography>
                    <Avatar variant="square" src={profilePhoto} sx={{ width: 80, height: 90 }} />
                    {/* <img src={file} /> */}
                </Box>

                {/* //choose media and update avtart */}
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "10px",
                    marginTop: "10px"
                }}>
                    {/* <div style={{height:"30px",
                        alignItems:"center",
                        marginRight:"10px",
                        marginLeft:"20px",
                        marginTop:"20px",
                        marginBottom:"20px",
                        width:"auto",
                        borderRadius:"4px"}}>
                        <input type="file" placeholder="" onChange={setProfilePhotoData} />
                    </div> */}
                    <div style={{height:"30px",
                        alignItems:"center",
                        marginRight:"10px",
                        marginLeft:"20px",
                        marginTop:"20px",
                        marginBottom:"20px",
                        width:"50%",
                        borderRadius:"4px"}}>
                        <label htmlFor="profileFile" style={{ background:BLACK,
                                    height: "32px",
                                    textAlign: "center",
                                    paddingTop: 5,
                                    color: isHover ? YELLOW :  WHITE,
                                    width: "100%",
                                    borderRadius: "4px", fontWeight: "bold", fontSize: 15 }} 
                                    onMouseEnter={handleMouseEnter} 
                                    onMouseLeave={handleMouseLeave}>
                        {t("YC_CHOOSE_MEDIA")}
                        </label>
                        <input id="profileFile" style={{visibility:"hidden"}} type={"file"} onChange={setProfilePhotoData}/>
                    </div>
                    {/* <CustomButton
                        placeHolder={t("YC_CHOOSE_MEDIA")}
                        textTransform="none"
                        background={BLACK}
                        height="30px"
                        alignItems="center"
                        marginRight="10px"
                        marginLeft="20px"
                        marginTop="20px"
                        marginBottom="20px"
                        width="auto"
                        borderRadius="4px"
                        variant="contained"
                        component="label"
                        // onClick={() => validateEmptyData()}
                        buttonStyle={{
                            SUBHEADER1
                        }}>

                        <input hidden accept="image/*" multiple type="file" />
                    </CustomButton> */}
                    <CustomButton
                        placeHolder={t("YC_UPDATE_AVATA")}
                        textTransform="none"
                        background={BLUE}
                        height="30px"
                        alignItems="center"
                        marginRight="10px"
                        marginLeft="20px"
                        width="50%"
                        borderRadius="4px"
                        onClick={() => uploadPhoto()}
                        buttonStyle={{
                            SUBHEADER1
                        }}>
                    </CustomButton>

                </Box>

            </Box>
            {/* divider line */}

            <hr
                style={{
                    color: BLACK,
                    backgroundColor: BLACK,
                    width: "100%",
                    marginTop: 7,
                    height: 2
                }}
            />
            {/* <Box> */}
            <Box sx={{
                borderRadius: "7.2px", margin: 2, padding: 2,
                boxShadow: 2,
                border: 2,
                display: "block",
                width: "95%",
                borderColor: 'primary.light',
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "10px"
                }}>
                    <Typography style={SUBHEADERBOLD} gutterBottom>{t("YC_PERSONAL_OPTIONS")}</Typography>

                </Box>

                <Stack direction="row" alignItems="start" spacing={6.5}>
                    <Typography variant="caption">{t("YC_USERNAME_REQUIRED")}</Typography>
                    <Stack direction="column" >
                    <Box sx={{ marginRight: "14%",
                            width: "155%", boxShadow: 4 }}>
                            <TextField style={{
                                fontStyle: "italic",
                                width: "100%"
                            }} variant="outlined"
                                size="small" 
                                value={inputData.userName}
                                onChange={(text) => {
                                    const trimStr = text.target.value.trimStart();
                                    setInputData((prevFields) => ({
                                        ...prevFields,
                                        userName: trimStr,
                                    }));
                                }}
                            />
                    </Box>
                        <label style={{ color: "red", fontStyle: "italic", marginTop: "10px", marginLeft: "10px", marginBottom: "20px" }}>{t("YC_USERNAMES_CAN_NOT_CHANGED")}</label>
                    </Stack>
                </Stack>

                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "10px"
                }}>
                    <Typography variant="caption">{t("YC_FIRST_NAME_REQUIRED")}</Typography>
                    <Box sx={{ marginRight: "14%",
                            width: "58%", boxShadow: 4 }}>
                            <TextField style={{
                                fontStyle: "italic",
                                width: "100%"
                            }}
                                variant="outlined" size="small" 
                                value={inputData.firstName}
                                onChange={(text) => {
                                    const trimStr = text.target.value.trimStart();
                                    setInputData((prevFields) => ({
                                        ...prevFields,
                                        firstName: trimStr,
                                    }));
                                }}
                            />
                    </Box>
                </Box>

                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "10px"
                }}>
                    <Typography variant="caption">{t("YC_LAST_NAME_REQUIRED")}</Typography>
                    <Box sx={{ marginRight: "14%",
                            width: "58%", boxShadow: 4 }}>
                            <TextField style={{
                                fontStyle: "italic",
                                width: "100%"
                            }}
                                variant="outlined" size="small" 
                                value={inputData.lastName}
                                onChange={(text) => {
                                    const trimStr = text.target.value.trimStart();
                                    setInputData((prevFields) => ({
                                        ...prevFields,
                                        lastName: trimStr,
                                    }));
                                }}
                            />
                    </Box>
                </Box>
                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "10px"
                }}>
                    <Typography variant="caption">{t("YC_DISPLAY_NAME_PUBLICLY_AS")}</Typography>
                    <Box sx={{ marginRight: "14%",
                            width: "58%", boxShadow: 4 }}>
                            <TextField style={{
                                fontStyle: "italic",
                                width: "100%"
                            }}
                                variant="outlined" size="small" 
                                value={inputData.displayNameAs}
                                onChange={(text) => {
                                    const trimStr = text.target.value.trimStart();
                                    setInputData((prevFields) => ({
                                        ...prevFields,
                                        displayNameAs: trimStr,
                                    }));
                                }}
                            />
                    </Box>
                </Box>

                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "10px"
                }}>
                    <Typography variant="caption">{t("YC_USER_DESCRIPTION")}</Typography>
                    <Box sx={{ marginRight: "14%",
                            width: "58%", boxShadow: 4 }}>
                        <TextField style={{
                            fontStyle: "italic",
                            width: "100%"
                        }}
                            multiline
                            rows={3}
                            variant="outlined" size="small"
                            value={inputData.description} 
                            onChange={(text) => {
                                const trimStr = text.target.value.trimStart();
                                setInputData((prevFields) => ({
                                    ...prevFields,
                                    description: trimStr,
                                }));
                            }}
                        />
                    </Box>
                </Box>




                <Box sx={{ float: "right" }}>
                    <CustomButton
                        disabled={isDisablePersonalOptionData}
                        placeHolder={t("YC_SET_NOW")}
                        textTransform="none"
                        background={isDisablePersonalOptionData ? DARK_GRAY : BLUE}
                        height="30px"
                        alignItems="center"
                        marginRight="10px"
                        marginLeft="20px"
                        width="auto"
                        borderRadius="4px"
                        onClick={() => uploadPersonalOptionData()}
                        buttonStyle={{
                            SUBHEADER1
                        }}>
                    </CustomButton>
                    <CustomButton
                        placeHolder={t("YC_CANCEL")}
                        textTransform="none"
                        background={DARKGREY}
                        height="30px"
                        alignItems="center"
                        marginRight="10px"
                        marginLeft="20px"
                        width="auto"
                        borderRadius="4px"
                        onClick={() => emptyData()}
                        buttonStyle={{
                            SUBHEADER1
                        }}>
                    </CustomButton>
                </Box>
            </Box>
            <hr
                style={{
                    color: BLACK,
                    backgroundColor: BLACK,
                    width: "100%",
                    marginTop: 7,
                    height: 2
                }}
            />
            <Box sx={{
                borderRadius: "7.2px", margin: 2, padding: 2,
                boxShadow: 2,
                border: 2,
                borderColor: 'primary.light',
            }}>
                <Box sx={{
                    display: "block",
                    flexDirection: "column",
                    paddingBottom: "10px"
                }}>
                    <Typography style={SUBHEADERBOLD} gutterBottom>{t("YC_CONTACT_INFO")}</Typography>

                </Box>

                <Stack direction="row" alignItems="start" spacing={7}>
                    <Typography variant="caption">{t("YC_EMAIL_REQUIRED")} </Typography>
                    <Stack direction="column" >
                        <Box sx={{ marginLeft: "0.5%",
                            width: "85%", boxShadow: 4 }}>
                            <TextField style={{
                                fontStyle: "italic",
                                width: "100%"
                            }} variant="outlined"
                                size="small"
                                value={contactData.email} 
                                    onChange={(text) => {
                                        const trimStr = text.target.value.trimStart();
                                        const validEmail = validateEmail(trimStr);
                                        setIsValidEmail({value: validEmail, error: !validEmail ? t("YC_EMAIL_VALID") : ""})
                                        setContactData((prevFields) => ({
                                            ...prevFields,
                                            email: trimStr,
                                        }));
                                    }}
                            />
                        </Box>
                        {(contactData.email !== "" && !isValidEmail.value) && (<div style = {{ color: "red" }}> {t("YC_EMAIL_VALID")} </div>)}
                        <label style={{ color: "red", fontStyle: "italic", marginTop: "10px", marginLeft: "10px", marginBottom: "20px" }}>
                            {t("YC_EDIT_USER_CONTACT_INFO_LABEL_1")}
                        </label>
                    </Stack>
                </Stack>


                <Stack direction="row" alignItems="start" spacing={7} >
                    <Typography variant="caption"> {t("YC_MOBILE_NO_REQUIRED")} </Typography>
                    <Stack direction="column" >
                        {/* <TextField style={{
                            fontStyle: "italic",
                            width: "85%",
                            marginLeft: "-4px"
                        }} variant="outlined"
                            size="small" 
                            type="number"
                            onChange={(text) => {
                                const trimStr = text.target.value.trimStart();
                                setMobileNumber(trimStr)
                            }}
                        /> */}
                        <Box sx={{ marginLeft: "0.5%",
                          width: "85%", boxShadow: 4 }}>
                            <Box sx={{ width: { xs: "70%", sm: "71%", lg: '71%', xl: '72%' }}}>
                                <PhoneInput
                                        country={"ma"}
                                        value={contactData.displayMobileNo}
                                        containerStyle={{marginLeft: 5, marginTop:3,  border:'none', height:40, background:'#F9F9FA'}}
                                        buttonStyle={{background:'#F9F9FA', border:'none'}}
                                        inputStyle={{border: 'none', width: "120%", height:40, background: '#F9F9FA', fontSize: 12, color: BLACK}}
                                        onChange={(phone: string, country: any) => {
                                        const reducedPhone = phone.replace(
                                            country.dialCode,'',);
                                            setContactData((prevFields) => ({
                                                ...prevFields,
                                                displayMobileNo: phone,
                                                mobile: reducedPhone,
                                                code: country.dialCode
                                            }))
                                        }} 
                                /> 
                            </Box>
                        </Box>
                        <label style={{ color: "red", fontStyle: "italic", marginTop: "10px", marginLeft: "10px", marginBottom: "20px" }}>
                        {t("YC_EDIT_USER_CONTACT_INFO_LABEL_MOBILE")}</label>
                    </Stack>
                </Stack>



                <Box sx={{ float: "right" }}>
                    <CustomButton
                        disabled={isDisableContactInfo}
                        placeHolder={t("YC_SET_NOW")}
                        textTransform="none"
                        background={isDisableContactInfo ? DARK_GRAY : BLUE}
                        height="30px"
                        alignItems="center"
                        marginRight="10px"
                        marginLeft="20px"
                        width="auto"
                        borderRadius="4px"
                        onClick={() => uploadContactInfo()}
                        buttonStyle={{
                            SUBHEADER1
                        }}>
                    </CustomButton>
                    <CustomButton
                        placeHolder={t("YC_CANCEL")}
                        textTransform="none"
                        background={DARKGREY}
                        height="30px"
                        alignItems="center"
                        marginRight="10px"
                        marginLeft="20px"
                        width="auto"
                        borderRadius="4px"
                        onClick={() => clearContactInfo()}
                        buttonStyle={{
                            SUBHEADER1
                        }}>
                    </CustomButton>
                </Box>
            </Box>
            {/* third dialog page */}
            <hr
                style={{
                    color: BLACK,
                    backgroundColor: BLACK,
                    width: "100%",
                    marginTop: 7,
                    height: 2
                }}
            />
            <Box sx={{
                borderRadius: "7.2px", margin: 2, padding: 2,
                boxShadow: 2,
                border: 2,
                width: "95%",
                display: "block",
                borderColor: 'primary.light',
            }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingBottom: "10px"
                }}>
                    <Typography style={SUBHEADERBOLD} gutterBottom>{t("YC_ACCOUNT_MANAGEMENT")}</Typography>

                </Box>
                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    paddingBottom: "10px"
                }}>
                      <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    paddingBottom: "10px"
                }}>
                    <Typography variant="caption">{t("YC_NEW_PASSWORD")}</Typography>
                    <Box sx={{marginRight: "-55px", width: "35%", marginTop: "-10px" }}>
                    <PasswordField
                    sx={{height: "100%"}}
                    label={t("YC_TYPE_NEW_PASSWORD")}
                    value={accountManageData.newPassword}
                    fieldStyle={{ marginTop: 10, fontStyle: "italic" }}
                    width={"100px"}
                    maxWidth={'100%'}
                    onChange={(text: any) => {
                        const trimStr = text.target.value.trimStart();
                        setAccountManageData((prevFields) => ({
                            ...prevFields,
                            newPassword: trimStr,
                        }))
                    }}
                  />
                          {/* <TextField style={{
                                fontStyle: "italic",
                                width: "100%"
                            }}
                              type="password"  id="mobile" label={t("YC_TYPE_NEW_PASSWORD")} variant="outlined" size="small" />  */}
                    </Box>
                    <Box sx={{width: "35%", marginTop: "-10px"}}>
                    <PasswordField
                    label={t("YC_RETYPE_PASSWORD")}
                    value={accountManageData.retypePassword}
                    fieldStyle={{ marginTop: 10, fontStyle: "italic" }}
                    width={"100px"}
                    maxWidth={'100%'}
                    onChange={(text: any) => {
                        const trimStr = text.target.value.trimStart();
                        setAccountManageData((prevFields) => ({
                            ...prevFields,
                            retypePassword: trimStr,
                        }))
                      isCreatePasswordValid();
                    }}
                  />
                           {/* <TextField style={{
                                fontStyle: "italic",
                                width: "100%"
                            }}
                            type="password" id="mobile" label={t("YC_RETYPE_PASSWORD")} variant="outlined" size="small" /> */}
                    </Box>
                </Box>
                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    paddingBottom: "10px"
                }}>
                {
                    isPasswordNotMatch && (
                      <InputLabel style={{ color: RED, textAlign: 'start', fontSize: 12, width: '70%' }}>{t("YC_PASSWORD_NOT_MATCH")}</InputLabel>
                    )
                  }
                </Box>
                </Box>
                
                <Stack direction="row" alignItems="baseline" spacing={9}>
                    <Typography variant="caption">{t("YC_SESSION")}</Typography>
                    <Stack direction="column" >
                        <CheckBox
                            sx={{ color: BLACK, marginLeft: "50px", "&.Mui-checked": { color: BLACK } }}
                            value="top"
                            label={t("YC_LOG_OUT_EVERYWHERE")}
                            labelPlacement="end"
                        />
                        <label style={{ color: "red", fontStyle: "italic", marginTop: "10px", marginLeft: "10px", marginBottom: "20px" }}>
                            {t("YC_ACCOUNT_SESSION_LOGOUT_TEXT")}</label>
                    </Stack>
                </Stack>
                <hr
                    style={{
                        color: BLACK,
                        backgroundColor: BLACK,
                        width: "80%",
                        alignContent: "center",
                        margin: "auto",
                        marginTop: 7,
                        height: 2
                    }}
                />
                <Typography style={{
                    fontStyle: "normal",
                    fontWeight: 'bold',
                    fontSize: 15,
                    marginTop: "20px",
                }} gutterBottom>{t("YC_TWO_TYPE_AUTHENTICATION")}</Typography>

                <Stack direction="row" spacing={5} sx={{ marginLeft: "25%" }}>
                    <label style={{ marginTop: "10px", marginBottom: "20px" }}>
                       {t("YC_ENABLE")} </label>
                    <label style={{ marginTop: "10px", marginBottom: "20px" }}>
                        {t("YC_CHANNEL")}</label>
                </Stack>
                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    paddingBottom: "10px"
                }}>
                    <Typography variant="caption" visibility={"hidden"}>Sommm</Typography>
                    <CheckBox
                        sx={{ color: BLACK, "&.Mui-checked": { color: BLACK }, ml: 1 }}
                        value="top"
                        labelPlacement="end"
                        checked={isOTPEmail}
                        onChange={(value: any) => {
                            setOTPEmail(value.target.checked);
                        }}
                    />
                    <label style={{marginTop:4}}>{t("YC_EMAIL")}</label>
                    <Stack direction="column" >
                    <Box sx={{width: "100%", boxShadow: 4,height: "40px" }}>
                           {/* <TextField style={{
                                fontStyle: "italic",
                                width: "100%",
                            }}
                                id="mobile" label="pm@themyp.com" variant="outlined" size="small" /> */}
                                <TextField style={{
                                        fontStyle: "italic",
                                        width: "100%"
                                    }} variant="outlined"
                                       label="pm@themyp.com"
                                        size="small"
                                         id="mobile"
                                        value={accountManageData.email} 
                                            onChange={(text) => {
                                                const trimStr = text.target.value.trimStart();
                                                const validEmail = validateEmail(trimStr);
                                                setIsValidEmail({value: validEmail, error: !validEmail ? t("YC_EMAIL_VALID") : ""})
                                                setAccountManageData((prevFields) => ({
                                                    ...prevFields,
                                                    email: trimStr,
                                                }));
                                            }}
                                    />
                                </Box>
                                {(accountManageData.email !== "" && !isValidEmail.value) && (<div style = {{ color: "red" }}> {t("YC_EMAIL_VALID")} </div>)}
                    {/* </Box> */}
                    </Stack>
                </Box>
                <Modal
                    open={isShowModal}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <div>
                        {renderVerificationOTPView()}
                    </div>
                </Modal>
                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    paddingBottom: "10px"
                }}>
                    <label style={{ color: "red", fontStyle: "italic", marginBottom: "20px" }}>
                        {t("YC_TIME_BASED_OTP")}:</label>
                </Box>
                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    paddingBottom: "10px"
                }}>
                    <Typography variant="caption" visibility={"hidden"}>Sommm</Typography>
                    <CheckBox
                        sx={{ color: BLACK, "&.Mui-checked": { color: BLACK }, ml: 2 }}
                        value="top"
                        labelPlacement="end"
                    />
                    <label style={{marginTop:4}}>{t("YC_MOBILE")}</label>
                    {/* <TextField label="+235512568" variant="outlined" size="small" /> */}
                    <Box sx={{width: "35%", boxShadow: 4 }}>
                        <PhoneInput
                            country={"ma"}
                            value={accountManageData.displayMobileNo}
                            containerStyle={{marginLeft: 5, marginTop:3,  border:'none', height:40, background:'#F9F9FA'}}
                            buttonStyle={{background:'#F9F9FA', border:'none'}}
                            inputStyle={{border: 'none', width: "95%", height:40, background: '#F9F9FA', fontSize: 12, color: BLACK}}
                            onChange={(phone: string, country: any) => {
                            const reducedPhone = phone.replace(
                                country.dialCode,'',);
                                setAccountManageData((prevFields) => ({
                                    ...prevFields,
                                    displayMobileNo: phone,
                                    mobile: reducedPhone,
                                    code: country.dialCode
                                }))
                            }} 
                        /> 
                    </Box>
                </Box>
                <Box sx={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "row",
                    justifyContent: "space-evenly",
                    paddingBottom: "10px"
                }}>
                    <label style={{ color: "red", fontStyle: "italic", marginBottom: "20px" }}>
                    {t("YC_TIME_BASED_OTP")}:</label>
                </Box>

                <Box sx={{ float: "right" }}>
                    <CustomButton
                        disabled={isDisableForAccountManage}
                        placeHolder={t("YC_SET_NOW")}
                        textTransform="none"
                        background={isDisableForAccountManage ? DARK_GRAY : BLUE}
                        height="30px"
                        alignItems="center"
                        marginRight="10px"
                        marginLeft="20px"
                        width="auto"
                        borderRadius="4px"
                        onClick={() => sendOtpPassword()}
                        buttonStyle={{
                            SUBHEADER1
                        }}>
                    </CustomButton>
                    <CustomButton
                        placeHolder={t("YC_CANCEL")}
                        textTransform="none"
                        background={DARKGREY}
                        height="30px"
                        alignItems="center"
                        marginRight="10px"
                        marginLeft="20px"
                        width="auto"
                        borderRadius="4px"
                        onClick={() => clearAccountManageData()}
                        buttonStyle={{
                            SUBHEADER1
                        }}>
                    </CustomButton>
                </Box>
            </Box>
        </Box>
    );
}

export default EditUserDialog;
