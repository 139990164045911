import { Button, Link } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { BLACK, LINK, WHITE } from "utils/colors";
import { SUBHEADER1 } from "utils/fonts";

const useStyles = makeStyles(() => ({
  footer: {
    flexDirection: "row",
    justifyContent: "space-between",
    position: "fixed",
    color: "#000000",
    // marginTop: '10%',
    width: "100%",
    background: BLACK,
    height: 50,
    bottom: 0,
    zIndex: 2999,
    alignItems: 'center',
    padding: 20,
    paddingLeft: 50,
    paddingRight: 50,
  },
  footerText: {
    lineHeight: "19px",
    textAlign: "center",
    color: WHITE,
    textTransform: "capitalize",
  },
}));

export default function NewHomepageFooter(props: any) {
  const { footer, footerText } = useStyles();
  return (
    <Box sx={{ display: "flex" }} className={footer}>
      <Box sx={{display: 'flex', flexDirection: 'row'}}>
        <Typography className={footerText} style={SUBHEADER1}>{props.displayText}</Typography>
        <Link sx={{ml: 1, color: LINK, textAlign: 'center', lineHeight: "19px", cursor: "pointer" }} style={SUBHEADER1}>
          MYP Online Marketing Ltd
        </Link>
      </Box>
      <Button className={footerText} style={SUBHEADER1}>Contact Us</Button>
    </Box>
  );
}
