import * as React from "react";
import { Box, CssBaseline, Link, ListItemIcon, TextField, Tooltip, Typography, } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DashboardPageHeader from "components/dashboard-header/dashboard-header";
import { SUBHEADER1, SUBHEADERBOLD } from "utils/fonts";
import { BLACK, BLUE, GREY, GREY_2, GREY_3, LINK, WHITE, YELLOW } from "utils/colors";
import CustomButton from "components/custom-button/custom-button";
import { useTranslation } from "react-i18next";
import SupportIcon from "../../../assets/helpIcon.png";
import galleryIcon from "../../../assets/galleryIcon.png";
import { useNavigate } from "react-router-dom";

const UseStyles = makeStyles(() => ({
    loginContainBlock: {
        flex: 1.6,
        "@media (max-width: 100px)": {
            display: "none",
        },
    },
}));

const drawerWidth = 250;

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

function ApplicationPlanActivate(props: any) {

    const { loginContainBlock } = UseStyles();
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [planData, setPlanData] = React.useState(props.planData)

    const renderPlanPricingView = () => {
        return (
            <Box
                sx={{
                    display: "flex",
                    height: "100%",
                    width: "100%", //{xs: "100", sm: "30%", lg: "30%", xl: "30%"},
                    border: 2,
                    borderColor: GREY,
                    boxShadow: 2,
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginTop: "30px"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: {xs: "100%", sm: "100%", lg: "30%", xl: "30%"},
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        marginLeft: "10px",
                        marginTop: "30px",
                    }}
                >
                    <Typography style={{ marginTop: "15px", fontSize: "16px" }}>
                        {t("YC_FEATURES_AND_TOOLS")} :
                    </Typography>
                    {planData.features.map((toolsData: any) => {
                         return(
                            <Box sx={{display: "flex", flexDirection: "column", marginLeft: 2, width: "90%",
                                      justifyContent: "flex-start", alignItems: "flex-start"
                                    }}
                            >
                                
                                <Box sx={{display: "flex", flexDirection: "row"}}>
                                    <img src={galleryIcon} height={15} alt={"icons"} style={{marginLeft: "-15px", marginTop: "5px"}}/>
                                    <Typography style={{ marginLeft: "5px", marginTop: "5px", fontSize: "12px", textDecoration: 'underline' }}>
                                        {toolsData.Feature_name}
                                    </Typography>
                                </Box>
                                {toolsData.tools.map((tools: any) => {
                                    return(
                                        <Box sx={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "flex-start"}}>
                                            <Typography style={{ marginLeft: "5px", marginTop: "8px", fontSize: "12px" }}>
                                              {tools.tool}
                                            </Typography>
                                            {tools.description === null ? (
                                                <Tooltip title={tools.description}>
                                                    <ListItemIcon sx={{marginTop:"5px", marginLeft: "10px"}}>
                                                    <img src={SupportIcon} height={15} alt={"icons"} />
                                                    </ListItemIcon>
                                                </Tooltip>
                                            ) : null}
                                        </Box>
                                    )
                                })}
                            </Box>
                         )
                    })}
                </Box>
            </Box>
        )
    }

    return (
        <div style={{ height: "100%", flex: 1 }}>
            <Box
                sx={{
                    marginBottom: "40px",
                    height: "100%",
                    flex: 1,
                }}
            >
                <CssBaseline />
                <DashboardPageHeader />

                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Box
                        sx={{
                            width: "100%",
                        }}
                    >
                        <Typography style={SUBHEADERBOLD}>
                            Mauritius Yellow Pages
                        </Typography>
                        <Typography>
                            Application Plan And Pricing
                        </Typography>
                        <Box sx={{width: "100%",display: "flex", justifyContent: "flex-start"}}>
                            <CustomButton
                                placeHolder={ t("YC_BACK")}
                                textTransform="none"
                                background={BLUE}
                                height="30px"
                                alignItems="center"
                                marginLeft="30px"
                                marginTop="10px"
                                width= "10%"
                                borderRadius="4px"
                                titl
                                border={BLACK}
                                onClick={() => {navigate(-1)}}
                                buttonStyle={{
                                    SUBHEADER1
                                }}
                            />
                        </Box>
                        <hr
                            style={{
                                color: BLACK,
                                backgroundColor: BLACK,
                                width: "95%",
                                marginTop: 10,
                                marginLeft: "2.5%",
                                height: 2
                            }}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: { xs: "column", sm: "row", lg: "row", xl: "row" },
                                width: "100%",
                                height: "100%",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    height: "100%",
                                    width: "95%", //{xs: "100", sm: "30%", lg: "30%", xl: "30%"},
                                    border: 2,
                                    borderColor: GREY,
                                    boxShadow: 2,
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                    marginLeft: "2.5%",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: { xs: "column", sm: "row", lg: "row", xl: "row" },
                                        width: "92%",
                                        marginLeft: "4%",
                                        marginTop: "10px",
                                        height: "20%",
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            height: "100%",
                                            width: {xs: "100%", sm: "100%", lg: "60%", xl: "60%"},
                                            border: 2,
                                            borderColor: GREY,
                                            boxShadow: 2,
                                            justifyContent: "flex-start",
                                            alignItems: "flex-start",
                                            marginRight: "20px",
                                            // marginLeft: "2.5%"
                                        }}
                                    >

                                        <Typography style={{ marginTop: "10px", marginLeft: "10px" }}>
                                            {"Yearly Subscription"}
                                        </Typography>
                                        <Typography style={{ marginTop: "10px", marginLeft: "10px", fontSize: "12px", color: BLACK }}>
                                            {"Intro text for the services."}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            width: {xs: "100%", sm: "100%", lg: "40%", xl: "40%"},
                                            marginTop: {xs: "10px", sm: "0px", lg: "0px", xl: "0px"}
                                        }}
                                    >
                                        <CustomButton
                                            placeHolder={t("YC_ACTIVATE")}
                                            textTransform="none"
                                            background={YELLOW}
                                            height="50px"
                                            alignItems="center"
                                            marginRight="10px"
                                            width="100%"
                                            borderRadius="4px"
                                            border={BLACK}
                                            titleColor={BLACK}
                                            hoverColor={WHITE}
                                            onClick={() => {
                                                navigate('/application-activate-setup', { state: { planData } })
                                            }}
                                            buttonStyle={{
                                                SUBHEADER1
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                height: "80%",
                                                width: "100%",
                                                border: 2,
                                                borderColor: GREY,
                                                boxShadow: 2,
                                                justifyContent: "flex-start",
                                                alignItems: "flex-start",
                                                marginTop: "10px"
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    width: "100%",
                                                }}
                                            >
                                                <Typography style={{ marginLeft: "20px", marginTop: "10px", fontSize: "18px" }}>
                                                    {"MUR"}
                                                </Typography>
                                                <Typography style={{ marginLeft: "20px", marginTop: "10px", fontSize: "18px" }}>
                                                    {planData.prize}
                                                </Typography>
                                                {/* <TextField variant="standard" 
                                                  sx={{ marginLeft: "15px", marginTop: "8px", width: "40%"}}
                                                  placeholder="0.00"
                                                >

                                                </TextField> */}
                                            </Box>
                                            <hr
                                                    style={{
                                                        color: BLACK,
                                                        backgroundColor: BLACK,
                                                        width: "30%",
                                                        marginTop: 1,
                                                        marginLeft: "15%",
                                                        height: 2
                                                    }}
                                                />
                                            <Typography style={{ marginLeft: "20px", marginTop: "15px", fontSize: "10px", color: GREY_2 }}>
                                                    {planData.plan_usage}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        height: "70%",
                                        width: "92%",
                                        marginLeft: "4%",
                                    }}
                                >                                    
                                    {renderPlanPricingView()
                                    }
                                    <hr
                                        style={{
                                            color: BLACK,
                                            backgroundColor: BLACK,
                                            width: "30%",
                                            marginTop: 50,
                                            marginLeft: "35%",
                                            height: 4
                                        }}
                                    />
                                    <Typography style={{ marginTop: "15px", fontSize: "12px" }}>
                                        {`${"+" + " " + planData?.plan === "Classic" ? t("YC_INTEGRATION_TEXT_CLASSIC") : t("YC_INTEGRATION_TEXT_PREMIUM") + " " + "-"}`}
                                        <Link sx={{ mx: 1, color: LINK, marginLeft: 1 }} variant="h6" fontSize={12}>
                                            {planData?.plan === "Classic" ? t("YC_LEARN_MORE") : planData?.plan === "Premium" ?
                                            `${t("YC_LEARN_MORE_ABOUT") + " " + t("YC_PREMIUM")}` : `${t("YC_LEARN_MORE_ABOUT") + " " + t("YC_CYBER")}`}
                                        </Link>
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <hr
                            style={{
                                color: BLACK,
                                backgroundColor: BLACK,
                                width: "95%",
                                marginTop: 50,
                                marginLeft: "2.5%",
                                height: 2
                            }}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "90%",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginLeft: "5%"
                            }}
                        >
                            <Typography style={{ marginTop: "15px", fontSize: "16px" }}>
                                {t("YC_BUSINESS_DIRECTORY_POWERED_BY")}
                            </Typography>
                            <Typography style={{ marginTop: "15px", fontSize: "12px" }}>
                                {`MYP (Mauritius Yellow Pages) are a support local businesses driven company...............`}
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: { xs: "100", sm: "60%", lg: "60%", xl: "60%" },
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                    marginBottom: 5
                                }}
                            >
                                <CustomButton
                                    placeHolder={t("YC_CONTACT_SALES")}
                                    textTransform="none"
                                    background={BLUE}
                                    height="35px"
                                    alignItems="center"
                                    marginRight="10px"
                                    marginTop="10px"
                                    width="25%"
                                    borderRadius="4px"
                                    titl
                                    border={BLACK}
                                    onClick={() => { }}
                                    buttonStyle={{
                                        SUBHEADER1
                                    }}
                                />
                                <CustomButton
                                    placeHolder={t("YC_LEARN_MORE")}
                                    textTransform="none"
                                    background={"transparent"}
                                    height="35px"
                                    alignItems="center"
                                    marginRight="10px"
                                    marginTop="10px"
                                    width="25%"
                                    borderRadius="4px"
                                    titleColor={BLACK}
                                    border={BLACK}
                                    onClick={() => { }}
                                    buttonStyle={{
                                        SUBHEADER1
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
            {/* <HomepageFooter isLinkFooter={true} displayText={YC_FOOTER_COPYRIGHT} /> */}
        </div>
    );
}

export default ApplicationPlanActivate;
