export const YC_TITLE = 'Y-CLICK';
export const YC_TITLE_CAM = 'Y-Click';

// TITLES
export const YC_GET_STARTED_EX = 'Get started for FREE!';
export const YC_LOGIN = 'Login';

//Forgot Password
export const YC_FORGOT_PASSWORD = 'Forgot Password';
export const YC_FP_TEXT_ONE = 'Do not worry. Resetting your password is easy, just tell us the email address or the mobile phone number you registered with Y-Click.';
export const YC_FP_TEXT_TWO = 'Note that you will received the reset link on the device as per recover data you sent to us.';
export const YC_FORGOT_PASSWORD_TEXT_1 = 'Please enter your new password.'
export const YC_FORGOT_PASSWORD_TEXT_2 = 'Password needs to have at least 8 characters and contain at least one of the following: uppercase letters, lowercase letters, numbers, and symbols'
export const YC_NEW_PASSWORD = 'New Password'
export const YC_POWERED_BY_Y_CLICK = 'Powered by Y-Click'
export const YC_YOUR_UNIVERSAL_ONLINE_PANEL = 'Your universal online panel'

// Helper Text
export const YC_HT_EMAIL_ADDRESS = 'Please Enter your email address';
export const YC_GET_STARTED_ACCOUNT_EX = 'Get Started with a Free Account!';
export const YC_PHONE_VALID = 'Phone Number is not valid';
export const YC_PASSWORD_VALID = 'Passwords is not valid';
export const YC_PASSWORD_NOT_MATCH = 'Passwords do not match';
export const YC_FAX_VALID = 'Fax Number is not valid';
export const YC_LANDLINE_VALID = 'Landline Number is not valid';

// Labels
export const YC_LABEL_EMAIL_PHONE = 'Email Address OR Mobile Phone Number';
export const YC_SEND = 'Send';
export const YC_IMAGE_VIDEO = 'Image/Video';
export const YC_EMAIL_MOBILE = 'Email Address/Mobile Phone Number';
export const YC_PASSWORD = 'Password';
export const YC_CONFIRM_PASSWORD = 'Confirm Password';
export const YC_SIGN_IN = 'Sign In';
export const YC_NO_ACCOUNT = 'Do Not Have An Account?';
export const YC_SIGN_UP_HERE_EX = 'Sign Up Here!';
export const YC_FIRST_NAME = 'First Name';
export const YC_LAST_NAME = 'Last Name';
export const YC_FIRST_NAME_VALID = 'First Name require';
export const YC_LAST_NAME_VALID = 'Last Name require';
export const YC_EMAIL_VALID = 'Email is not valid';
export const YC_EMAIL_ADDRESS = 'Email Address';
export const YC_MOBILE_PHONE = 'Mobile Phone Number';
export const YC_SEND_OTP_MY = 'Send my One Time Password on my:';
export const YC_EMAIL = 'Email';
export const YC_MOBILE = 'Mobile';
export const YC_VERIFY_ME = 'Verify Me';
export const YC_ALREADY_ACCOUNT = 'Already Have An Account?';
export const YC_LOGIN_HERE = 'Login Here!';
export const YC_VERIFICATION = 'Verification';
export const YC_SIGN_UP_TEXT_ONE = 'We have sent you a One Time Password to your [email - phone]. Kindly enter the four digits in below boxes to verify the connection and access your account.';
export const YC_WHICH_ACCOUNT_TYPE = 'Which type of account are you setting up:';
export const YC_BUSINESS = 'Business';
export const YC_PUBLIC = 'Public';
export const YC_GOVERNMENT = 'Government';
export const YC_OTP = 'One Time Password';
export const YC_TERMS_POLICY_TEXT = 'By ticking the box to continue; I confirm that I have read and agree to the:';
export const YC_TOC = 'Terms of Service';
export const YC_PP = 'Privacy Policy';
export const YC_TICK_CONTINUE = 'Tik to continuing,';
export const YC_ACTIVATE_MY_ACCOUNT = 'Activate My Account';
export const YC_RESEND_OTP_NOW_EX = 'Resent OTP Now!';
export const YC_COUNTRY_CODE = 'Country Code'
export const YC_MOBILE_NUMBER = 'Mobile Number'
export const YC_FIXED_LINE_NUMBER = 'Fixed Line Number'
export const YC_EMAIL_OF_CONTROLLER = 'Email of Controller'
export const YC_POSITION_OF_CONTROLLER = 'Position of Controller'

//BUTTONS NAME
export const YC_YES = 'Yes'
export const YC_NO = 'No'
export const YC_OK = 'Ok'
export const YC_SELECT = 'Select'

// Slide Button Titles
export const YC_SLIDE_RIGHT = 'Slide';
export const YC_SLIDE_LEFT = 'Slide';
export const YC_SLIDE_RIGHT_INFO = 'Slide right to unlock the activate button below';


// Link titles
export const YC_MYP_MARKETING = 'MYP Online Marketing Ltd';

// Header Nav Button titles
export const YC_MANAGE = 'Manage';
export const YC_SERVICE = 'Service';
export const YC_PARTNERS = 'Partners';
export const YC_WHY = 'Why';
export const YC_CONTROL = 'Control';

// Login page title
export const YC_TITLE_1 = 'The Simplest Way To Manage A  Business';
export const YC_TITLE_2 = 'And It Is Simply FREE!';

// Control page title
export const YC_CONTROL_TITLE_1 = 'Gain Control Over Your Online Business';
export const YC_CONTROL_TITLE_2 = '- Brand, Applications and Access';
export const YC_CONTROL_SUB_TITLE_1 = 'As the Controller, you hold the Master Password and have full access and control of admin, configurations, features and functions, operations, brand, accounting and payments.';
export const YC_CONTROL_SUB_TITLE_2 = 'You have the ability to delegate different user access levels (roles and capacities) to ensure the security of the panel and its functions. By using the Universal Admin Panel you will be able to streamline your day-to-day operations and create a secure, efficient, and sustainable work environment.';

// Login page sub title
export const YC_SUB_TITLE_1 = 'Y-Click is a powerful digital management platform with advanced marketing, sales and admin features and security at scale.';
export const YC_SUB_TITLE_2 = 'Y-Click for start-ups, entrepreneurs and organisations that need centralised visibility, control, and support it online business, branding and event it operation.';
export const YC_SUB_TITLE_3 = 'Y-Click provides free FOREVER universal admin panel, free services and trial for business applications and we constantly improve and innovate the platform and features.';

// Login footer text
export const YC_FOOTER_COPYRIGHT = '© Copyrights 2016 MYP Online Marketing Ltd';
export const YC_FOOTER_COPYRIGHT_ORIGINAL = '© Copyrights 2016';
export const YC_FOOTER_CONCEPTUALISED = 'Conceptualised by ';

// Sign Up Screen
export const YC_SIGNUP_SUB_TITLE = 'Take control of your online branding, sales, communication and more, all from a single dashboard...............';
export const YC_FOOTER_COPYRIGHT_2022 = 'Copyright © 2022. All right reserved.';

// Setup dashboard
export const YC_CONTROLLER_SETUP_MSG = 'Let us finalise your account setup as Controller';
export const YC_CONTROLLER_SETUP_STEP_3 = 'Let us setup additional user accounts'
export const YC_CONTROLLER_SETUP_STEP_5 = `Let us finalise user account setup - role and capacity`

export const YC_BUSINESS_SETUP_MSG = 'Let us finalise your business setup';
export const YC_YELLOW_PAGES_SETUP_MSG = 'Let us finalise your FREE Yellow Pages Listing';
export const YC_ACCOUNTING_SETUP_MSG = 'Let us finalise your payment solutions for online sales';

// Error messages
export const YC_INVALID_OTP = 'The OTP entered is incorrect. Please enter correct OTP.';
export const YC_CHECK_BOX = 'Please select the type of account you are setting up.';

// Wellcome page text

export const YC_THANK = 'Thank you for Choosing Y-Click!';
export const YC_THANK_SUPPORT_MSG = 'We are here to support The Local Businesses Community by connecting you with Digital Solutions and Customers ';
export const YC_YOU_READY_SETUP = 'You are ready to set up your Y-Click Account.';
export const YC_GETSTART_FREE = 'Get started for free'

// Common User page text

export const YC_MY_FRIENDS = 'My Friends';

// DashboardStatusAccordian  text

export const YC_STATUS_SHORTCUTS = 'Status And Shortcuts';
export const YC_TOOL_APPLICATION = 'Tools and Applications';
export const YC_ACCESS = 'Access';

// Customise Dashboard 

export const YC_CUSTOMISE_DASHBOARD = 'Customise Dashboard';

// Controller setup text
export const YC_ACCOUNT_TYPE_TITLE = 'Account Setup: Account Type'
export const YC_ACCOUNT_SETUP_CONTROLLER_TITLE = 'Account Setup: Controller'
export const YC_ACCOUNT_SETUP_ADMINISTRATOR_TITLE = 'Account Setup: Controller - Administrator'
export const YC_ACCOUNT_PASSWORD_TITLE = 'Account Setup: Password'
export const YC_WHICH_TYPE_ACCOUNT_SETUP = 'Which type of account to setup?'
export const YC_WILL_YOU_BE_CONTROLLER_OF_ACCOUNT = 'Will you be the Controller of this account?'
export const YC_CONTROLLER_PASSWORD = 'Controller Password'
export const YC_NON_CONTROLLER_PASSWORD = 'Non-Controller Password'
export const YC_THERE_ARE_TWO_TYPE_YC_ACCOUNTS = 'There are two types of Y-Click accounts:'
export const YC_CONTROLLER_SETUP_INFO_1 = '1. Public: Intended for private individuals not engaging in commercial activities or activities related to government, religious organizations, NGOs, or similar entities.'
export const YC_CONTROLLER_SETUP_INFO_2 = `2. Commercial: Designed for businesses, government bodies, authorities, NGOs, religious organizations, or similar entities. User access must be set up by a designated "Controller" who holds overall responsibilities.`
export const YC_CONTROLLER_SETUP_PUBLIC_INFO_1 = `As a member of the public, you can set up one account with one user access as the 'Controller.'`
export const YC_CONTROLLER_SETUP_PUBLIC_INFO_2 = 'Once set up, you can then opt in for a variety of web applications that provide various services.'
export const YC_CONTROLLER_SETUP_PUBLIC_INFO_RED_1 = 'As a member of the public, you can purchase products and services. However, trading capabilities are not available'
export const YC_CONTROLLER_SETUP_BUSINESS_INFO_1 = `As a business, you can set up one account with two additional user access levels in addition to the 'Controller' role.`
export const YC_CONTROLLER_SETUP_BUSINESS_INFO_2 = 'The option for additional user access is available, subject to certain conditions.'
export const YC_CONTROLLER_SETUP_BUSINESS_INFO_3 = 'Once setup is complete, you will have the option to access several services, some free of charge and others at a fee.'
export const YC_CONTROLLER_SETUP_BUSINESS_INFO_RED_1 = `If you will not be the controller, you will register with user role “Administrator”, and you will required to provide “Controller” details.`
export const YC_CONTROLLER_SETUP_BUSINESS_PASSWORD_INFO_1 = `"Please create a password for your account.`
export const YC_CONTROLLER_SETUP_BUSINESS_PASSWORD_INFO_2 = `An indicator will show the strength of your password, which should display as either 'Good' or 'Strong.' `
export const YC_CONTROLLER_SETUP_BUSINESS_PASSWORD_INFO_3 = `Please confirm your password."`
export const YC_CONTROLLER_SETUP_BUSINESS_NONCONTROLLER_PASSWORD_INFO_RED_1 = 'You will be setup as Administrator to complete the signup process.'
export const YC_CONTROLLER_SETUP_BUSINESS_NONCONTROLLER_PASSWORD_INFO_RED_2 = 'Until Controller approves your role as Administrator, you will only have General User Access.'

export const YC_CONTROLLER_SETUP_2_ADD_CONTROLLER_TITLE = 'Account Setup: Add Controller Details'
export const YC_CONTROLLER_SETUP_2_TITLE = 'Account Setup: Controller Details'
export const YC_USE_SAME_DETAILS = 'Use same details used in the sign-up'
export const YC_CONTROLLER_SETUP_2_INFO_1 = 'Please note that a Controller account must be created before access is granted.'
export const YC_CONTROLLER_SETUP_2_INFO_2 = 'Kindly verify and update your personal details to take control and gain full access.'
export const YC_NON_CONTROLLER_SETUP_2_INFO_1 = `Please note: A Controller account must be created before granting any access. 
      The Controller will receive an email to verify their identity, accept the Terms & Conditions, 
      and set an owner password before full access is granted.`
export const YC_NON_CONTROLLER_SETUP_2_INFO_AFTER_DATA_FIELD = `After clicking 'Next,' an email will be sent to the respective person. 
                They will be invited to take control of the business account, create their own password, 
                and accept the Terms & Conditions to gain access to Y-Click. 
                The Controller is required to approve you as the Administrator`
export const YC_DIRECTOR = 'Director'
export const YC_EXECUTIVE = 'Executive'
export const YC_MANAGER = 'Manager'

// Business setup text

export const YC_BUSINESS_SETUP_STEP_ONE_TITLE = 'Set Up Business: Legal Details'
export const YC_BUSINESS_SETUP_STEP_TWO_TITLE = 'Set Up Business: Legal Registered  Address'
export const YC_BUSINESS_SETUP_STEP_THREE_TITLE = 'Set Up Business: Communication Details'
export const YC_BUSINESS_SETUP_STEP_FOUR_TITLE = 'Set Up Business: Brand Details'
export const YC_COMPANY_NAME =  'Company Name'
export const YC_TRADE_NAME = 'Trade Name'
export const YC_LEGAL_STATUS_QUESTION = 'What are your legal status?'
export const YC_REGISTRATION_NUMBER = 'Registration Number'
export const YC_ARE_YOU_VAT_REGISTERED = 'Are you VAT registered'
export const YC_VAT_NUMBER = 'Vat Number'
export const YC_BUSINESS_SETUP_STEP_ONE_INFO_1 = `In the sign-up process you indicated that you are a business or Government. We like you to reconfirm by your legal status.
 If you are a Governmental entity or NGO/NPC the setup process will include additional steps.`
 export const YC_BUSINESS_SETUP_STEP_ONE_INFO_2 = 'You may have to submit documentation or download documents i.e. SLA (Service Level Agreement) to be signed and uploaded.'
 export const YC_BUSINESS_SETUP_STEP_ONE_INFO_RED_1 = 'If you are a Government entity or NGO/NPC, the setup process will include additional steps. You may be required to submit documentation or download documents such as the Service Level Agreement (SLA) to be signed and uploaded.'
 export const YC_BUSINESS_MODULE_QUESTION_BUSINESS_SETUP = 'What are your business model'
export const YC_COUNTRY = 'Country'
export const YC_REGION = 'Region'
export const YC_CITY_VILLAGE = 'City - Village'
export const YC_ADDRESS = 'Address'
export const UC_FIX_LINE_NUMBER = 'Fixed Line Number'
export const UC_CONTACT_EMAIL = 'Contact Email'
export const UC_BUSINESS_SETUP_STEP_THREE_INFO_1 = `Please provide your communication details. Include your business fixed phone number, mobile, fax email address, and links to your social media channels. This will ensure your customers can connect and communicate effectively with you.`

// Business setup 4
export const UC_BUSINESS_SETUP_STEP_FOUR_INFO_1 = `Please provide your brand details in form of uploading logo, a cover photo and entre brand colours. This will improve your branding through out Y-Click and third party applications that you may opt-in for i.e. Mauritius Yellow Pages.`
export const UC_BUSINESS_SETUP_STEP_FOUR_INFO_RED_1 = `Where a Colour code is not entered; Y-Click will apply default nutrual Black on White colour format for any business communication and documents.`
export const UC_UPLOAD_LOGO = 'Upload Logo'
export const UC_UPLOAD_COVER_PHOTO = 'Upload Cover Photo'
export const UC_ENTIRE_PRIME_COLOUR = 'Entre  Prime Colour Code'
export const UC_ENTIRE_SECONDARY_COLOUR = 'Entre  Secondary Colour Code'
export const UC_SET_CODE = 'Set Code'

 // Yellow Page setup text
  
export const YC_YELLOW_PAGE_STEP_ONE_TITLE = 'Setup Yellow Pages: Free Classic Listing'
export const YC_YELLOW_PAGE_STEP_TWO_TITLE = 'Setup Yellow Pages: Business Information'
export const YC_YELLOW_PAGE_STEP_THREE_TITLE = 'Setup Yellow Pages: Confirmation Publish'
export const YC_YELLOW_PAGE_STEP_FOUR_TITLE = 'Setup Yellow Pages: YP Listing Completed'
export const YC_BUSINESS_DESCRIPTION_QUESTION = 'Can you descript your business in a few words?'
export const YC_50_CHARACTER = 'of 50 characters'
export const YC_BUSINESS_NATURE_QUESTION = 'What is the nature of your business?'
export const YC_BUSINESS_MODULE_QUESTION = 'What is your business module (market)'
export const YC_STEP_INFO_ONE_YP_SETUP = `Any business have the option to create a Free Classic business listing on Mauritius Yellow Pages.`
export const YC_STEP_INFO_TWO_YP_SETUP = 'This listing will give the business more visibility on the World Wide Web.'
export const YC_STEP_INFO_THREE_YP_SETUP = 'Business have option to upgrade the listing once setup is completed. Refer to dashboard section Application.'
export const YC_STEP_INFO_FOUR_YP_SETUP = 'Government and NGO/NPC have additional benefits with Yellow Pages Free Listings:'
export const YC_STEP_INFO_FIVE_YP_SETUP = 'Premium Gov: are offered to government departments and authorities.'
export const YC_STEP_INFO_SIX_YP_SETUP = 'Premium NGO: offered to none trading NGOs, religious organisations, and embassies.'
export const YC_POPUP_CONTENT = `You are skipping an easy step to setup your Free Classic business page on Yellow Pages.`
export const YC_SKIP_STEP = 'Skip Step'
export const YC_SKIP_STEP_BUTTON = 'Skip Step >'
export const YC_NEXT_STEP = 'Next Step >'
export const YC_SELECT_CATEGORY = 'Select A Main Category'
export const YC_SELECT_SUB_CATEGORY = 'Select A Sub Category'
export const YC_SELECT_CHILD_CATEGORY = 'Select A Child Category'
export const YC_SELECT_MODEL = 'Select A Model'
export const YC_DESCRIPTION_MAX_50 = 'Description of max 50 characters'
export const YC_WE_WILL_HELP = 'We will help you'
export const YC_POPUP_QUESTION = 'Are you sure you want to?'
export const YC_WHAT_IS_SUB_CATEGORY = "What is sub category?"
export const YC_WHAT_IS_CHILD_CATEGORY = "What is child category?"
export const YC_SUCCESSFULL = "Successfull"
export const YC_MAIL_SENT_SUCCESSFULLY = "Successfully sent invitation mail to the mentioned email id. Please proceed for the next details"
export const YC_1_CATEGORY = 'of 1 Category'

export const YC_STEP_YP_SETUP_S2_INFO_1 = 'You are in the process of setting up a FREE Yellow Pages listing.'
export const YC_STEP_YP_SETUP_S2_INFO_2 ='In the ongoing process you are provide relevant information that will enable Yellow Pages to classify and promote your business  in the best possible way with an free listing.'
export const YC_STEP_YP_SETUP_S2_INFO_3 ='You will have the option to upgrade your listing at any time within the Apps and Tools section of Y-Click.'
export const YC_STEP_YP_SETUP_S2_INFO_RED_1 ='NGO, GOVERNMENT AND AUTHORITIES'
export const YC_STEP_YP_SETUP_S2_INFO_RED_2 ='There are no ability to upgrade this free listings.'
export const YC_STEP_YP_SETUP_S3_INFO_1 = 'You have the option to upgrade your business listing from the Free Classic listing to paid subscription based listing with additional exposure and tools for branding your business, capture potential customer and service them.'
export const YC_STEP_YP_SETUP_S3_INFO_RED_1 = 'If you select YES to opgrade, you will be directo to Opt-in section after completing the setup process. From this section you will be able to view the plans avalible.'
export const YC_UPGRADE_BUSINESS_LISTING = 'Upgrade Business Listing'

// Accounting setup
export const YC_ACCOUNTING_STEP_ONE_TITLE = 'Accounting Setup: Basic Accounting'
export const YC_ACCOUNTING_STEP_TWO_TITLE = 'Accounting Setup: Bank Details'
export const YC_ENTER_VAT_NUMBER = 'Enter Vat Number'
export const UC_PRICING_CURRENCY = 'Pricing Currency'
export const YC_STEP_ACCOUNTING_SETUP_INFO_1 = `Kindly complete the basic accounting setup. This will facilitate single entry structure of Y-Click when your company use any of the tools, functions or third party applications i.e. Yellow Pages Quotation tool.`
export const YC_ENTER_BANK_NAME = 'Enter Bank Name'
export const YC_ENTER_BANK_ADDRESS = 'Enter Bank Address'
export const YC_ENTER_BANK_ACCOUNT = 'Enter Bank Account'
export const YC_ENTER_SWIFT_CODE = 'Enter SWIFT code'
export const YC_ENTER_IBAN_CODE = 'Enter Iban Code'
export const YC_WIDER_TRANSFER_FEES_PAID = 'Wider transfer fees are paid by:'
export const YC_STEP_ACCOUNTING_SETUP_S2_INFO_1 = `Kindly complete the Bank details setup section. This will facilitate single entry structure of Y-Click when your company use any of the tools, functions or third party applications i.e. Yellow Markets (Online Marketplace) where the system issue invoice to shopper purchasing your products or services.`
export const YC_GIVE_INVOICE_TEMPLATE_TITLE = 'Give your invoice template a title'
export const YC_PAYMENT_TERMS = 'Payment Terms'
export const YC_PAYMANT_METHOD_AVAILABLE = 'Payments Methods Available'
export const YC_TERMS_CONDITION_LINK = 'Terms And Conditions Link'
export const YC_TC_UPLOADED = 'T&C Uploaded'
export const YC_DELIVERY_TERMS = 'Delivery Terms'
export const YC_STEP_ACCOUNTING_SETUP_S3_INFO_1 = `We create a invoice and quotation template for your company for which can be used by any tools or third-party application you may decided to use or opt-in for.`
export const YC_STEP_ACCOUNTING_SETUP_S3_INFO_2 = `The invoice and quotation can have your business terms and conditions linked or you can make use of standard template available within Y-Click´s structure or third party application.`
export const YC_STEP_ACCOUNTING_SETUP_S3_INFO_RED_1 = `Be aware that Y-Click cannot be hold responsable if a business decided to make use of any business or accounting templates availible. Each business have own responsability to reivew and approve before use.`
export const YC_ACCOUNTING_SETUP_POP_UP_TEXT = `You are skipping an easy step to setup your business accounting for clients to payment.
You can also apply for online merchant with local or international payment solution providers`