import { Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { BLACK, WHITE, YELLOW } from "utils/colors";

const UseStyles = makeStyles(() => ({
  getStartedButton: {
    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
  },
}));

function CustomButton(props: any) {
  const { getStartedButton } = UseStyles();
  const [isHover, setIsHover] = React.useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <Button
      disabled={props.disabled ?? false}
      style={{
        textTransform: props.textTransform ?? "none",
        width: props.width ?? 300,
        height: props.height ?? 60,
        background: props.background ?? BLACK,
        borderRadius: props.borderRadius ?? "7.2px",
        color: isHover ? props.hoverColor ? props.hoverColor : YELLOW : props.titleColor ? props.titleColor : WHITE,
        marginRight: props.marginRight ?? 0,
        marginLeft: props.marginLeft ?? 0,
        marginBottom: props.marginBottom ?? 0,
        marginTop: props.marginTop ?? 0,
        lineHeight: 'normal',
        ...props?.buttonStyle ?? null
      }}
      className={getStartedButton}
      component="label"
      sx={{ color: props.color }}
      variant="contained"
      startIcon={props.startIcon ?? ""}
      endIcon={props.endIcon ?? ""}
      onClick={() => {
        if(props.onClick) {
          props.onClick();
        }
      }}
      onTouchStart={props.onTouchStart}
      onTouchMove={props.onTouchMove}
      onTouchEnd={props.onTouchEnd}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {props.placeHolder}
    </Button>
  );
}

export default CustomButton;
