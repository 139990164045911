import { Box, Button, Divider, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { BLACK, DARKGREY, WHITE, YELLOW } from "utils/colors";


const useStyles = makeStyles(() => ({
  boxView: {
    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
    background: "#FFFFFF",
    borderRadius: "8px",
    "@media (max-width: 800px)": {
      width: "410px",
    }
  },
  titleText: {
    fontSize: 20,
    fontWeight: "700",
  },
  yellowText: {
    fontSize: 16,
    fontWeight: "400",
    justifyContent: "center",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    textOverflow: "ellipsis",
    height: "60px",
    overflow: "hidden",
    padding: "10px",
  },
  yellowImageBox: {
    overflowX: "auto",
    display: "flex",
    justifyContent: 'center'
  },
  yellowPageBox: {
    margin: "24px !important",
    minWidth: '187px',
    borderRadius: '8px',
    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
    "@media (max-width: 800px)": {
      margin: "6px !important",
    }
  },
  yellowPageImg: {
    height: '80px',
    width: '80px',
    borderRadius: '40px',
    border: '1px solid #000000',
    margin: "auto",
    backgroundColor: DARKGREY,
  },
  button: {
    '&:hover': {
      color: BLACK,
      backgroundColor: YELLOW,
    },
  }
}));

function DashboardMyApplication(props: any) {
  const { boxView, titleText, yellowPageBox, yellowText, yellowImageBox, yellowPageImg, button } = useStyles();
  const { t } = useTranslation()

  return (
    <Box className={boxView}>
      <Typography variant="subtitle1" sx={{ height: "15px" }}></Typography>
      <Typography className={titleText}> {t("YC_MY_APPLICATIONS")} </Typography>
      <Box className={yellowImageBox + " flex-container p-2 appliImagescroll"}>
        {all.map((Element) => (
          <Box className={yellowPageBox + " col-2 m-0 p-0 "} >
            <Typography className={yellowText + " py-2"}> {Element} </Typography>
            <Box className={yellowPageImg}>

            </Box>
            <Button variant="contained" className={button + " my-2"} > {t("YC_OPEN_THIS_APP")}</Button>
          </Box>
        ))}
      </Box >
    </Box >
  );
}
export default DashboardMyApplication;
const all = [
  "Yellow Pages",
  // "Yellow Markets",
  // "YC Community",
  // "e-Market",
  // "Customer Relation Management",
  // "Accounting",
  // "Payment Solution",
  // "Human Resources",
  // "e-Learning",
  // "Appointments",
  // "Advertising",
  // "Marketing",
  // "Search Engine Optimisation",
  // "Social Media Marketing",
  // "Project Manager",
  // "Kirol",
  // "Help Desk",
  // "Events",
]