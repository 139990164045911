import { Avatar, Box, Divider, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, Stack, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CheckBox from "components/check-box/check-box";
import CustomButton from "components/custom-button/custom-button";
import CustomTitleSelect from "components/custom-title-select/custom-title-select";
import FileUpload from "components/file-uploader/image-uploader";
import PasswordField from "components/password-field/password-field";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import OtpInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import HomeApi from "services/HomeAPi";
import { BLACK, BLUE, DARKGREY, DARK_GRAY, GREY, RED, WHITE, YELLOW } from "utils/colors";
import { HEADER2, SUBHEADER1, SUBHEADERBOLD, SUBHEADERBOLD2 } from "utils/fonts";
import { validateEmail, validateMobilePhone } from "utils/helper";
import Notification from "views/Notifications/Notification";

const EditUserDialogStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        borderRadius: "7.2px",
        marginTop: "10px",
        marginLeft: "20px",
        marginBottom: "10px",
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "90%",
        overflowY: 'auto',
        OverflowX: "none",
        maxWidth: 700,
        minWidth: 450,
        background: "white",
    },
    dividerStyleVerticle: {
        borderColor: GREY,
        borderWidth: 0.5,
        opacity: 1,
    },
    otpViewTitle: {
        fontSize: 16,
        fontWeight: "500",
        color: "#1F2328",
        lineHeight: "24px",
    },
    titleText: {
        display: "flex",
        marginTop: 20,
        marginBottom: 20,
        justifyContent: "center",
        alignItems: "center",
    },
    subTitle: {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "28px",
        textAlign: "left",
        color: BLACK,
    }

}))
function General(props: any) {
    const { boxView, dividerStyleVerticle, otpViewTitle, titleText, subTitle } = EditUserDialogStyle();
    const { t } = useTranslation()
    const [isDisableContactInfo, setIsDisableContactInfo] = React.useState(true);
    const [isValidEmail, setIsValidEmail] = React.useState({ value: true, error: "" })
    const [isValidMobileNumber, setIsValidMobileNumber] = React.useState({ value: true, error: "" })
    const [OTP, setOTP] = React.useState("");

    const [countryList, setCountryList] = React.useState([])
    const [countryName, setCountryName] = React.useState([]);
   
    const [stateList, setStateList] = React.useState([])
    const [stateName, setStateName] = React.useState([]);

    const [countryId, setCountryId] = React.useState(0)
    const [stateId, setStateId] = React.useState(0)

    const [inputData, setInputData] = React.useState({
        companyName: "",
        contactEmail: "",
        brn: "",
        mobileNumber: "",
        vatNumber: "",
        fax: "",
        streetAddress: "",
        city: "",
        zipCode: "",
        state: "",
        country: ""
    });

    const getUserId = () => {
        let userId
        const loginUserDetails = localStorage.getItem("user_Details")
        if (loginUserDetails) {
            const details = JSON.parse(loginUserDetails)
            userId = details.id
        }
        return userId
    }

    useEffect(() => {
        getCountryList()
    }, [])

    const isValid = () => {

        if (inputData.contactEmail !== "" && isValidEmail.value) { // isValidEmail.value === true && contactData.mobile !== ""
            setIsDisableContactInfo(false);
        } else {
            setIsDisableContactInfo(true);
        }

    };

    useEffect(() => {
        isValid()
    }, [isValidEmail.value, inputData.mobileNumber])

    const getCountryList = async () => {
        const response = await HomeApi.getCountryList();
        if (response.status === true) {
          setCountryList(response.data)
          let tempArray: any = [];
          response.data.map((element: any) => {
            tempArray.push(element.name)
          });
          setCountryName(tempArray)
        }
      }
  
      const regionData = async (countryCode: string) => {
        const response = await HomeApi.getRegionData(countryCode);
        if (response.status === true) {
          setStateList(response.data)
          let tempArray: any = [];
          response.data.map((element: any) => {
            tempArray.push(element.name)
          });
          setStateName(tempArray)
          setStateList(response.data)
        } else {
          Notification.notifyError(response.message)
        }
      }

      const getCompanyDetails = async () => {
        const response = await HomeApi.getCompanyDetails(getUserId())
        if (response.status === true) {
          const data = response.data[0]
          setInputData({
            companyName: data.companyName,
            contactEmail: data.email,
            brn: data.BRN,
            mobileNumber: data.phone,
            vatNumber: data.VAT,
            fax: data.fax_phone_number,
            streetAddress: data.address,
            city: data.cities,
            zipCode: data.postal_code,
            state: data.region,
            country: data.country
        })
        }
    }

      const postCompanyDetails = async () => {
        const payload = {
          companyName : inputData.companyName,
          BRN : inputData.brn,
          email : inputData.contactEmail,
          phone : inputData.mobileNumber,
          fax_phone_number : inputData.fax,
          address: inputData.streetAddress,
          cities: inputData.city,
          postal_code: inputData.zipCode,
          region: inputData.state,
          country: inputData.country
        }
        
        const response = await HomeApi.postCompanyDetails(payload, getUserId())
        if (response.status === true) {
            Notification.notifySuccess(response.message)
         } else {
              Notification.notifyError(response.message)
         }
      }

      useEffect(() => {
        getCompanyDetails()
      }, [])

    const textFieldView = (key: string, value: string) => {
        return (
            <Box sx={{boxShadow: 2}}>
                <TextField style={{
                    width: "100%",
                }} label={""} variant="outlined"
                    size="small"
                    value={value}
                    onChange={(text) => {
                        const trimStr = text.target.value.trimStart();
                        if (key === "companyName") {
                            setInputData((prevFields) => ({
                                ...prevFields,
                                companyName: trimStr,
                            }));
                        } else if (key === "contactEmail") {
                            const validEmail = validateEmail(trimStr);
                            setIsValidEmail({ value: validEmail, error: !validEmail ? t("YC_EMAIL_VALID") : "" })
                            setInputData((prevFields) => ({
                                ...prevFields,
                                contactEmail: trimStr,
                            }));
                        } else if (key === "brn") {
                            setInputData((prevFields) => ({
                                ...prevFields,
                                brn: trimStr,
                            }));
                        } else if (key === "mobileNumber") {
                            const mob = text.target.value.replace(/(\d{2})(\d{2})(\d{2})(\d{2})/, "$1 $2 $3 $4");
                            const isValidNumber = validateMobilePhone(mob);
                            setIsValidMobileNumber({ value: isValidNumber, error: !isValidNumber ? t("YC_PHONE_VALID") : "" })
                            setInputData((prevFields) => ({
                                ...prevFields,
                                mobileNumber: trimStr,
                            }));
                        } else if (key === "vat") {
                            setInputData((prevFields) => ({
                                ...prevFields,
                                vatNumber: trimStr,
                            }));
                        } else if (key === "fax") {
                            setInputData((prevFields) => ({
                                ...prevFields,
                                fax: trimStr,
                            }));
                        } else if (key === "streetAddress") {
                            setInputData((prevFields) => ({
                                ...prevFields,
                                streetAddress: trimStr,
                            }));
                        } else if (key === "city") {
                            setInputData((prevFields) => ({
                                ...prevFields,
                                city: trimStr,
                            }));
                        }
                    }}
                />
                {(key === "contactEmail" && inputData.contactEmail !== "" && !isValidEmail.value) && (<div style={{ color: "red" }}> {t("YC_EMAIL_VALID")} </div>)}
                {(key === "mobileNumber" && inputData.mobileNumber !== "" && !isValidMobileNumber.value) && (<div style={{ color: "red" }}> {t("YC_PHONE_VALID")} </div>)}
            </Box>
        )
    }

    const renderLabelAndTextView = (key: string, label:string, value: string) => {
        return(
            <Box sx={{width: "100%", display: "flex", flexDirection: "column"}}>
                <Typography sx={{pl:2, mt: 2, mb:1, width: "100%", textAlign: "start" }}>
                    {label}
                </Typography>
                {textFieldView(key, value)}
            </Box>
        )
    }

    const renderLabelAndDropDownView = (key: string, itemsList: any, label:string, value: string) => {
        return(
            <Box sx={{width: "100%", display: "flex", flexDirection: "column"}}>
                <Typography sx={{pl:2, mt: 2, mb:1, width: "100%", textAlign: "start" }}>
                    {label}
                </Typography>
                <Box sx={{width: "100%", height: 40, boxShadow: 4 }}>
                            <Select
                                sx={{ height: 40, width: "100%", textAlign: "start" }}
                                value={value}
                                displayEmpty
                                inputProps={{ "aria-label": "Without label" }}
                                onChange={(event: any) => {
                                    if (key === "country") {
                                        countryList.map((country: any) => {
                                            if (country.name === event.target.value){
                                              regionData(country.id)
                                              setCountryId(country.id)
                                            }})
                                          setInputData((prevFields) => ({
                                            ...prevFields,
                                            country: event.target.value,
                                          }))
                                      } else if (key === "state") {
                                        stateList.map((region: any) => {
                                          if (region.name
                                            === event.target.value){
                                            setStateId(region.id)
                                          }})
                                      setInputData((prevFields) => ({
                                          ...prevFields,
                                          state: event.target.value,
                                      }))
                                      }
                                }}
                            >
                                {itemsList.map((item: any) => {
                                    return (
                                        <MenuItem
                                            value={item}
                                        >
                                            {item}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Box>
            </Box>
        )
    }


    return (
        <Box sx={{ border: "1px solid #CCD0D4", mt: 1.5, borderColor: GREY, boxShadow: 2 }}>
            <Box sx={{ width: "90%", marginLeft: "5%" }}>
                <Typography sx={{ mt: 2, width: "100%", textAlign: "start", fontStyle: SUBHEADERBOLD2 }}>
                    {t("YC_COMPANY_DETAILS")}
                </Typography>
                <Box sx={{ mt: 1, mb: 2, width: "100%", height: 1.5, background: BLACK }} />
                <Typography sx={{ mt: 2, width: "100%", textAlign: "start" }}>
                    {t("YC_COMPANY_DETAILS_TEXT")}
                </Typography>
                <Box sx={{width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                   <Box sx={{width: "45%"}}>
                      {renderLabelAndTextView("companyName", t("YC_COMPANY_NAME"), inputData.companyName)}
                   </Box>
                   <Box sx={{width: "45%"}}>
                      {renderLabelAndTextView("contactEmail", t("YC_CONTACT_EMAIL"), inputData.contactEmail)}
                   </Box>
                </Box>
                <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                   <Box sx={{width: "45%"}}>
                      {renderLabelAndTextView("brn", "BRN (Business Registration Number)", inputData.brn)}
                   </Box>
                   <Box sx={{width: "45%"}}>
                      {renderLabelAndTextView("mobileNumber", t("YC_PHONE_1"), inputData.mobileNumber)}
                   </Box>
                </Box>
                <Box sx={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                   <Box sx={{width: "45%"}}>
                      {renderLabelAndTextView("vat", "VAT (Value Added Tax)", inputData.vatNumber)}
                   </Box>
                   <Box sx={{width: "45%"}}>
                      {renderLabelAndTextView("fax", t("YC_FAX"), inputData.fax)}
                   </Box>
                </Box>
                <Box sx={{mb:2, width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                   <Box sx={{width: "45%"}}>
                      {renderLabelAndTextView("streetAddress", t("YC_STREET_ADDRESS"), inputData.streetAddress)}
                   </Box>
                   <Box sx={{width: "45%"}}>
                      {renderLabelAndTextView("city", t("YC_CITY"), inputData.city)}
                   </Box>
                </Box>
                <Box sx={{mb:2, width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                   <Box sx={{width: "30%"}}>
                      {renderLabelAndTextView("zipCode", t("YC_ZIP_CODE"), inputData.zipCode)}
                   </Box>
                   <Box sx={{width: "30%"}}>
                      {renderLabelAndDropDownView("state", stateName, t("YC_STATE"), inputData.state)}
                   </Box>
                   <Box sx={{width: "30%"}}>
                      {renderLabelAndDropDownView("country", countryName, t("YC_COUNTRY"), inputData.country)}
                   </Box>
                </Box>
            </Box>
            <Box sx={{mt:4, ml: 2, mb: 2, display: "flex", width: "95%", justifyContent: "flex-end" }}>
          <CustomButton
            placeHolder={t("YC_SAVE")}
            textTransform="none"
            background={BLUE}
            height="40px"
            width={100}
            // borderRadius="7.2px"
            buttonStyle={{
              HEADER2
            }}
            onClick={() => {postCompanyDetails()}}
          />
        </Box>
        </Box>
    );
}

export default General;
