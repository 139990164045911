import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
} from "@mui/material";
import { Box } from "@mui/system";
import { GREEN } from "utils/colors";
import { DoneAll } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import SuccessIcon from "../../assets/success.png";

const useStyles = makeStyles(() => ({
  root: {
    textAlign: "start",
    "& .MuiSelect-iconOutlined": {
      marginRight: 37,
    }
  },
  selectStyle: {
    textAlign: "start",
  }
}));

function CustomSelect(props: any) {
  const { root, selectStyle } = useStyles();

  const renderIcon = (iconName: any) => {
    return (
        <img
          // style={{opacity: 0.4}}
          src={iconName}
          height={22}
          alt={"icon"}
        />
    );
  };

  return (
    <Box
      sx={{mt: 1}}
      style={{
        ...props.selectStyle ?? null
      }}
    >
      <FormControl sx={{ minWidth: props.minWidth }}>
        <InputLabel style={{ fontWeight: "400", color: "rgba(0, 0, 0, 0.6)", fontSize: 16 }}>
          {props.label}
        </InputLabel>
        <Select className={props.isShowTick && props.value ? root : selectStyle} value={props.value} onChange={props.onChange} label={props.label} endAdornment={
              props.isShowTick && props.value &&
              <InputAdornment position="end">
                {/* <DoneAll sx={{ color: GREEN }} /> */} 
                {renderIcon(SuccessIcon)}
              </InputAdornment>
            }>
          {props.items.map((item: any) => {
            return <MenuItem value={item}>{item}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </Box>
  );
}

export default CustomSelect;
