import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TodoModel, TodoArrayModel } from "../../models/redux-models";

const initialToDoState: TodoArrayModel = {
  all_todos: [],
  particular_todo: {
    userId: 0,
    Id: 0,
    title: "",
    completed: false,
  },
};
const todoSlice = createSlice({
  name: "todos",
  initialState: initialToDoState,
  reducers: {
    setTodos(state, action: PayloadAction<TodoModel[]>) {
      state.all_todos = action.payload;
    },
    setParticularTodos(state, action: PayloadAction<TodoModel>) {
      state.particular_todo = action.payload;
    },
  },
});

export default todoSlice;
