import * as React from "react";
import { Box, CssBaseline, Link, ListItemIcon, Tooltip, Typography, } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { YC_FOOTER_COPYRIGHT } from "utils/strings";
import HomepageFooter from "components/homepage-footer/homepage-footer";
import DashboardPageHeader from "components/dashboard-header/dashboard-header";
import SideNav from "components/reusable-sideNav/SideNav";
import UserAccess from "../user-access/user-access";
import { SUBHEADER1, SUBHEADERBOLD } from "utils/fonts";
import { BLACK, BLUE, GREY, GREY_2, GREY_3, LINK, PINK, WHITE, YELLOW } from "utils/colors";
import CustomButton from "components/custom-button/custom-button";
import { useTranslation } from "react-i18next";
import HomeApi from "services/HomeAPi";
import SupportIcon from "../../../assets/helpIcon.png";
import galleryIcon from "../../../assets/galleryIcon.png";
import ApplicationPlanActivate from "./application-plan-activate";
import { useNavigate } from "react-router-dom";

const UseStyles = makeStyles(() => ({
    loginContainBlock: {
        flex: 1.6,
        "@media (max-width: 100px)": {
            display: "none",
        },
    },
}));

const drawerWidth = 250;

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

function ApplicationPlanPricing(props: any) {
    const { loginContainBlock } = UseStyles();
    const { t } = useTranslation()
    const navigate = useNavigate()
    const [applicationId, setApplicationId] = React.useState(props.applicationId)
    const [applicationPlanList, setApplicationPlanList] = React.useState([])
    const [isActiveClick, setIsActiveClick] = React.useState(false)
    const [selectedPlan, setSelectedPlan] = React.useState([])

    React.useEffect(() => {
        getApplicationPlan()
    }, [])

    const getApplicationPlan = async() => {
       const response = await HomeApi.getApplicationPlan(applicationId)
       if (response.status === true) {
         setApplicationPlanList(response.data)
       }
    }

    const renderPlanPricingView = (key: string, title: string, introText: string,
        pricing: string,
        subscriptionType: string,
        subscriptionType_2: string,
        featureTools: any,
        integration: string,
        link: string,
        planData: any) => {
        return (
            <Box
                sx={{
                    display: "flex",
                    width: {xs: "95%", sm: "95%", lg: "30%", xl: "30%"},
                    border: 2,
                    borderColor: GREY,
                    boxShadow: 2,
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginLeft: "2%",
                    marginTop: key === "Premium" ? "10px" : "30px"
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: "90%",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        marginLeft: "5%"
                    }}
                >
                    <Typography style={{ marginTop: "10px", fontWeight: key === "Premium" ? 700 : 400 }}>
                        {title}
                    </Typography>
                    <Typography style={{ marginTop: "10px", fontSize: "12px", color: GREY_2 }}>
                        {introText}
                    </Typography>
                    <Typography style={{ marginTop: "10px" }}>
                        {pricing}
                    </Typography>
                    <Typography style={{ marginTop: "10px", fontSize: "10px", color: GREY_2 }}>
                        {subscriptionType}
                    </Typography>
                    <Typography style={{ marginTop: "2px", fontSize: "10px", color: GREY_2 }}>
                        {subscriptionType_2}
                    </Typography>
                    <CustomButton
                        placeHolder={t("YC_SUBSCRIBE_ACTIVATE")}
                        textTransform="none"
                        background={key === "Premium" ? PINK : YELLOW}
                        height="30px"
                        alignItems="center"
                        marginRight="10px"
                        marginTop="10px"
                        width="95%"
                        borderRadius="4px"
                        border={BLACK}
                        titleColor={key === "Premium" ? WHITE : BLACK}
                        hoverColor={key === "Premium" ? YELLOW : WHITE}
                        onClick={() => {
                            setSelectedPlan(planData)
                            navigate('/application-activate', { state: { planData } })
                            setIsActiveClick(true)}}
                        buttonStyle={{
                            SUBHEADER1
                        }}/>
                    <hr
                        style={{
                            color: BLACK,
                            backgroundColor: BLACK,
                            width: "20%",
                            marginTop: 10,
                            marginLeft: "40%",
                            height: 2
                        }}
                    />
                    <Typography style={{ marginTop: "15px", fontSize: "16px" }}>
                        {t("YC_FEATURES_AND_TOOLS")} :
                    </Typography>
                    {featureTools.map((toolsData: any) => {
                         return(
                            <Box sx={{display: "flex", flexDirection: "column", marginLeft: 2, width: "90%",
                                      justifyContent: "flex-start", alignItems: "flex-start"
                                    }}
                            >
                                
                                <Box sx={{display: "flex", flexDirection: "row"}}>
                                    <img src={galleryIcon} height={15} alt={"icons"} style={{marginLeft: "-15px", marginTop: "5px"}}/>
                                    <Typography style={{ marginLeft: "5px", marginTop: "5px", fontSize: "12px", textDecoration: 'underline' }}>
                                        {toolsData.Feature_name}
                                    </Typography>
                                </Box>
                                {toolsData.tools.map((tools: any) => {
                                    return(
                                        <Box sx={{display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", alignItems: "flex-start"}}>
                                            <Typography style={{ marginLeft: "5px", marginTop: "8px", fontSize: "12px" }}>
                                              {tools.tool}
                                            </Typography>
                                            {tools.description !== null ? (
                                                <Tooltip title={tools.description}>
                                                    <ListItemIcon sx={{marginTop:"5px", marginLeft: "10px"}}>
                                                    <img src={SupportIcon} height={15} alt={"icons"} />
                                                    </ListItemIcon>
                                                </Tooltip>
                                            ) : null}
                                        </Box>
                                    )
                                })}
                            </Box>
                         )
                    })}
                    <hr
                        style={{
                            color: BLACK,
                            backgroundColor: BLACK,
                            width: "60%",
                            marginTop: 50,
                            marginLeft: "20%",
                            height: 2
                        }}
                    />
                    <Typography style={{ marginTop: "15px", marginBottom: "15px", fontSize: "12px" }}>
                        {`${ "+" + " " + integration + " " + "-"}`}
                        <Link sx={{ mx: 1, color: LINK, marginLeft: 1 }} variant="h6" fontSize={12}>
                            {link}
                        </Link>
                    </Typography>
                </Box>
            </Box>
        )
    }

    return (
        <div style={{ height: "100%", flex: 1 }}>
            <Box
                sx={{
                    marginBottom: "40px",
                    height: "100%",
                    flex: 1,
                }}
            >
                <CssBaseline />
                <DashboardPageHeader />

                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Box
                        sx={{
                            width: "100%",
                        }}
                    >
                        <Typography style={SUBHEADERBOLD}>
                            Mauritius Yellow Pages
                        </Typography>
                        <Typography>
                            Application Plan And Pricing
                        </Typography>
                        <Box sx={{width: "100%",display: "flex", justifyContent: "flex-start"}}>
                            <CustomButton
                                placeHolder={ t("YC_BACK")}
                                textTransform="none"
                                background={BLUE}
                                height="30px"
                                alignItems="center"
                                marginLeft="30px"
                                marginTop="10px"
                                width= "10%"
                                borderRadius="4px"
                                titl
                                border={BLACK}
                                onClick={() => {navigate(-1)}}
                                buttonStyle={{
                                    SUBHEADER1
                                }}
                            />
                        </Box>
                        <hr
                            style={{
                                color: BLACK,
                                backgroundColor: BLACK,
                                width: "95%",
                                marginTop: 10,
                                marginLeft: "2.5%",
                                height: 2
                            }}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: {xs: "column", sm : "column", lg: "row", xl: "row"},
                                width: "100%",
                            }}
                        >
                            
                            {applicationPlanList.map((planData: any) => {
                                return(
                                    renderPlanPricingView(planData.plan, planData.plan, planData.plan_description, planData.prize, 
                                        planData.plan_usage, "", planData.features, 
                                        planData.plan === "Classic" ? t("YC_INTEGRATION_TEXT_CLASSIC") : t("YC_INTEGRATION_TEXT_PREMIUM"),
                                        planData.plan === "Classic" ? t("YC_LEARN_MORE") : planData.plan === "Premium" ?  
                                        `${t("YC_LEARN_MORE_ABOUT")+ " "+ t("YC_PREMIUM")}` : `${t("YC_LEARN_MORE_ABOUT")+ " "+ t("YC_CYBER")}`, planData)
                                )
                            })}
                            {
                                applicationPlanList.length === 0 && (
                                    <Typography sx={{width: "100%", textAlign: "center"}}>No Data available</Typography>
                                )
                            }
                            {/* {renderPlanPricingView("Classic", t("YC_CLASSIC"), "Intro text for Classic business page", "0.00", 
                                "Free forever", "", classicFeatureTools, t("YC_INTEGRATION_TEXT_CLASSIC"), t("YC_LEARN_MORE"))}
                            {renderPlanPricingView("Premium", t("YC_PREMIUM"), "Intro text for Classic business page", "2,000.00",
                                "Per user, per month, billed annually", "MUR 200.00 billed monthly", premiumFeatureTools, t("YC_INTEGRATION_TEXT_PREMIUM"),
                                `${t("YC_LEARN_MORE_ABOUT")+ " "+ t("YC_PREMIUM")}`)}
                            {renderPlanPricingView("Cyber", t("YC_CYBER"), "Intro text for Classic business page", "3,600.00",
                            "Per user, per month, billed annually", "MUR 350.00 billed monthly", premiumFeatureTools, t("YC_INTEGRATION_TEXT_PREMIUM"),
                            `${t("YC_LEARN_MORE_ABOUT")+ " "+ t("YC_CYBER")}`)} */}
                        </Box>
                        <hr
                            style={{
                                color: BLACK,
                                backgroundColor: BLACK,
                                width: "95%",
                                marginTop: 50,
                                marginLeft: "2.5%",
                                height: 2
                            }}
                        />
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                width: "90%",
                                justifyContent: "flex-start",
                                alignItems: "flex-start",
                                marginLeft: "5%"
                            }}
                        >
                            <Typography style={{ marginTop: "15px", fontSize: "16px" }}>
                               {t("YC_BUSINESS_DIRECTORY_POWERED_BY")}
                            </Typography>
                            <Typography style={{ marginTop: "15px", fontSize: "12px" }}>
                               {`MYP (Mauritius Yellow Pages) are a support local businesses driven company...............`}
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: {xs: "100", sm: "60%", lg: "60%", xl: "60%"},
                                    justifyContent: "flex-start",
                                    alignItems: "flex-start",
                                    marginBottom: 5
                                }}
                            >
                                <CustomButton
                                    placeHolder={t("YC_CONTACT_SALES")}
                                    textTransform="none"
                                    background={BLUE}
                                    height="35px"
                                    alignItems="center"
                                    marginRight="10px"
                                    marginTop="10px"
                                    width= "25%"
                                    borderRadius="4px"
                                    titl
                                    border={BLACK}
                                    onClick={() => {}}
                                    buttonStyle={{
                                        SUBHEADER1
                                    }}
                                />
                                <CustomButton
                                    placeHolder={t("YC_LEARN_MORE")}
                                    textTransform="none"
                                    background={"transparent"}
                                    height="35px"
                                    alignItems="center"
                                    marginRight="10px"
                                    marginTop="10px"
                                    width="25%"
                                    borderRadius="4px"
                                    titleColor={BLACK}
                                    border={BLACK}
                                    onClick={() => {}}
                                    buttonStyle={{
                                        SUBHEADER1
                                    }}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </div>
    );
}

export default ApplicationPlanPricing;
