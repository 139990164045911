import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";

const useStyles = makeStyles(() => ({
  boxView: {
    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.25)",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    flexDirection: "column",
    textAlign: "left",
    border: "1px solid #000000",
    height: "fit-content",
  },
  subTitleStyle: {
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px'
  }

}));

function StepInformation(props: any) {
  const { boxView, subTitleStyle } = useStyles();
  return (
    <Box
      className={boxView}
      sx={{
        width: props.width ?? "100%",
        minWidth: props.minWidth ?? "52%",
        maxWidth: props.maxWidth ?? "40%",
        maxHeight: props.maxHeight ?? null,
        display: { xs: "none", sm: "flex", lg: "flex", xl: "flex" },
        background: props.bgColor,
        p: 2,
      }}
      style={{ ...(props?.boxStyle ?? null) }}
    >
      {props.title && (
        <Typography
          sx={{ color: props.textColor, mb: 2, textAlign: props.textAlign ?? "start", width:'100%' }}
          className={subTitleStyle}
        >
          {props.title}
        </Typography>
      )}
      {props.textOne && (
        <Typography
          sx={{
            color: props.textColor,
            textAlign: props.textAlign ?? "start",
            width:'100%'
          }}
          className={subTitleStyle}
        >
          {props.textOne}
        </Typography>
      )}
      {props.textTwo && (
        <Typography
          sx={{ mt: 2, color: props.textColor, textAlign: "start", width:'100%' }}
          className={subTitleStyle}
        >
          {props.textTwo}
        </Typography>
      )}
      {props.textThree && (
        <Typography
        sx={{ color: props.textColor, mt: 2, textAlign: "start", width:'100%' }}
          className={subTitleStyle}
        >
          {props.textThree}
        </Typography>
      )}
      {props.textFour && (
        <Typography
        sx={{ color: props.textColor, mt: 2, textAlign: "start", width:'100%' }}
          className={subTitleStyle}
        >
          {props.textFour}
        </Typography>
      )}
      {props.textFive && (
        <Typography
        sx={{ color: props.textColor, mt: 2, textAlign: "start", width:'100%' }}
          className={subTitleStyle}
        >
          {props.textFive}
        </Typography>
      )}
      {props.textSix && (
        <Typography
        sx={{ color: props.textColor, mt: 2, textAlign: "start", width:'100%' }}
          className={subTitleStyle}
        >
          {props.textSix}
        </Typography>
      )}
    </Box>
  );
}

export default StepInformation;
