import HomepageHeader from "../../../components/homepage-header/homepage-header";
import { Box, Button, Link, Modal, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  YC_FOOTER_COPYRIGHT_ORIGINAL,
  YC_GET_STARTED_EX,
} from "utils/strings";
import CustomButton from "components/custom-button/custom-button";
import { useNavigate } from "react-router-dom";
import { BLACK, GREY, WHITE, YELLOW } from "utils/colors";
import BACKGROUNDIMAGE from "../../../assets/y-click-partners-2.jpg";
import USERIMAGE from "../../../assets/application-presentation.png";
import NewHomepageFooter from "components/homepage-footer/new-homepage-footer";
import { BODY } from "utils/fonts";

const UseStyles = makeStyles(() => ({
  leftContainer: {
    display: "flex",
    width: "50%",
    height: "100%",
    minHeight: '500px',
    maxHeight: '790px',
    minWidth: '400px',
    // "@media (max-width: 320px)": {
    //   width: "100%",
    //   marginBottom: 10,
    // },
    // "@media (min-width: 480)": {
    //   width: "100%",
    //   marginBottom: 10,
    // },
  },
  leftContainerBoxView: {
    width: "100%",
    // minWidth: "450px",
    height: "100%",
    background: BLACK,
    borderWidth: 5,
    borderStyle: "solid",
    borderColor: YELLOW,
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rightContainer: {
    display: "flex",
    width: "64%",
    height: "100%",
    // "@media (min-width: 320px)": {
    //   width: "100%",
    // },
    "@media (min-width: 480px)": {
      width: "100%",
    },
  },
  loginContainBlock: {
    backgroundColor: "rgba(0, 0, 0, 0.4)",
    borderRadius: 10,
    width: "100%",
    maxHeight: '92%',
    // minWidth: "450px",
    // maxHeight: '95%'
    // height: "100%",
    // "@media (min-width : 320px)": {
    //   minWidth: "100%",
    // },
    // "@media (min-width: 480)": {
    //   minWidth: "100%",
    // },
  },
  subtitleView: {
    marginTop: 10,
    marginRight: 30,
    marginLeft: 30,
  },
  subTitle: {
    lineHeight: "28px",
    textAlign: "left",
    letterSpacing: "0em",
    color: WHITE,
  },
  boxButtonPicker: {
    display: "flex",
    height: "25%",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "space-between",
    marginRight: 40,
    marginTop: 10,
  },
  titleView: {
    flexDirection: "column",
    justifyContent: "space-between",
  },
  titleStyle: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 45,
    lineHeight: "60px",
    textAlign: "center",
    color: WHITE,
  },
  mainTitle: {
    textAlign: "left",
    color: YELLOW,
    fontSize: 70,
    fontWeight: "bold",
  },
  bodyText: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 20,
    lineHeight: "60px",
    textAlign: "left",
    marginLeft: 20,
    color: WHITE,
  },
  checkBoxView: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 40,
    width: "45%",
    justifyContent: "space-between",
    alignContent: "space-between",
  },
  loginText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 10,
  },
  profilePhoto: {
    display: "flex",
    flexDirection: "column",
    // background: "url(" + USERIMAGE + ")",
    // objectFit: "contain",
    height: "90%",
    width: "90%",
    marginBottom: 18,
  },
}));

function PartnersPage() {

  const navigate = useNavigate();

  const {
    leftContainer,
    rightContainer,
    leftContainerBoxView,
    loginContainBlock,
    titleStyle,
    titleView,
    subtitleView,
    mainTitle,
    profilePhoto,
  } = UseStyles();

  function subTitleView(subTitleText: any) {
    const { subTitle } = UseStyles();
    return (
      <Typography sx={{ my: 3 }} className={subTitle} style={BODY}>
        {subTitleText}
      </Typography>
    );
  }

  const LeftContainView = () => {
    return (
      <Box className={leftContainerBoxView} sx={{ flexDirection: "column" }}>
        <img className={profilePhoto} src={USERIMAGE} alt={"UserImage"} />
        <CustomButton
          placeHolder={YC_GET_STARTED_EX}
          textTransform="none"
          background={YELLOW}
          titleColor={BLACK}
          height={50}
          width={"70%"}
          borderRadius={7.2}
          onClick={() => {
            navigate("/signup");
          }}
          hoverColor={WHITE}
          buttonStyle={{ backgroundColor: YELLOW, fontSize: 17 }}
        />
      </Box>
    );
  };

  const RightContainView = () => {
    return (
      <Box
        sx={{ display: { xs: "block", sm: "block", lg: "block", xl: "block" } }}
        className={loginContainBlock}
      >
        <Box
          sx={{ display: { xs: "flex", sm: "flex" }, mt: 1 }}
          className={titleView}
        >
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography className={mainTitle} sx={{ ml: 3, mb: 1 }}>
              {"Y "}
            </Typography>
            <Typography
              className={mainTitle}
              sx={{ ml: 3 }}
              style={{ color: WHITE }}
            >
              {" "}
              - CLICK
            </Typography>
          </Box>
          <Typography className={titleStyle}>{"Our Partner Network -"}</Typography>
          <Typography className={titleStyle}>{"Sign Up To Receive Advance Notification"}</Typography>
        </Box>
        <Box className={subtitleView}>
          {subTitleView("If you're looking for an easy and effective way to connect with the local community, the Y-click Universal Admin Panel is the perfect choice.")}
          {subTitleView("Our multipurpose web application is designed to help you take your virtual presence to the next level, with a range of partnerships with local stakeholders, all working together for the benefit of the user themself. Whether you need robust security, integrated payment solutions, local publications, events, or support from NGOs, Y-Click is the perfect partner for you.")}
          {subTitleView("Sign up today, and begin enjoying all the advantages of our powerful platform and partnership. Become part of something bigger and benefit from the unique value that Y-Click provides.")}
          {subTitleView("Join now and start making the most of what our platform has to offer")}
        </Box>
      </Box>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        background: "url(" + BACKGROUNDIMAGE + ")",
        objectFit: "contain",
      }}
    >
      <Box
        sx={{
          display: "flex",
          marginBottom: "40px",
        }}
      >
        <HomepageHeader />
        <Box
          component="main"
          sx={{
            display: { xs: "block", sm: "block", lg: "flex", xl: "flex" },
            justifyContent: "space-between",
            alignContent: "space-between",
            flexDirection: "row",
            p: 5,
            mt: 8,
          }}
        >
          <Box
            className={leftContainer}
            sx={{
              mr: 2,
              mb: 5
            }}
          >
            {LeftContainView()}
          </Box>
          <Box
            className={rightContainer}
          >
            {RightContainView()}
          </Box>
        </Box>
      </Box>
      <NewHomepageFooter
        isLinkFooter={true}
        displayText={YC_FOOTER_COPYRIGHT_ORIGINAL}
      />
    </div>
  );
}

export default PartnersPage;