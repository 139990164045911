import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { BLACK } from "utils/colors";
import StepFooter from "../dashboard-reusables/step-footer";
import YellowPageProcessOne from "./yellowpage-process-step-one";
import YellowPageProcessTwo from "./yellowpage-process-step-two";
import YellowPageProcessThree from "./yellowpage-process-step-three";
import YellowPageProcessFour from "./yellowpage-process-step-four";

const useStyles = makeStyles(() => ({
  mainBoxView: {
    display: "flex",
    flexDirection: "column",
    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
    borderRadius: "7.2px",
    width: "97%",
    background: "#FFFFFF",
  },
  boxView: {
    boxShadow: "2px 4px 8px rgba(0, 0, 0, 0.35)",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "space-between",
    borderRadius: "7.2px",
    width: "90%",
    minWidth: "30%",
  },
  titleText: {
    color: BLACK,
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 1,
  },
}));

function YellowPageProcess({ goToNext, stepNumberYellowPage }: any) {
  const { mainBoxView } = useStyles();
  const [stepNumber, setStepNumber] = React.useState(Number(stepNumberYellowPage));
  const [businessDescription, setBusinessDescription] = React.useState("");
  const [businessModel, setBusinessModel] = React.useState("");
  const [businessMainCategory, setBusinessMainCategory] = React.useState("");
  const [mainCategoryId, setMainCategoryId] = React.useState(0);

  const nextStep = (stepName: string, isPreStepClicked: boolean) => {
    if (stepNumber === 1) {
      if(isPreStepClicked) {
        setStepNumber(5)
        goToNext("business_setup", true)
      } else {
        setStepNumber(2)
        goToNext(2)
      }
    } else if (stepNumber === 2) {
      if(isPreStepClicked) {
        setStepNumber(1)
        goToNext(1)
      } else {
        setStepNumber(3)
        goToNext(3)
      }
    } else if (stepNumber === 3) {
      if(isPreStepClicked) {
        setStepNumber(2)
        goToNext(2)
      } else {
        goToNext(stepName)
      }
    }
  }

  return (
    <Box className={mainBoxView}>
      {stepNumber === 1 && 
        <YellowPageProcessOne
          goToNext={nextStep}
          setBusinessDescription={setBusinessDescription}
        />
      }
      {stepNumber === 2 && 
        <YellowPageProcessTwo
          goToNext={nextStep}
          setBusinessDescription={setBusinessDescription}
          businessDescription={businessDescription}
          setBusinessModel={setBusinessModel}
        />
      }
      {stepNumber === 3 && 
        <YellowPageProcessThree
          goToNext={nextStep}
          setBusinessDescription={setBusinessDescription}
          businessDescription={businessDescription}
          setBusinessModel={setBusinessModel}
          businessModel={businessModel}
          setBusinessMainCategory={setBusinessMainCategory}
          setMainCategoryId={setMainCategoryId}
        />
      }
      {stepNumber === 4 && 
        <YellowPageProcessFour
          goToNext={nextStep}
          setBusinessDescription={setBusinessDescription}
          businessDescription={businessDescription}
          setBusinessModel={setBusinessModel}
          businessModel={businessModel}
          setBusinessMainCategory={setBusinessMainCategory}
          businessMainCategory={businessMainCategory}
          mainCategoryId={mainCategoryId}
        />
      }
      <StepFooter
        text={"Do you need assistance? "}
        link={"Click here to access Tutorials"}
      />
    </Box>
  );
}
export default YellowPageProcess;
