import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BLACK } from "utils/colors";
import { SUBHEADERBOLD } from "utils/fonts";

const UserStatusBlockStyle = makeStyles(() => ({
    boxView: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        borderRadius: "7.2px",
        marginTop: "10px",
        marginLeft: "20px",
        marginBottom: "10px",
        width: 'auto',
        background: "#c3c7c4",
    },

}))
function UserStatusBlock(props: any) {
    const { boxView } = UserStatusBlockStyle();

    return (
        <Box className={boxView}>
            <Box sx={{
                display: "flex",
                flexDirection: "column", ml: 1, mr: 1
            }}>
                <label style={SUBHEADERBOLD}> {props.name}</label>
                <label > Allocated: {props.allocated}</label>
                <hr
                    style={{
                        color: BLACK,
                        backgroundColor: BLACK,
                        height: 2
                    }}
                />
                <label > Used:   {props.value}</label>

            </Box>
        </Box>
    );
}

export default UserStatusBlock;
